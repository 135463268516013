.wrapper-inner,
body,
html {
  max-width: 100%;
}

.row,
.wrapper {
  width: 100%;
}

.column > :first-child,
.row {
  margin-top: 0;
}

.overlay-navigation ul li a,
.side-navigation ul li a {
  -webkit-tap-highlight-color: transparent;
  text-decoration: none;
  word-wrap: break-word;
}

.page-fade-out,
.side-navigation-wrapper,
.thumbnail .overlay-info,
.wrapper {
  -webkit-backface-visibility: hidden;
}

.button:focus,
.checkbox:focus + .checkbox-label,
.radio:focus + .radio-label,
.thumbnail > a,
.thumbnail > span,
a,
button:focus,
img,
input:focus,
p a,
select:focus,
textarea:focus {
  outline: 0;
}

#tml-caption,
.tms-caption {
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
}

.tmh-perspective,
.tms-perspective {
  perspective: 800px;
}

html {
  font-size: 62.5%;
}

body {
  padding: 0;
  margin: 0;
  background: #fff;
  color: #666;
  font-family: "Helvetica Neue", Helvetica, Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.4rem;
  line-height: 1.8;
  overflow-x: hidden !important;
  overflow-y: scroll;
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.row,
.wrapper,
.wrapper-inner {
  margin-right: auto;
  margin-left: auto;
}

.clear,
.wrapper,
.wrapper-inner {
  overflow: hidden;
}

.page-fade-reset {
  opacity: 1;
  -webkit-transition-duration: 0s;
  transition-duration: 0s;
}

.page-fade-out {
  opacity: 0;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
}

.row,
body.boxed .wrapper-inner {
  max-width: 114rem;
}

.row {
  margin-bottom: 0;
}

.ie-browser .row {
  max-width: 1140px;
}

.row.full-width {
  max-width: 100%;
}

.row .row {
  width: auto;
  margin-right: -1.5rem;
  margin-left: -1.5rem;
}

.column,
[class*="content-grid"] .grid-item {
  width: 100%;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  float: left;
}

.row.collapse > .column {
  padding-left: 0;
  padding-right: 0;
}

.row.flex {
  height: auto;
  display: flex;
  flex-wrap: wrap;
}

.row.flex.boxes > .column,
.row.flex.boxes > .v-align-middle,
.row.flex > .v-align-middle,
.row.flex > .v-align-middle > .box {
  height: auto;
  display: inherit;
}

.row.flex > .column > div {
  width: 100%;
  flex-direction: column;
}

.row.flex .row.flex {
  flex-direction: row;
}

@media only screen and (min-width: 768px) {
  .row.flex .v-align-middle > :not(.box),
  .row.flex.boxes .v-align-middle > div > :not(.box) {
    height: auto;
    margin-top: auto;
    margin-bottom: auto;
  }
  .ie-browser:not(.webkit) .flex > .column {
    align-items: center;
  }
}

.row.flex:after,
.row.flex:before {
  content: "";
  display: none;
}

.row.small {
  padding: 3rem 0;
}

.row.medium {
  padding: 5rem 0;
}

.row.large {
  padding: 7rem 0;
}

.row.xlarge {
  padding: 9rem 0;
}

.width-1 {
  width: 8.33333%;
}

.width-2 {
  width: 16.66667%;
}

.width-3 {
  width: 25%;
}

.width-4 {
  width: 33.33333%;
}

.width-5 {
  width: 41.66667%;
}

.width-6 {
  width: 50%;
}

.width-7 {
  width: 58.33333%;
}

.width-8 {
  width: 66.66667%;
}

.width-9 {
  width: 75%;
}

.width-10 {
  width: 83.33333%;
}

.width-11 {
  width: 91.66667%;
}

.width-12 {
  width: 100%;
}

.column[class*="push-"],
.column[class*="pull-"] {
  position: relative;
}

.push-1 {
  left: 8.33333%;
}

.push-2 {
  left: 16.66667%;
}

.push-3 {
  left: 25%;
}

.push-4 {
  left: 33.33333%;
}

.push-5 {
  left: 41.66667%;
}

.push-6 {
  left: 50%;
}

.push-7 {
  left: 58.33333%;
}

.push-8 {
  left: 66.66667%;
}

.push-9 {
  left: 75%;
}

.push-10 {
  left: 83.33333%;
}

.push-11 {
  left: 91.66667%;
}

.pull-1 {
  right: 8.33333%;
}

.pull-2 {
  right: 16.66667%;
}

.pull-3 {
  right: 25%;
}

.pull-4 {
  right: 33.33333%;
}

.pull-5 {
  right: 41.66667%;
}

.pull-6 {
  right: 50%;
}

.pull-7 {
  right: 58.33333%;
}

.pull-8 {
  right: 66.66667%;
}

.pull-9 {
  right: 75%;
}

.pull-10 {
  right: 83.33333%;
}

.pull-11 {
  right: 91.66667%;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

.alpha {
  padding-left: 0;
}

.omega {
  padding-right: 0;
}

.clear {
  width: 0;
  height: 0;
  display: block;
  clear: both;
  visibility: hidden;
}

.clearfix:after,
.clearfix:before,
.row:after,
.row:before {
  height: 0;
  content: ".";
  display: block;
  overflow: hidden;
}

.clearfix:after,
.row:after {
  clear: both;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.center,
.navigation.nav-center > ul {
  text-align: center;
}

.pull-left {
  float: left !important;
}

.pull-right {
  float: right !important;
}

.v-align-bottom {
  vertical-align: bottom !important;
}

.v-align-top {
  vertical-align: top !important;
}

.v-align-top .background-on-mobile {
  margin-top: -2rem;
}

.v-align-bottom .background-on-mobile {
  margin-bottom: -2rem;
}

.hide {
  display: none !important;
  visibility: hidden !important;
}

.show {
  display: block !important;
  visibility: visible !important;
}

.mobile .hide-on-mobile,
.mobile .hide-on-mobile * {
  display: none !important;
  visibility: visible !important;
}

.inline,
[class*="border-"].inline,
[class*="bkg-"].inline {
  width: auto !important;
  display: inline-block !important;
}

.no-margins {
  margin: 0 !important;
}

.no-margin-top {
  margin-top: 0 !important;
}

.no-margin-right {
  margin-right: 0 !important;
}

.no-margin-bottom {
  margin-bottom: 0 !important;
}

.no-margin-left {
  margin-left: 0 !important;
}

.no-padding {
  padding: 0 !important;
}

.no-padding-top {
  padding-top: 0 !important;
}

.no-padding-right {
  padding-right: 0 !important;
}

.no-padding-bottom {
  padding-bottom: 0 !important;
}

.no-padding-left {
  padding-left: 0 !important;
}

.overflow-visible {
  overflow: visible !important;
}

.no-scroll {
  overflow: hidden !important;
}

.clear-height {
  height: auto !important;
}

.prototype {
  font-size: 1.1rem;
  line-height: 3.5rem;
}

.row.prototype .column:nth-child(odd) {
  background: #f9f9f9;
}

.row.prototype .column:nth-child(even) {
  background: #eee;
}

.nav-bar {
  width: 100%;
  min-height: 5rem;
}

.nav-bar-inner {
  position: relative;
}

.nav-bar .logo {
  height: 5rem;
  line-height: 5rem;
  float: left;
  margin-right: 3rem;
}

.nav-bar .logo img {
  margin-top: -4px;
}

.nav-bar .logo-right {
  margin-left: 3rem;
  margin-right: 0;
  float: right;
}

.nav-bar .logo.logo-center {
  float: none;
  margin: 0 auto;
}

.navigation.nav-left {
  float: left !important;
}

.navigation.nav-left > ul > li:first-child > a {
  padding-left: 0;
  margin-left: 0;
}

.navigation.nav-right {
  float: right !important;
  margin-left: 2.5rem;
}

.navigation.nav-right > ul > li:last-child > a {
  padding-right: 0;
  margin-right: 0;
}

.navigation.nav-center {
  float: none !important;
  display: block;
}

.navigation.nav-left {
  margin-right: 2.5rem;
}

.navigation:last-child {
  margin: 0;
}

.navigation .dropdown .button {
  width: auto;
}

.navigation .button [class*="icon-"] {
  margin-right: 0;
}

.navigation .dropdown-list,
.navigation ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.navigation ul li {
  margin: 0;
  float: left;
  position: relative;
}

.navigation ul li.contains-mega-sub-menu {
  position: inherit;
}

.navigation.nav-center > ul > li {
  margin-left: -0.4rem;
  float: none;
  display: inline-block !important;
  vertical-align: top;
}

.navigation > ul > li > a {
  padding: 0 1.5rem;
  background-color: rgba(0, 0, 0, 0);
  line-height: 5rem;
}

.navigation .dropdown-list:not(.custom-content) li a,
.navigation ul li a {
  font-size: 1.1rem;
  color: #878787;
  text-decoration: none;
  display: block;
  word-wrap: break-word;
}

.navigation .dropdown-list li > a:hover,
.navigation ul li > a:hover {
  background: 0 0;
  color: #fff;
}

.navigation ul li:hover > .sub-menu {
  visibility: visible;
  opacity: 1;
}

.navigation .dropdown-list,
.navigation .mega-sub-menu,
.navigation .sub-menu {
  box-shadow: 0 0.2rem 0.2rem rgba(0, 0, 0, 0.3);
  opacity: 0;
  visibility: hidden;
  z-index: 101;
  line-height: 1.8;
  position: absolute;
}

.navigation ul li.current > a,
.navigation ul li.current > a:hover {
  color: #fff;
}

.navigation .dropdown-list,
.navigation .sub-menu {
  width: 18rem;
  text-align: left;
  background: #222;
  background: rgba(34, 34, 34, 1);
  border: none;
  border-radius: 0;
}

.navigation .dropdown-list li,
.navigation .sub-menu li {
  background: 0 0;
  border-bottom: 1px solid #303030;
  float: none;
}

.navigation .dropdown-list.custom-content {
  width: auto;
  min-width: 25rem;
  padding: 2rem;
}

.navigation .dropdown.pull-right .dropdown-list,
.navigation > ul > li.sub-menu-right .sub-menu,
.navigation > ul > li:last-child > .sub-menu {
  right: 0;
}

.navigation .sub-menu ul {
  top: 0;
  left: 18rem;
}

.navigation > ul > li.sub-menu-right .sub-menu ul,
.navigation > ul > li:last-child > .sub-menu ul {
  right: 18rem;
  left: auto;
}

.navigation .dropdown-list:not(.custom-content) li a,
.navigation .sub-menu:not(.custom-content) li a {
  padding: 1.2rem 1.5rem;
}

.navigation .dropdown-list li a,
.navigation .sub-menu li a {
  color: #666;
}

.navigation .dropdown-list li:last-child,
.navigation .sub-menu li:last-child {
  border-bottom: none;
}

.navigation .mega-sub-menu {
  padding: 2rem 0;
  margin-left: 0;
  margin-right: 0;
  background: #222;
  background: rgba(34, 34, 34, 1);
  display: block;
  left: 1.5rem;
  right: 1.5rem;
}

.navigation .mega-sub-menu > li:not(.column) {
  width: 33.33333%;
}

.navigation .mega-sub-menu > li {
  padding: 0 2rem;
  text-align: left;
  float: left;
  border-right: 1px solid #303030;
}

.navigation .mega-sub-menu > li > a {
  background: 0 0;
  font-weight: 700;
  padding: 0 1.5rem 0.7rem;
  font-size: 1.2rem;
  line-height: 1.8;
  text-transform: uppercase;
}

.navigation .mega-sub-menu > li:hover > a {
  background: 0 0;
}

.navigation .mega-sub-menu > li:last-child {
  border-right: none;
}

.navigation .mega-sub-menu ul li p {
  padding: 0 1.5rem 0.7rem;
  font-size: 1.2rem;
  margin-bottom: 0;
}

.navigation .mega-sub-menu li.content-column ul li,
.navigation .mega-sub-menu ul li a {
  padding: 0.6rem 1.5rem;
}

.navigation .mega-sub-menu ul li.current > a {
  color: #fff;
}

.navigation .mega-sub-menu ul li:hover > a {
  background: #333;
}

.navigation .mega-sub-menu li ul > li {
  float: none;
}

.navigation .mega-sub-menu li.content-column ul li a {
  padding: 0;
}

.navigation ul li:hover > .mega-sub-menu {
  visibility: visible;
  opacity: 1;
}

.navigation .sub-menu li.contains-sub-menu > a:after,
.navigation.sub-menu-indicator > ul > li.contains-mega-sub-menu > a:after,
.navigation.sub-menu-indicator > ul > li.contains-sub-menu > a:after {
  font-family: icomoon;
  font-size: inherit;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: inherit;
  display: inline-block;
  position: absolute;
}

.navigation.sub-menu-indicator > ul > li.contains-mega-sub-menu,
.navigation.sub-menu-indicator > ul > li.contains-sub-menu {
  padding-right: 1.2rem;
}

.navigation.sub-menu-indicator > ul > li.contains-mega-sub-menu > a:after,
.navigation.sub-menu-indicator > ul > li.contains-sub-menu > a:after {
  margin-left: 0.5rem;
  content: "\e63e";
}

.navigation .sub-menu li.contains-sub-menu > a {
  padding-right: 2.2rem;
}

.navigation .sub-menu li.contains-sub-menu > a:after {
  right: 0.8rem;
  content: "\e640";
}

.navigation .mega-sub-menu,
.navigation .sub-menu {
  -webkit-transition-property: -webkit-transform, opacity, visibility, border-color;
  transition-property: transform, opacity, visibility, border-color;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
}

.navigation ul li a {
  -webkit-transition-property: background, border-color, color, line-height;
  transition-property: background, border-color, color, line-height;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
}

.aux-navigation {
  display: none;
}

.navigation-hide,
.navigation-show {
  width: 4.4rem;
  height: 4.4rem;
  text-align: center;
  float: right;
}

.navigation-hide a,
.navigation-show a {
  height: 4.4rem;
  text-align: center;
  display: block;
}

.navigation-hide span,
.navigation-show span {
  width: 100%;
  line-height: 4.4rem;
}

.navigation-show a {
  color: #666;
}

.navigation-show a:hover {
  color: #fff;
}

.navigation-hide {
  position: relative;
  z-index: 10;
}

.navigation-hide a {
  color: #666;
}

.navigation-hide a:hover {
  color: #fff;
}

.mobile .logo.logo-center {
  float: left;
}

.mobile header .navigation:not(.secondary-navigation) {
  display: none !important;
}

.mobile .aux-navigation {
  display: table !important;
  visibility: visible;
}

.reveal-side-navigation > div:after {
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  background: rgba(0, 0, 0, 0);
  content: "";
  opacity: 0;
  z-index: 110;
  -webkit-transition: opacity 0.3s, width 0s, height 0s;
  transition: opacity 0.3s, width 0s, height 0s;
}

.inactive.reveal-side-navigation > div:after {
  width: 100%;
  height: 100%;
  opacity: 1;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}

.element-reveal-left {
  -webkit-transform: translate3d(35rem, 0, 0);
  transform: translate3d(35rem, 0, 0);
}

.element-reveal-right {
  -webkit-transform: translate3d(-35rem, 0, 0);
  transform: translate3d(-35rem, 0, 0);
}

.side-navigation-inner,
.side-navigation-wrapper {
  width: 35rem;
}

.side-navigation-wrapper {
  height: 100%;
  padding: 0;
  background: #292929;
  box-shadow: inset -7px 0 9px -7px rgba(0, 0, 0, 0.6);
  overflow-y: scroll;
  visibility: hidden;
  position: fixed;
  left: 0;
  right: auto;
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}

.side-navigation-wrapper.active {
  visibility: visible;
}

.side-navigation-wrapper.enter-right {
  text-align: left;
  box-shadow: inset 7px 0 9px -7px rgba(0, 0, 0, 0.6);
  right: 0;
  left: auto;
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}

.side-navigation-wrapper.no-scrollbar {
  overflow: hidden;
}

.no-scrollbar .side-navigation-scroll-pane {
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  position: absolute;
  left: 0;
}

.no-scrollbar .side-navigation-scroll-pane::-webkit-scrollbar {
  display: none;
}

.side-navigation-inner {
  min-height: 100%;
  position: relative;
}

.side-navigation-wrapper.no-transition-reset {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.side-navigation-wrapper.slide-in-reset {
  z-index: 104;
}

.element-reveal-left.slide-in,
.element-reveal-right.slide-in,
.side-navigation-wrapper.slide-in {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.side-navigation-wrapper.push-in-reset {
  -webkit-transform: translate3d(-60%, 0, 0);
  transform: translate3d(-60%, 0, 0);
}

.side-navigation-wrapper.enter-right.push-in-reset {
  -webkit-transform: translate3d(60%, 0, 0);
  transform: translate3d(60%, 0, 0);
}

.side-navigation-wrapper.enter-right.push-in,
.side-navigation-wrapper.enter-right.reveal-reset,
.side-navigation-wrapper.push-in,
.side-navigation-wrapper.reveal-reset {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.side-navigation-wrapper.enter-right.scale-in-reset,
.side-navigation-wrapper.scale-in-reset {
  -webkit-transform: translate3d(0, 0, 0) scale(0.9);
  transform: translate3d(0, 0, 0) scale(0.9);
}

.side-navigation-wrapper.enter-right.scale-in,
.side-navigation-wrapper.scale-in {
  -webkit-transform: translate3d(0, 0, 0) scale(1);
  transform: translate3d(0, 0, 0) scale(1);
}

.reveal-side-navigation,
.side-navigation-wrapper {
  -webkit-transition: -webkit-transform 550ms, visibility 0s;
  transition: transform 550ms, visibility 0s;
}

.side-navigation-wrapper.hide {
  visibility: hidden;
  left: -35rem;
}

.side-navigation-wrapper.enter-right.hide {
  right: -35rem;
}

.element-show-left {
  left: 35rem;
}

.element-show-right {
  left: -35rem;
}

.element-show-left,
.element-show-right {
  position: relative !important;
}

.side-navigation {
  width: 100%;
}

.side-navigation:after,
.side-navigation:before {
  content: ".";
  display: block;
  height: 0;
  overflow: hidden;
  clear: both;
}

.side-navigation ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.side-navigation ul li {
  width: 100%;
  display: block;
  margin-bottom: 0;
}

.side-navigation > ul > li > a {
  padding: 1.3rem 4rem;
}

.side-navigation ul li a {
  font-size: 1.2rem;
  line-height: 1;
  color: #999;
  display: block;
  -webkit-transition-property: background, color;
  transition-property: background, color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

.side-navigation ul li a:hover,
.side-navigation ul li.current > a:hover {
  color: #fff;
}

.side-navigation ul li.current > a {
  color: #fff;
  font-weight: 700;
}

.side-navigation .sub-menu:not(.custom-content) a {
  padding: 1.1rem 4rem 1.1rem 5.5rem;
}

.side-navigation.center .sub-menu:not(.custom-content) a {
  padding: 1.1rem 4rem;
}

.side-navigation .sub-menu .sub-menu a {
  padding-left: 7rem;
}

.side-navigation ul li:hover > .sub-menu {
  opacity: 1;
}

.side-navigation .sub-menu {
  overflow: hidden;
  height: 0;
  -webkit-transition-property: height;
  transition-property: height;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

.overlay-navigation-inner,
.overlay-navigation-wrapper {
  width: 100vw;
}

.overlay-navigation-wrapper {
  width: 100vw;
  height: 100%;
  min-height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  overflow-y: scroll;
  visibility: hidden;
  position: fixed;
  top: -100%;
  z-index: 101;
  -webkit-transition: -webkit-transform 550ms, opacity 550ms;
  transition: transform 550ms, opacity 550ms;
}

.overlay-navigation-wrapper.active {
  visibility: visible;
  top: 0 !important;
}

.overlay-navigation-wrapper.no-scrollbar {
  overflow: hidden;
}

.overlay-navigation-scroll-pane {
  height: 100%;
}

.no-scrollbar .overlay-navigation-scroll-pane {
  overflow-x: hidden;
  overflow-y: scroll;
  position: absolute;
  left: 0;
}

.no-scrollbar .overlay-navigation-scroll-pane::-webkit-scrollbar {
  display: none;
}

.overlay-navigation-inner {
  height: 100%;
  display: table;
  position: relative;
}

.overlay-navigation-inner .v-align-middle {
  min-height: 100%;
  display: table-cell;
  vertical-align: middle;
}

.overlay-navigation-wrapper.no-transition-reset {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.overlay-navigation-wrapper.fade-in-reset {
  opacity: 0;
}

.overlay-navigation-wrapper.fade-in {
  opacity: 1;
}

.overlay-navigation-wrapper.slide-in-reset {
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
  opacity: 0;
}

.overlay-navigation-wrapper.enter-top.slide-in-reset {
  -webkit-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0);
  opacity: 0;
}

.overlay-navigation-wrapper.enter-right.slide-in-reset {
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
  opacity: 0;
}

.overlay-navigation-wrapper.enter-bottom.slide-in-reset {
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
  top: 100%;
  opacity: 0;
}

.overlay-navigation-wrapper.enter-bottom.slide-in,
.overlay-navigation-wrapper.enter-right.slide-in,
.overlay-navigation-wrapper.enter-top.slide-in,
.overlay-navigation-wrapper.slide-in {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.overlay-navigation-wrapper.scale-in-reset {
  -webkit-transform: translate3d(0, 0, 0) scale(0.8);
  transform: translate3d(0, 0, 0) scale(0.8);
  opacity: 0;
}

.overlay-navigation-wrapper.scale-in {
  -webkit-transform: translate3d(0, 0, 0) scale(1);
  transform: translate3d(0, 0, 0) scale(1);
  opacity: 1;
}

.overlay-navigation {
  max-width: 30rem;
  margin-left: auto;
  margin-right: auto;
}

.overlay-navigation:after,
.overlay-navigation:before {
  content: ".";
  display: block;
  height: 0;
  overflow: hidden;
  clear: both;
}

.overlay-navigation ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.overlay-navigation ul li {
  width: 100%;
  display: block;
  margin-bottom: 0;
}

.overlay-navigation > ul > li > a {
  padding: 0.6rem 0;
}

.overlay-navigation ul li a {
  font-size: 1.2rem;
  line-height: 1.5;
  color: #878787;
  display: block;
  -webkit-transition-property: background, color;
  transition-property: background, color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

.overlay-navigation ul li a:hover,
.overlay-navigation ul li.current > a:hover {
  color: #fff;
}

.overlay-navigation ul li.current > a {
  color: #fff;
  font-weight: 700;
}

.overlay-navigation .sub-menu:not(.custom-content) a {
  padding: 1.1rem 4rem 1.1rem 5.5rem;
}

.overlay-navigation.center .sub-menu:not(.custom-content) a {
  padding: 1.1rem 4rem;
}

.overlay-navigation .sub-menu a {
  color: #666;
}

.overlay-navigation .sub-menu .sub-menu a {
  padding-left: 7rem;
}

.overlay-navigation ul li:hover > .sub-menu {
  opacity: 1;
}

.overlay-navigation .sub-menu {
  overflow: hidden;
  height: 0;
  -webkit-transition-property: height, border-color;
  transition-property: height, border-color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

.thumbnail video,
img {
  height: auto;
  max-width: 100%;
}

img {
  display: inline-block;
  vertical-align: middle;
  border: none;
}

.thumbnail.pull-left,
img.pull-left {
  margin: 0 1.5rem 1rem 0;
}

.thumbnail.pull-right,
img.pull-right {
  margin: 0 0 1.5rem 1rem;
}

.thumbnail {
  margin-bottom: 3rem;
  position: relative;
  overflow: hidden;
}

.thumbnail.rounded,
img.rounded {
  border-radius: 50%;
}

.thumbnail video {
  width: 100%;
  position: relative;
  z-index: 1;
}

.thumbnail video + img {
  position: absolute;
  top: 0;
  z-index: 0;
}

.thumbnail:hover {
  transform: none !important;
  transition: none !important;
}

.caption-below {
  width: 100%;
  padding: 1rem 1.5rem;
  font-size: 1.2rem;
  font-style: italic;
  border: 1px solid #eee;
  float: left;
}

.overlay-info,
.thumbnail > a,
.thumbnail > span {
  border: none;
  left: 0;
  width: 100%;
  height: 100%;
  top: 0;
}

.thumbnail .caption-over-outer {
  width: 100%;
  height: 100%;
  padding: 2rem;
  display: table;
  opacity: 0;
  position: absolute;
  bottom: 0;
  z-index: 2;
}

.thumbnail .caption-over-inner {
  width: 100%;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
}

.thumbnail .caption-over-inner:first-child {
  margin-top: 0;
}

.thumbnail .caption-over-inner:last-child {
  margin-bottom: 0;
}

.thumbnail > a,
.thumbnail > span {
  float: left;
  display: block;
  overflow: hidden;
  position: relative;
}

.overlay-info {
  padding: 0;
  margin: 0;
  color: #fff;
  background: #000;
  background: rgba(0, 0, 0, 0.5);
  text-align: center;
  opacity: 0;
  position: absolute;
  z-index: 1;
}

a.overlay-info:hover {
  color: #fff;
}

.overlay-info > div,
.overlay-info > span {
  width: 100%;
  height: 100%;
  padding: 1.5rem;
  font-size: 1.2rem;
  font-style: normal;
  display: table;
}

.overlay-info > div > div,
.overlay-info > span > span {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}

.fill-background {
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  background-size: cover;
}

.overlay-info.left > span > span {
  text-align: left;
}

.overlay-info.right > span > span {
  text-align: right;
}

.overlay-info.v-align-top > div > div,
.overlay-info.v-align-top > span > span,
.thumbnail .caption-over-inner.v-align-top {
  vertical-align: top;
}

.overlay-info.v-align-bottom > div > div,
.overlay-info.v-align-bottom > span > span,
.thumbnail .caption-over-inner.v-align-bottom {
  vertical-align: bottom;
}

.thumbnail .overlay-info {
  width: 100.1%;
  line-height: 1;
  opacity: 0;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

.overlay-fade-img-scale-in .overlay-info,
.overlay-fade-img-scale-out .overlay-info,
.overlay-fade-out .overlay-info,
.thumbnail:hover .overlay-info {
  opacity: 1;
}

.thumbnail:hover .overlay-info,
.thumbnail:hover img {
  -webkit-transform: translate3d(0, 0, 0) rotate3d(1, 1, 1, 0deg) scale3d(1, 1, 1);
  transform: translate3d(0, 0, 0) rotate3d(1, 1, 1, 0deg) scale3d(1, 1, 1);
}

.overlay-fade-img-scale-in:hover .overlay-info,
.overlay-fade-img-scale-out:hover .overlay-info,
.overlay-fade-out:hover .overlay-info {
  opacity: 0;
}

.overlay-slide-in-top .overlay-info {
  opacity: 0;
  -webkit-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0);
}

.overlay-slide-in-right .overlay-info {
  opacity: 0;
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}

.overlay-slide-in-bottom .overlay-info {
  opacity: 0;
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
}

.overlay-slide-in-left .overlay-info {
  opacity: 0;
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}

.overlay-scale-in .overlay-info {
  opacity: 0;
  -webkit-transform: scale3d(0.1, 0.1, 1);
  transform: scale3d(0.1, 0.1, 1);
}

.overlay-rotate-in .overlay-info {
  opacity: 0;
  -webkit-transform: rotate3d(0.1, 0.1, 1, -180deg);
  transform: rotate3d(0.1, 0.1, 1, -180deg);
}

.overlay-img-slide-right .overlay-info {
  opacity: 1;
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}

.overlay-img-slide-right:hover img {
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}

.overlay-img-slide-left .overlay-info {
  opacity: 1;
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}

.overlay-img-slide-left:hover img {
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}

.overlay-img-slide-down .overlay-info {
  opacity: 1;
  -webkit-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0);
}

.overlay-img-slide-down:hover img {
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
}

.overlay-img-slide-up .overlay-info {
  opacity: 1;
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
}

.overlay-img-slide-up:hover img {
  -webkit-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0);
}

.img-scale-in:hover img,
.img-scale-out img,
.overlay-fade-img-scale-in:hover img,
.overlay-fade-img-scale-out img,
.overlay-img-scale-in:hover img {
  opacity: 1;
  -webkit-transform: scale3d(1.2, 1.2, 1);
  transform: scale3d(1.2, 1.2, 1);
}

.overlay-img-scale-in .overlay-info {
  -webkit-transform: scale3d(0.4, 0.4, 1);
  transform: scale3d(0.4, 0.4, 1);
}

[class*="content-grid"] .grid-item {
  margin: 0 0 3rem;
  float: left;
}

[class*="content-grid"] .thumbnail {
  float: none;
}

.content-grid-1 .grid-item {
  width: 100%;
}

.content-grid-2 .grid-item {
  width: 50%;
}

.content-grid-3 .grid-item {
  width: 33.33333%;
}

.content-grid-4 .grid-item {
  width: 25%;
}

.content-grid-5 .grid-item {
  width: 20%;
}

.content-grid-6 .grid-item {
  width: 16.66667%;
}

.row [class*="content-grid-"].no-margins,
[class*="content-grid-"].no-margins {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

[class*="content-grid-"].no-margins .grid-item {
  padding: 0 !important;
  margin: 0 !important;
}

.text-column {
  margin-bottom: 3rem;
}

.text-column > :last-child {
  margin-bottom: 0;
}

.feature-column-group .feature-column {
  padding: 7rem;
  margin-bottom: 0;
}

.feature-column {
  margin-bottom: 3rem;
}

.feature-column > :last-child {
  margin-bottom: 0;
}

.feature-column .feature-icon {
  font-size: 3rem;
  margin-bottom: 2rem;
  display: inline-block;
  position: relative;
}

.feature-column.left .feature-icon {
  float: left;
}

.feature-text.left {
  padding-left: 5rem;
}

.feature-column.right .feature-text {
  padding-right: 5rem;
  padding-left: 0;
}

.feature-text:first-child {
  margin-top: 0;
}

.feature-column.small .feature-icon {
  font-size: 2rem;
}

.feature-column.left.small .feature-text {
  padding-left: 4rem;
}

.feature-column.left.small .icon-boxed,
.feature-column.left.small .icon-circled {
  width: 5.8rem;
  height: 5.8rem;
  line-height: 5.8rem;
}

.feature-column.left.small .icon-boxed + .feature-text,
.feature-column.left.small .icon-circled + .feature-text {
  padding-left: 9rem;
}

.feature-column.right.small .feature-text {
  padding-right: 4rem;
  padding-left: 0;
}

.feature-column.right.small .icon-boxed + .feature-text,
.feature-column.right.small .icon-circled + .feature-text {
  padding-right: 9rem;
  padding-left: 0;
}

.feature-colum.mediumn .feature-icon {
  font-size: 3rem;
}

.feature-column.left.medium .feature-text {
  padding-left: 5rem;
}

.feature-column.medium .icon-boxed,
.feature-column.medium .icon-circled {
  width: 6.6rem;
  height: 6.6rem;
  line-height: 6.6rem;
}

.feature-column.large .feature-icon,
.feature-column.large .icon-boxed,
.feature-column.large .icon-circled {
  width: 7.4rem;
  height: 7.4rem;
  line-height: 7.4rem;
}

.feature-column.left .icon-boxed + .feature-text,
.feature-column.left .icon-circled + .feature-text,
.feature-column.left.medium .icon-boxed + .feature-text,
.feature-column.left.medium .icon-circled + .feature-text {
  padding-left: 9.8rem;
}

.feature-column.right .icon-boxed + .feature-text,
.feature-column.right .icon-circled + .feature-text,
.feature-column.right.medium .icon-boxed + .feature-text,
.feature-column.right.medium .icon-circled + .feature-text {
  padding-right: 9.8rem;
  padding-left: 0;
}

.feature-column.large .feature-icon {
  font-size: 4rem;
}

.feature-column.left.large .feature-text {
  padding-left: 6rem;
}

.feature-column.left.large .icon-boxed + .feature-text,
.feature-column.left.large .icon-circled + .feature-text {
  padding-left: 10.6rem;
}

.feature-column.right.large .icon-boxed + .feature-text,
.feature-column.right.large .icon-circled + .feature-text {
  padding-right: 10.6rem;
  padding-left: 0;
}

.feature-column.xlarge .feature-icon {
  font-size: 5rem;
}

.feature-column.left.xlarge .feature-text {
  padding-left: 7rem;
}

.feature-column.xlarge .icon-boxed,
.feature-column.xlarge .icon-circled {
  width: 9rem;
  height: 9rem;
  line-height: 9rem;
}

.feature-column.left.xlarge .icon-boxed + .feature-text,
.feature-column.left.xlarge .icon-circled + .feature-text {
  padding-left: 12.2rem;
}

.feature-column.right.xlarge .icon-boxed + .feature-text,
.feature-column.right.xlarge .icon-circled + .feature-text {
  padding-right: 12.2rem;
  padding-left: 0;
}

.feature-column.right .feature-icon {
  float: right;
  margin: 0 auto 2rem;
}

.feature-column.center .feature-icon {
  display: inline-block;
  float: none;
  margin: 0 auto 2rem;
}

.feature-column.center .feature-icon + .feature-text {
  padding-left: 0;
  padding-right: 0;
}

@media only screen and (max-width: 768px) {
  .feature-column.center-on-mobile .feature-icon {
    display: inline-block;
    float: none;
    margin: 0 auto 2rem;
  }
  .feature-column.center-on-mobile .feature-icon + .feature-text {
    padding-left: 0;
    padding-right: 0;
  }
}

input {
  line-height: normal;
}

input,
textarea {
  width: 100%;
  padding: 1.3rem 2.5rem;
  margin: 0 0 1.5rem;
}

.button + .button,
.checkbox-label ~ .checkbox-label,
.radio-label ~ .radio-label,
button + button,
label.pull-right {
  margin-left: 1rem;
}

input[type="submit"] {
  width: auto;
}

.disabled,
input:disabled,
select:disabled,
textarea:disabled {
  opacity: 0.6;
  cursor: default;
}

.checkbox:disabled,
.radio:disabled {
  opacity: 0;
  cursor: default;
}

.form-element,
textarea {
  background: #f9f9f9;
  font-size: 1.3rem;
  color: #666;
  line-height: 1.4;
  border: 1px solid #eee;
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.form-element:focus,
textarea:focus {
  background: #fff;
  color: #333;
  border: 1px solid #ccc;
  box-shadow: 0 0 0.2rem 0 rgba(0, 0, 0, 0.2);
}

textarea {
  min-width: 10rem;
  min-height: 20rem;
}

.textarea-no-resize {
  resize: none;
}

label {
  margin-bottom: 0.8rem;
  color: #666;
  font-size: 1.2rem;
  display: block;
}

label.pull-left,
label.pull-right {
  margin-bottom: 0;
  padding: 1.4rem 0;
  line-height: normal;
  float: left;
}

label.pull-left {
  margin-right: 1rem;
}

label.emphasized {
  color: #666;
  font-size: 1.2rem;
  font-weight: 700;
}

select {
  width: 100%;
  background: 0 0;
  border: 0;
  border-radius: 0;
  color: inherit;
  font-size: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.form-select {
  width: 100%;
  padding: 0 !important;
  position: relative;
}

.form-select select {
  width: 100%;
  padding: 1.3rem 2.5rem;
}

.form-select:after {
  font-family: icomoon;
  font-size: 1.5rem;
  content: "\e63e";
  pointer-events: none;
  position: absolute;
  top: 1.3rem;
  right: 2rem;
}

input[type="checkbox"] {
  -webkit-appearance: checkbox;
  -moz-appearance: checkbox;
  appearance: checkbox;
}

.checkbox,
.radio {
  opacity: 0;
  position: absolute;
}

.checkbox,
.checkbox-label,
.radio,
.radio-label {
  margin-bottom: 2rem;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}

.checkbox:disabled + .checkbox-label,
.checkbox:disabled + .checkbox-label:before,
.radio:disabled + .radio-label,
.radio:disabled + .radio-label:before {
  opacity: 0.6;
}

.checkbox-label,
.radio-label {
  color: #666;
  position: relative;
}

.checkbox + .checkbox-label:before,
.radio + .radio-label:before {
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  margin-right: 1rem;
  display: inline-block;
  vertical-align: middle;
  background: #f9f9f9;
  border: 1px solid #eee;
  content: "";
  font-family: icomoon;
  text-align: center;
}

.checkbox:checked + .checkbox-label,
.radio:checked + .radio-label {
  opacity: 1;
}

.checkbox:checked + .checkbox-label:before {
  content: "\e63a";
  background: #fff;
  color: #666;
}

.radio:checked + .radio-label:before {
  background: #666;
  box-shadow: inset 0 0 0 4px #fff;
}

.checkbox.rounded + .checkbox-label:before,
.form-element.rounded,
textarea.rounded {
  border-radius: 0.3rem;
}

.radio + .radio-label:before {
  border-radius: 50%;
}

.form-element.pill {
  border-radius: 10.5rem;
}

@media only screen and (min-width: 768px) {
  .merged-form-elements .column:first-child {
    padding-right: 0;
  }
  .merged-form-elements .column:last-child {
    padding-left: 0;
  }
  .merged-form-elements .column + .column {
    padding: 0;
  }
  .merged-form-elements .column .form-element {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .merged-form-elements .column + .column .form-element {
    border-radius: 0;
  }
  .merged-form-elements .column:last-child .button {
    width: 100%;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.form-element.small {
  padding: 1rem 1.5rem;
  font-size: 1.1rem;
}

.form-element.small select {
  padding: 1rem 3.5rem 1rem 1.5rem;
  font-size: 1.1rem;
}

.form-select.small:after {
  top: 0.8rem;
  right: 1rem;
}

.checkbox.small + .checkbox-label:before,
.radio.small + .radio-label:before {
  width: 1.6rem;
  height: 1.6rem;
  line-height: 1.6rem;
}

.checkbox.small + .checkbox-label,
.radio.small + .radio-label {
  font-size: 1.1rem;
}

.form-element.medium {
  padding: 1.3rem 2.5rem;
}

.form-element.medium select {
  padding: 1.3rem 4.5rem 1.3rem 2.5rem;
}

.form-element.large {
  padding: 1.6rem 3rem;
  font-size: 1.4rem;
}

.form-element.large select {
  padding: 1.6rem 5rem 1.6rem 3rem;
  font-size: 1.4rem;
}

.form-select.large:after {
  top: 1.7rem;
  right: 2.5rem;
}

.checkbox.large + .checkbox-label:before,
.radio.large + .radio-label:before {
  width: 2.4rem;
  height: 2.4rem;
  line-height: 2.4rem;
}

.checkbox.large + .checkbox-label,
.radio.large + .radio-label {
  font-size: 1.4rem;
}

.form-element.xlarge {
  padding: 1.9rem 3.5rem;
  font-size: 1.5rem;
}

.form-element.xlarge select {
  padding: 1.9rem 5.5rem 1.9rem 3.5rem;
  font-size: 1.5rem;
}

.form-select.xlarge:after {
  top: 2rem;
  right: 3rem;
}

.checkbox.xlarge + .checkbox-label:before,
.radio.xlarge + .radio-label:before {
  width: 2.7rem;
  height: 2.7rem;
  line-height: 2.7rem;
}

.checkbox.xlarge + .checkbox-label,
.radio.xlarge + .radio-label {
  font-size: 1.5rem;
}

div[contenteditable="true"] {
  background: #f9f9f9;
  color: #666;
  border: 1px solid #eee;
  padding: 1.3rem 2.5rem;
}

div[contenteditable="true"]:focus {
  background: #fff;
  color: #333;
  border: 1px solid #ccc;
  box-shadow: 0 0 0.2rem 0 rgba(0, 0, 0, 0.2);
  outline: 0;
}

div[contenteditable="true"].small {
  padding: 1rem 1.5rem;
  font-size: 1rem;
}

div[contenteditable="true"].medium {
  padding: 1.3rem 2.5rem;
}

div[contenteditable="true"].large {
  padding: 1.6rem 3rem;
  font-size: 1.4rem;
}

div[contenteditable="true"].xlarge {
  padding: 1.9rem 3.5rem;
  font-size: 1.5rem;
}

.button-group {
  margin-bottom: 3rem;
}

.button,
button {
  padding: 1.3rem 2.5rem;
  margin: 0 0 1rem;
  font-family: inherit;
  font-size: 1.3rem;
  color: #666;
  background-color: #eee;
  border: 1px solid #eee;
  font-weight: 400;
  vertical-align: top;
  display: inline-block;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.button,
.navigation .dropdown-list .button,
.navigation .dropdown-list button,
.navigation .mega-sub-menu .button,
.navigation .mega-sub-menu button,
.navigation .sub-menu .button,
.navigation .sub-menu button,
button {
  line-height: 1.4;
}

.button:hover,
button:hover {
  text-decoration: none;
  color: #666;
  background-color: #d0d0d0;
  border-color: #d0d0d0;
}

.button.small,
button.small {
  padding: 1rem 1.5rem;
  font-size: 1.1rem !important;
}

.button.medium,
button.medium {
  padding: 1.3rem 2.5rem;
  font-size: 1.3rem !important;
}

.button.large,
button.large {
  padding: 1.6rem 3rem;
  font-size: 1.4rem !important;
}

.button.xlarge,
button.xlarge {
  padding: 1.9rem 3.5rem;
  font-size: 1.5rem !important;
}

.button [class*="icon-"].left,
button [class*="icon-"].left {
  margin-right: 0.5rem;
}

.button [class*="icon-"].right,
button [class*="icon-"].right {
  margin-left: 0.5rem;
}

.button-content {
  display: inline-block;
}

.button-content * {
  display: block;
}

input[type="submit"]::-moz-focus-inner {
  border: 0 !important;
}

.button.rounded,
button.rounded {
  border-radius: 0.3rem;
}

.button.pill,
button.pill {
  border-radius: 10.5rem;
}

.button.shadow,
button.shadow {
  -webkit-box-shadow: 0 0.1rem 0.2rem 0 rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 0.1rem 0.2rem 0 rgba(0, 0, 0, 0.5);
  box-shadow: 0 0.1rem 0.2rem 0 rgba(0, 0, 0, 0.5);
}

.button.hard-shadow,
button.hard-shadow {
  border-bottom: 3px solid rgba(0, 0, 0, 0.15);
}

.button.disabled,
.button.disabled:hover,
button.disabled,
button.disabled:hover {
  background-color: #f9f9f9;
  border-color: #eee;
}

.button.disabled,
button.disabled {
  cursor: default;
  color: #ccc;
}

.button-nav {
  padding: 0;
  margin: 0;
  list-style: none;
}

.button-nav li {
  float: left;
  margin: 0 1px 0 0;
}

.button-nav li .button.pill,
.button-nav li .button.rounded,
.button-nav li button.pill,
.button-nav li button.rounded {
  border-radius: 0;
}

.button-nav li:first-child .button.rounded,
.button-nav li:first-child button.rounded,
.button-nav.rounded li:first-child .button,
.button-nav.rounded li:first-child button {
  border-radius: 0.3rem 0 0 0.3rem;
}

.button-nav li:last-child .button.rounded .button-nav li:last-child button.rounded,
.button-nav.rounded li:last-child .button,
.button-nav.rounded li:last-child button {
  border-radius: 0 0.3rem 0.3rem 0;
}

.button-nav li:first-child .button.pill,
.button-nav li:first-child button.pill,
.button-nav.pill li:first-child .button,
.button-nav.pill li:first-child button {
  border-radius: 10.5rem 0 0 10.5rem;
}

.button-nav li:last-child .button.pill,
.button-nav li:last-child button.pill,
.button-nav.pill li:last-child .button,
.button-nav.pill li:last-child button {
  border-radius: 0 10.5rem 10.5rem 0;
}

.button-nav.center li {
  margin-left: -0.3rem;
  display: inline-block;
  float: none;
}

.dropdown {
  position: relative;
  margin-bottom: 1rem;
}

.dropdown .button,
.dropdown button {
  width: 100%;
  text-align: left;
  margin-bottom: 0;
}

.dropdown.disabled .button {
  cursor: default;
}

.dropdown.disabled .button,
.dropdown.disabled .button:hover,
.dropdown.disabled button,
.dropdown.disabled button:hover {
  border-color: #ddd !important;
  background: #fff !important;
  color: #ddd !important;
}

.dropdown-list {
  width: 16rem;
  margin-top: 0.3rem;
  text-align: left;
  background: #fff;
  border: 1px solid #ddd;
  box-shadow: 0 0.2rem 0.2rem rgba(0, 0, 0, 0.3);
  border-radius: 0.3rem;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  z-index: 101;
  -webkit-transition-property: -webkit-transform, opacity, visibility;
  transition-property: transform, opacity, visibility;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
}

.fs-pagination,
.tab-panes .tab-content {
  -webkit-transition-property: opacity, visibility;
}

.dropdown-list:not(.custom-content) li a {
  padding: 0.6rem 2.5rem;
  display: block;
  font-size: 1.2rem;
  color: #666;
  font-weight: 400;
  cursor: pointer;
}

.dropdown-list li a:hover {
  background: #f4f4f4;
  color: #333;
  text-decoration: none;
}

.dropdown-list li.current a {
  background: #f4f4f4;
  color: #666;
}

.dropdown-list.active {
  opacity: 1;
  visibility: visible;
}

.dropdown-list .list-header {
  padding: 0.6rem 2.5rem;
  font-size: 1.1rem;
  font-weight: 700;
  background: #ddd;
}

.dropdown.small .button,
.dropdown.small button {
  padding: 1rem 1.5rem;
}

.dropdown.small .dropdown-list li a {
  padding: 0.4rem 1.5rem;
}

.dropdown.small .dropdown-list {
  width: 14rem;
}

.dropdown.medium .button,
.dropdown.medium button {
  padding: 1.3rem 2.5rem;
}

.dropdown.medium .dropdown-list li a {
  padding: 0.6rem 2.5rem;
}

.dropdown.medium .dropdown-list {
  width: 16rem;
}

.dropdown.large .button,
.dropdown.large button {
  padding: 1.6rem 3rem;
}

.dropdown.large .dropdown-list li a {
  padding: 0.9rem 3rem;
}

.dropdown.large .dropdown-list {
  width: 18rem;
}

.dropdown.xlarge .button,
.dropdown.xlarge button {
  padding: 1.9rem 3.5rem;
}

.dropdown.xlarge .dropdown-list li a {
  padding: 1.2rem 3.5rem;
}

.dropdown.xlarge .dropdown-list {
  width: 20rem;
}

.dropdown.rounded .button,
.dropdown.rounded button {
  border-radius: 0.3rem;
}

ol,
ul {
  margin: 0 0 3rem;
  padding: 0;
  font-size: 1.4rem;
}

ul {
  list-style: none;
}

ol {
  list-style: decimal inside;
}

ul.disc {
  list-style: disc inside;
}

ul.circle {
  list-style: circle inside;
}

ul.square {
  list-style: square inside;
}

ol.decimal-leading-zero {
  list-style: decimal-leading-zero inside;
}

ol.upper-roman {
  list-style: upper-roman inside;
}

ol.lower-alpha {
  list-style: lower-alpha inside;
}

ol ol,
ol ul,
ul ol,
ul ul {
  margin: 0.3rem 0 0.3rem 2rem;
  list-style: inherit;
}

.accordion > ul,
.breadcrumb,
.grid-filter-menu ul,
.social-1 ul,
.social-3 ul,
.social-4 ul,
.tab-nav,
.tms-slides {
  list-style: none;
}

ol [class*="icon-"],
ul [class*="icon-"] {
  margin-right: 1rem;
}

.list-group-dotted,
.list-group.dashed,
.list-group.solid {
  border: none;
}

.list-group li {
  padding: 1rem 0;
  border-bottom: 1px solid #eee;
}

.list-group li li {
  border-top: 1px solid #eee;
}

.list-group > li:first-child {
  padding-top: 0;
}

.list-group li li:last-child {
  padding-bottom: 0;
}

.list-group li:last-child {
  border-bottom: none;
  border-width: 0;
}

.list-group ol,
.list-group ul {
  margin: 0;
}

.list-group.boxed li {
  padding: 1rem 2rem;
}

.list-group.boxed li li:last-child {
  padding: 1rem 2rem;
  border-top: 1px solid;
}

.list-group li ul {
  padding-top: 1rem;
}

.list-group[class*="border-"] li,
.list-group[class*="border-"] li li,
.list-group[class*="border-"] li ul {
  border-color: inherit !important;
}

.list-group li:last-child {
  margin-bottom: 0;
}

.list-group.small li li {
  padding-left: 1.5rem;
  margin-top: 0;
}

.list-group.small li {
  padding: 0.7rem 0;
}

.list-group.small ol,
.list-groups.small ul {
  margin-top: 0.7rem !important;
}

.list-group.boxed.small li,
.list-groups.boxed.small li {
  padding: 0.7rem 1.5rem;
}

.list-group.medium li {
  padding: 1rem 0;
}

.list-group.medium ol,
.list-groups.medium ul {
  margin-top: 1rem !important;
}

.list-group.boxed.medium li {
  padding: 1rem 2rem;
}

.list-group.large li {
  padding: 1.3rem 0;
}

.list-group.large ol,
.list-groups.large ul {
  margin-top: 1.3rem !important;
}

.list-group.boxed.large li {
  padding: 1.3rem 2.5rem;
}

.list-group.xlarge li {
  padding: 1.6rem 0;
}

.list-group.xlarge ol,
.list-groups.xlarge ul {
  margin-top: 1.6rem !important;
}

.list-group.boxed.xlarge li {
  padding: 1.6rem 3rem;
}

.list-group.dotted li {
  border-bottom-style: dotted;
}

.list-group.dotted li li {
  border-top-style: dotted;
}

.list-group.dashed li {
  border-bottom-style: dashed;
}

.list-group.dashed li li {
  border-top-style: dashed;
}

.list-group.thick li {
  border-bottom-width: 0.2rem;
}

.list-group.thick li li {
  border-top-width: 0.2rem;
}

.list-group:not(.boxed) li:last-child {
  border-bottom: none;
  padding-bottom: 0 !important;
}

.list-group li li:last-child {
  border-top: none;
}

.list-group.boxed li {
  border: 1px solid;
  border-bottom: none;
}

.list-group.boxed li:last-child {
  border-bottom: 1px solid;
}

.list-group.boxed.dotted li {
  border-style: dotted;
  border-bottom: none;
}

.list-group.boxed.dotted li:last-child {
  border-bottom: 1px dotted;
}

.list-group.boxed.dotted li li:last-child {
  border-top-style: dotted;
}

.list-group.boxed.dashed li {
  border-style: dashed;
  border-bottom: none;
}

.list-group.boxed.dashed li:last-child {
  border-bottom: 1px dashed;
}

.list-group.boxed.dashed li li:last-child {
  border-top-style: dashed;
}

.list-group.boxed.thick li,
.list-group.boxed.thick li:last-child {
  border-width: 0.2rem;
}

.list-group.rounded li:first-child {
  border-radius: 0.3rem 0.3rem 0 0;
}

.list-group.rounded li:last-child {
  border-radius: 0 0 0.3rem 0.3rem;
}

.list-group.boxed[class*="border-"],
.list-group.boxed[class*="border-"].dashed,
.list-group.boxed[class*="border-"].dotted,
.list-group.boxed[class*="border-"].thick,
.list-group.boxed[class*="border-"]:hover,
.list-group[class*="border-"],
.list-group[class*="border-"].dashed,
.list-group[class*="border-"].dotted,
.list-group[class*="border-"].thick,
.list-group[class*="border-"]:hover {
  border-width: 0;
}

.list-horizontal li {
  display: inline-block;
}

.menu-box {
  overflow: visible;
  margin-bottom: 3rem;
}

.menu-list,
.menu-list li {
  margin: 0 0 3rem;
}

.menu-list h4,
.menu-list li:last-child,
.menu-list p {
  margin-bottom: 0;
}

.menu-list.boxed {
  padding: 2rem;
}

.menu-list li {
  position: relative;
}

.menu-list h4 + p {
  margin-top: 1.3rem;
}

.menu-list .menu-content {
  padding-right: 10rem;
}

.menu-list .menu-description,
.menu-list .menu-price,
.menu-list .menu-title {
  background-color: #fff;
}

.menu-list .menu-content,
.menu-list .menu-description,
.menu-list .menu-price,
.menu-list .menu-title,
.menu-list h4 {
  position: relative;
  z-index: 1;
}

.menu-list .menu-price {
  font-weight: 700;
  position: absolute;
  right: 0;
  bottom: 0;
}

.accordion > ul > li > a,
.tabs .tab-nav > li a {
  font-weight: 400;
  vertical-align: middle;
  color: #666;
  text-decoration: none;
}

.menu-title .menu-price {
  position: relative;
  right: auto;
  left: auto;
  bottom: auto;
}

.menu-list .menu-line {
  height: 1px;
  margin: 0;
  border: 0;
  display: block;
  position: absolute;
  bottom: 8px;
  left: 0;
  right: 0;
  z-index: 0;
  background-image: linear-gradient(to right, #ccc 100%, rgba(0, 0, 0, 0) 0);
  background-position: bottom;
  background-size: 6px 2px;
  background-repeat: repeat-x;
}

.menu-list .callout.rounded,
.menu-list.rounded,
.menu-list.rounded .callout {
  border-radius: 0.3rem;
}

.menu-list.center .menu-price,
.timeline .timeline-description,
.timeline .timeline-title {
  position: relative;
}

.menu-list .callout {
  padding: 2rem;
  background-color: #f4f4f4;
}

.menu-list .callout * {
  background-color: inherit;
}

.menu-list .special-item {
  font-size: 1.3rem;
  font-style: italic;
  text-transform: none;
}

.menu-list h4 .menu-line {
  bottom: 4px;
}

.menu-list .menu-content .menu-line {
  bottom: 8px;
}

.menu-box.small,
.menu-list.boxed.small {
  padding: 1.5rem 1.5rem 0;
}

.menu-box.small .menu-list,
.menu-list.boxed.small li:last-child {
  margin-bottom: 1.5rem;
}

.menu-box.medium,
.menu-list.boxed.medium {
  padding: 2rem 2rem 0;
}

.menu-box.medium .menu-list,
.menu-list.boxed.medium li:last-child {
  margin-bottom: 2rem;
}

.menu-box.large,
.menu-list.boxed.large {
  padding: 2.5rem 2.5rem 0;
}

.menu-box.large .menu-list,
.menu-list.boxed.large li:last-child {
  margin-bottom: 2.5rem;
}

.menu-box.xlarge,
.menu-list.boxed.xlarge {
  padding: 3rem 3rem 0;
}

.menu-box.xlarge .menu-list,
.menu-list.boxed.xlarge li:last-child {
  margin-bottom: 3rem;
}

.timeline li:last-child,
.timeline li:last-child .timeline-description:last-child {
  margin-bottom: 0;
}

.menu-list.center .menu-line {
  display: none;
}

.menu-list.center .menu-content {
  padding: 0;
}

.menu-list.center .menu-description {
  display: block;
}

.menu-list.right .menu-content {
  padding-left: 10rem;
  padding-right: 0;
}

.menu-list.right .menu-price {
  left: 0;
  right: auto;
}

.menu-list.boxed {
  border-style: solid;
  border-width: 1px;
}

.menu-list.boxed.dashed {
  border-style: dashed;
}

.menu-list.boxed.dotted {
  border-style: dotted;
}

.dotted .menu-line {
  height: 2px;
  background-image: radial-gradient(circle closest-side, #ccc 99%, rgba(0, 0, 0, 0) 0);
}

.dashed .menu-line {
  height: 1px;
  background-image: linear-gradient(to right, #ccc 50%, rgba(0, 0, 0, 0) 0);
}

.timeline li {
  padding: 0;
  margin: 0;
}

.timeline.vertical-indication .timeline-title {
  padding-right: 5rem;
  z-index: 1;
}

.timeline.vertical-indication .timeline-description {
  padding-left: 5rem;
}

.timeline.vertical-indication .timeline-title:after {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  content: "";
  border: 2px solid #333;
  background-color: #fff;
  position: absolute;
  left: 100%;
  top: 0;
  z-index: 100;
}

.timeline.vertical-indication .timeline-description:before {
  width: 0.1rem;
  content: "";
  background-color: #eee;
  position: absolute;
  left: 0.5rem;
  top: 0;
  bottom: 0;
}

.accordion:after,
.tab-nav:after,
.tab-panes > div.active,
.tabs.vertical:after {
  content: ".";
}

.timeline.vertical-indication li:last-child .timeline-description:before {
  display: none;
}

.tabs {
  margin: 0 0 3rem;
  padding: 0;
}

.tab-nav {
  margin: 0;
  padding: 0;
  font-size: 0;
}

.tab-nav:after {
  height: 0;
  display: block;
  overflow: hidden;
  clear: both;
}

.tabs .tab-nav > li {
  margin: 0 1px 0 0;
  display: inline-block;
}

.tabs.vertical:after {
  display: block;
  overflow: hidden;
  clear: both;
}

.tabs.vertical .tab-nav > li {
  margin: 0 0 1px;
  display: block;
  float: none;
}

.tabs.vertical .tab-nav {
  width: 25%;
  float: left;
}

.tabs .tab-nav > li:last-child {
  margin-right: 0;
}

.tabs.right .tab-nav > li {
  margin: 0 0 0 1px;
}

.tabs.right.vertical .tab-nav > li {
  margin: 0 0 1px;
  float: none;
}

.tabs.right.vertical .tab-nav {
  width: 25%;
  float: right;
}

.tabs .tab-nav > li.active a,
.tabs .tab-nav > li.active a:hover {
  background: #fff;
  border-top: 1px solid #eee;
  border-right: 1px solid #eee;
  border-bottom: 1px solid transparent;
  border-left: 1px solid #eee;
  cursor: default;
}

.tabs.vertical .tab-nav > li.active a,
.tabs.vertical .tab-nav > li.active a:hover {
  background: #fff;
  border-top: 1px solid #eee;
  border-right: 1px solid transparent;
  border-bottom: 1px solid #eee;
  border-left: 1px solid #eee;
  cursor: default;
  position: relative;
}

.tabs.right.vertical .tab-nav > li.active a,
.tabs.right.vertical .tab-nav > li.active a:hover {
  background: #fff;
  border-top: 1px solid #eee;
  border-right: 1px solid #eee;
  border-bottom: 1px solid #eee;
  border-left: 1px solid transparent;
}

.tabs .tab-nav > li a {
  padding: 1rem 1.5rem;
  margin: 0;
  font-size: 1.2rem;
  display: inline-block;
  background-color: #eee;
  border: 1px solid #eee;
  cursor: pointer;
}

.tabs.vertical .tab-nav > li a {
  display: block;
}

.tabs .tab-nav > li a:hover {
  text-decoration: none;
  color: #666;
  background: #d0d0d0;
  border-color: #d0d0d0;
}

.tab-panes {
  margin-top: -1px;
  padding: 1.5rem;
  background: #fff;
  border: 1px solid #eee;
}

.tabs.vertical .tab-panes {
  width: 75%;
  margin-top: 0;
  margin-left: -1px;
  float: left;
}

.tabs.right.vertical .tab-panes {
  margin-left: 0;
  margin-right: -1px;
  float: right;
}

.tab-panes > div {
  display: none;
  background: 0 0;
}

.tab-panes .tab-content {
  opacity: 0;
  transition-property: opacity, visibility;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
}

.tab-panes .animate-in .tab-content {
  opacity: 1;
}

.tab-panes > div.active {
  display: block;
  overflow: hidden;
  clear: both;
}

.tab-panes > div > :first-child {
  margin-top: 0;
}

.tab-panes > div:not(.form-element):last-child {
  margin-bottom: 0;
}

.tabs.small .tab-nav > li a {
  padding: 0.7rem 1rem;
  font-size: 1rem;
}

.tabs.small .tab-panes {
  padding: 1rem;
}

.tabs.medium .tab-nav > li a {
  padding: 1rem 1.5rem;
}

.tabs.medium .tab-panes {
  padding: 1.5rem;
}

.tabs.large .tab-nav > li a {
  padding: 1.3rem 2.5rem;
  font-size: 1.4rem;
}

.tabs.large .tab-panes {
  padding: 2.5rem;
}

.tabs.xlarge .tab-nav > li a {
  padding: 1.6rem 3rem;
  font-size: 1.5rem;
}

.tabs.xlarge .tab-panes {
  padding: 3rem;
}

.tabs.rounded .tab-nav > li:first-child a {
  border-radius: 0.3rem 0 0;
}

.tabs.right.rounded .tab-nav > li:first-child a,
.tabs.rounded .tab-nav > li:last-child a {
  border-radius: 0 0.3rem 0 0;
}

.tabs.right.rounded .tab-nav > li:last-child a {
  border-radius: 0.3rem 0 0;
}

.tabs.vertical.rounded .tab-nav > li:last-child a {
  border-radius: 0.3rem 0 0 0.3rem;
}

.tabs.right.vertical.rounded .tab-nav > li:last-child a {
  border-radius: 0.3rem 0;
}

.tabs.rounded .tab-panes {
  border-radius: 0 0.3rem 0.3rem;
}

.tabs.right.rounded .tab-panes {
  border-radius: 0.3rem 0 0.3rem 0.3rem;
}

.tabs.solid .tab-nav > li.active a,
.tabs.solid .tab-nav > li.active a:hover,
.tabs.solid .tab-panes {
  border-style: solid;
}

.tabs.dashed .tab-nav > li.active a,
.tabs.dashed .tab-nav > li.active a:hover,
.tabs.dashed .tab-panes,
.tabs.dotted .tab-nav > li.active a,
.tabs.dotted .tab-nav > li.active a:hover,
.tabs.dotted .tab-panes {
  border-style: dashed;
}

.tabs.thick .tab-nav > li.active a,
.tabs.thick .tab-nav > li.active a:hover,
.tabs.thick .tab-panes {
  border-width: 0.2rem;
}

.tabs[class*="border-"] {
  border: none;
}

.accordion {
  margin: 0 0 3rem;
  padding: 0;
  border: none !important;
}

.accordion > ul {
  margin: 0;
  padding: 0;
}

.accordion:after {
  height: 0;
  display: block;
  overflow: hidden;
  clear: both;
}

.accordion > ul > li {
  margin: 0 0 1px;
  display: block;
}

.accordion > ul > li.active > a {
  background: 0 0;
  border-top: 1px solid #eee;
  border-right: 1px solid #eee;
  border-bottom: 1px solid transparent;
  border-left: 1px solid #eee;
}

.accordion > ul > li > a {
  padding: 1rem 1.5rem;
  margin: 0;
  font-size: 1.2rem;
  display: block;
  background-color: #eee;
  border: 1px solid #eee;
  cursor: pointer;
}

.accordion > ul > li > a:hover {
  text-decoration: none;
  color: #666;
  background: #d0d0d0;
  border-color: #d0d0d0;
}

.table.striped tbody tr:nth-child(even) td,
.table.striped tbody tr:nth-child(even) th,
.table > thead {
  background-color: #eee;
}

.accordion > ul > li > div {
  height: 0;
  visibility: hidden;
  border: 1px solid #eee;
  overflow: hidden;
  -webkit-transition-property: height, visibility;
  transition-property: height, visibility;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

.accordion > ul > li.active > div {
  height: auto;
  visibility: visible;
}

.accordion .accordion-content {
  height: auto;
  padding: 1.5rem;
  overflow: hidden;
  display: block;
}

.accordion > ul > li > div > :first-child {
  margin-top: 0;
}

.accordion > ul > li > div:not(.form-element):last-child {
  margin-bottom: 0;
}

.pricing-table,
table.table {
  margin-bottom: 3rem;
  width: 100%;
}

.accordion.small li > a {
  padding: 0.7rem 1rem;
  font-size: 1rem;
}

.accordion.small .accordion-content {
  padding: 1rem;
}

.accordion.medium li > a {
  padding: 1rem 1.5rem;
}

.accordion.medium .accordion-content {
  padding: 1.5rem;
}

.accordion.large li > a {
  padding: 1.3rem 2.5rem;
  font-size: 1.4rem;
}

.accordion.large .accordion-content {
  padding: 2.5rem;
}

.accordion.xlarge li > a {
  padding: 1.6rem 3rem;
  font-size: 1.5rem;
}

.accordion.xlarge .accordion-content {
  padding: 3rem;
}

.accordion.rounded li:first-child > a {
  border-radius: 0.3rem 0.3rem 0 0;
}

.accordion.rounded li:last-child > a,
.accordion.rounded li > div {
  border-radius: 0 0 0.3rem 0.3rem;
}

.accordion.rounded li.active:last-child > a:hover {
  border-radius: 0;
}

.accordion.solid li > a,
.accordion.solid li > div {
  border-style: solid;
}

.accordion.dashed li > a,
.accordion.dashed li > div,
.accordion.dotted li > a,
.accordion.dotted li > div {
  border-style: dashed;
}

.accordion.thick li > a,
.accordion.thick li > div {
  border-width: 0.2rem;
}

table.table {
  border: 1px solid #eee;
  font-size: 1.2rem;
  line-height: 1.5;
  text-align: left;
  border-collapse: separate;
  border-spacing: 0;
}

.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
  padding: 1rem 1.5rem;
}

.table > thead {
  color: #666;
}

.table td,
.table th + th {
  border-left: 1px solid #eee;
  border-bottom: 1px solid #eee;
}

.table tr:last-child > td,
.table tr:last-child > th,
.table > thead > tr > th {
  border-bottom: none;
}

.table.small > tbody > tr > td,
.table.small > tbody > tr > th,
.table.small > tfoot > tr > td,
.table.small > tfoot > tr > th,
.table.small > thead > tr > td,
.table.small > thead > tr > th {
  padding: 0.7rem 1rem;
  font-size: 1rem;
}

.table.medium > tbody > tr > td,
.table.medium > tbody > tr > th,
.table.medium > tfoot > tr > td,
.table.medium > tfoot > tr > th,
.table.medium > thead > tr > td,
.table.medium > thead > tr > th {
  padding: 1rem 1.5rem;
}

.table.large > tbody > tr > td,
.table.large > tbody > tr > th,
.table.large > tfoot > tr > td,
.table.large > tfoot > tr > th,
.table.large > thead > tr > td,
.table.large > thead > tr > th {
  padding: 1.3rem 2.5rem;
  font-size: 1.4rem;
}

.table.xlarge > tbody > tr > td,
.table.xlarge > tbody > tr > th,
.table.xlarge > tfoot > tr > td,
.table.xlarge > tfoot > tr > th,
.table.xlarge > thead > tr > td,
.table.xlarge > thead > tr > th {
  padding: 1.6rem 3rem;
  font-size: 1.5rem;
}

.table.rounded {
  border-radius: 0.3rem;
}

.pricing-table {
  text-align: center;
}

.progress-bar,
.range-slider {
  text-align: right;
  font-weight: 700;
  overflow: hidden;
}

.pricing-table:after,
.pricing-table:before {
  content: ".";
  display: block;
  height: 0;
  overflow-x: hidden;
  overflow-y: hidden;
}

.pricing-table:after {
  clear: both;
}

.pricing-table.columns-1 .pricing-table-column {
  width: 100%;
}

.pricing-table.columns-2 .pricing-table-column {
  width: 50%;
}

.pricing-table.columns-3 .pricing-table-column {
  width: 33.33333%;
}

.pricing-table.columns-4 .pricing-table-column {
  width: 25%;
}

.pricing-table.columns-5 .pricing-table-column {
  width: 20%;
}

.pricing-table-column {
  float: left;
}

[class*="border-"] .pricing-table-column {
  border-width: 1px 1px 1px 0;
  border-style: solid;
}

.pricing-table-column:first-child {
  border-width: 1px;
}

.pricing-table-column > :not(hr) {
  padding: 3rem;
}

.pricing-table-column > :not(:first-child) {
  padding-top: 0;
}

.pricing-table-column > [class*="bkg-"] {
  padding: 3rem;
}

.pricing-table-column > hr {
  margin: 3rem;
  margin-top: 0;
}

.pricing-table-column.callout {
  margin-left: -1px;
  margin-bottom: -2rem;
  border: 1px solid #fff;
  box-shadow: 0 0 0.8rem rgba(0, 0, 0, 0.2);
  position: relative;
  top: -1.5rem;
  z-index: 1;
}

.pricing-table-column.callout > .pricing-table-header {
  padding-top: 4.5rem;
}

.pricing-table-column.callout > .pricing-table-footer {
  padding-bottom: 4.5rem;
}

.pricing-table-header h2 {
  margin: 0;
  font-size: 2.5rem;
}

.pricing-table-header[class*="bkg-"] h2 {
  color: #fff;
}

.pricing-table-price {
  color: #666;
}

.pricing-table-price h4 {
  margin: 0;
  font-size: 3rem;
}

.pricing-table-price[class*="bkg-"] h4 {
  color: #fff;
}

.pricing-table-price .currency {
  margin-right: 0.2rem;
  font-size: 1.3rem;
  position: relative;
  top: -1rem;
}

.pricing-table-price .interval {
  font-size: 1.3rem;
  opacity: 0.5;
}

.pricing-table-options {
  margin-bottom: 0;
  list-style: none;
  line-height: inherit;
}

.pricing-table-options del {
  opacity: 0.5;
  text-decoration: none;
}

.pricing-table-options li {
  margin-bottom: 0;
  padding: 0.7rem 0;
}

.pricing-table-text {
  line-height: inherit;
}

.pricing-table-footer .button,
.pricing-table-footer button,
.pricing-table-text p:last-child {
  margin-bottom: 0;
}

.pricing-table[class*="border-"] {
  border-width: 0;
}

.pricing-table[class*="border-"] .pricing-table-column {
  border-color: inherit !important;
}

.pricing-table.small .pricing-table-header h2 {
  font-size: 2.5rem;
}

.pricing-table.small .pricing-table-price .currency {
  font-size: 1.3rem;
  top: -1rem;
}

.pricing-table.small .pricing-table-price .interval {
  font-size: 1.1rem;
}

.pricing-table.medium .pricing-table-header h2,
.pricing-table.small .pricing-table-price h4 {
  font-size: 3rem;
}

.pricing-table.medium .pricing-table-price .currency {
  font-size: 1.6rem;
  top: -1.4rem;
}

.pricing-table.medium .pricing-table-price .interval {
  font-size: 1.3rem;
}

.pricing-table.medium .pricing-table-price h4 {
  font-size: 3.6rem;
}

.pricing-table.large .pricing-table-header h2 {
  font-size: 3rem;
}

.pricing-table.large .pricing-table-price .currency {
  font-size: 1.6rem;
  top: -2rem;
}

.pricing-table.large .pricing-table-price .interval {
  font-size: 1.4rem;
}

.pricing-table.large .pricing-table-price h4 {
  font-size: 4.5rem;
}

.pricing-table.xlarge .pricing-table-header h2 {
  font-size: 3.2rem;
}

.pricing-table.xlarge .pricing-table-price .currency {
  font-size: 2rem;
  top: -2.7rem;
}

.pricing-table.xlarge .pricing-table-price .interval {
  font-size: 1.6rem;
}

.pricing-table.xlarge .pricing-table-price h4 {
  font-size: 6rem;
}

.pricing-table.rounded .pricing-table-column:first-child {
  border-radius: 0.3rem 0 0 0.3rem;
}

.pricing-table.rounded .pricing-table-column:last-child {
  border-radius: 0 0.3rem 0.3rem 0;
}

.pricing-table-column.rounded,
.pricing-table.rounded .pricing-table-column.callout {
  border-radius: 0.3rem;
}

.box {
  width: 100%;
  padding: 1.5rem;
  margin-bottom: 3rem;
  border: 1px solid #eee;
  overflow: hidden;
}

.equalize .column > .box,
.grid-item .box {
  height: 100%;
}

.box.dotted {
  border-style: dotted;
}

.box.dashed {
  border-style: dashed;
}

.box > :first-child {
  margin-top: 0;
}

.box:not(.form-element):last-child {
  margin-bottom: 0;
}

.box > a:not(.button) {
  color: inherit;
  font-weight: 700;
}

.box > a:not(.button):hover {
  color: inherit;
  text-decoration: underline;
}

.box.dismissable .close,
.box.dismissable .close:hover,
.header .logo a,
.shop .product-price ins,
a,
a:hover,
p a,
p a:hover {
  text-decoration: none;
}

.box.dismissable {
  max-height: 100rem;
  padding-right: 3rem;
}

.box.dismissable .close {
  opacity: 0.5;
  display: block;
  float: right;
  position: relative;
  right: -2rem;
}

.box.dismissable .close:hover {
  opacity: 1;
}

.box.small {
  padding: 1rem;
  font-size: 1rem;
}

.box.small.dismissable {
  padding-right: 2rem;
}

.box.small.dismissable .close {
  right: -1.5rem;
}

.box.medium {
  padding: 1.5rem;
}

.box.large {
  padding: 2.5rem;
  font-size: 1.4rem;
}

.box.large.dismissable {
  padding-right: 5rem;
}

.box.large.dismissable .close {
  right: -3rem;
}

.box.xlarge {
  padding: 3rem;
  font-size: 1.5rem;
}

.box.xlarge.dismissable {
  padding-right: 6rem;
}

.box.xlarge.dismissable .close {
  right: -3.5rem;
}

.box.rounded {
  border-radius: 0.3rem;
}

.label {
  padding: 0.2rem 0.6rem 0.3rem;
  border: 1px solid #eee;
}

.bar,
.range-active-segment {
  width: 100%;
  padding: 0 1.5rem;
  -webkit-backface-visibility: hidden;
}

.label [class*="icon-"]:before {
  margin-left: 0;
  margin-right: 0.5rem;
}

.highlight,
.label {
  background: #eee;
  color: #666;
}

.label.rounded {
  border-radius: 0.3rem;
}

.progress-bar-group {
  margin-bottom: 3rem;
}

.progress-bar-group .progress-bar:last-child {
  margin-bottom: 0;
}

.progress-bar {
  width: 100%;
  height: 2rem;
  margin-bottom: 2rem;
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: #666;
  border: 1px solid #eee;
  background: #eee;
  transform: translate3d(0, 0, 0);
}

.progress-bar.tmh-perspective {
  overflow: hidden !important;
}

.aux-navigation-active .progress-bar {
  -webkit-mask-image: none;
}

.bar {
  height: 100%;
  border: 1px solid #d0d0d0;
  background: #d0d0d0;
}

.progress-bar-label {
  margin-bottom: 0.8rem;
  color: #666;
  font-size: 1.2rem;
  display: block;
}

.progress-bar-label + .progress-bar {
  margin-bottom: 1.7rem;
}

.progress-bar.small {
  height: 1.3rem;
  font-size: 1rem;
  line-height: 0.8rem;
}

.progress-bar.medium {
  height: 2rem;
}

.progress-bar.large {
  height: 3rem;
  font-size: 1.4rem;
  line-height: 2.6rem;
}

.progress-bar.xlarge {
  height: 4rem;
  font-size: 1.5rem;
  line-height: 3.6rem;
}

.progress-bar.rounded,
.progress-bar.rounded .bar {
  border-radius: 0.3rem;
}

.progress-bar.pill,
.progress-bar.pill .bar {
  border-radius: 10.5rem;
}

.range-slider {
  width: 100%;
  height: 2rem;
  margin-bottom: 2rem;
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: #666;
  border: 1px solid #eee;
  background: #eee;
  position: relative;
  -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
}

.aux-navigation-active .range-slider {
  -webkit-mask-image: none;
}

.range-active-segment {
  height: 100%;
  border: 1px solid #d0d0d0;
  background: #d0d0d0;
  position: absolute;
  z-index: 0;
}

.range-handle {
  width: 1.8rem;
  height: 1.8rem;
  display: inline-block;
  background: #666;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

.range-slider.small {
  height: 1.3rem;
  font-size: 1rem;
  line-height: 0.8rem;
}

.range-slider.small .range-handle {
  width: 1.1rem;
  height: 1.1rem;
}

.range-slider.medium {
  height: 2rem;
}

.range-slider.medium .range-handle {
  width: 1.8rem;
  height: 1.8rem;
}

.range-slider.large {
  height: 3rem;
  font-size: 1.4rem;
  line-height: 2.6rem;
}

.range-slider.large .range-handle {
  width: 2.8rem;
  height: 2.8rem;
}

.range-slider.xlarge {
  height: 4rem;
  font-size: 1.5rem;
  line-height: 3.6rem;
}

.range-slider.xlarge .range-handle {
  width: 3.8rem;
  height: 3.8rem;
}

.range-slider .range-handle,
.range-slider.rounded,
.range-slider.rounded .range-active-segment {
  border-radius: 0.3rem;
}

.range-slider .range-handle,
.range-slider.pill,
.range-slider.pill .range-active-segment {
  border-radius: 10.5rem;
}

.video-container {
  max-width: 100%;
  overflow: hidden;
}

.audio-container,
.video-container {
  margin-bottom: 3rem;
}

.audio-container .mejs-container {
  height: 3rem;
}

iframe {
  width: 100%;
  border: none;
  overflow: hidden;
}

.divider,
hr {
  margin: 3rem 0;
  border-top: 1px solid #eee;
  border-right: 0;
  border-bottom: 0;
  border-left: 0;
  height: 0;
  clear: both;
}

address,
p {
  margin: 0 0 3rem;
}

.divider.dashed,
hr.dashed {
  border-style: dashed;
}

.divider.dotted,
hr.dotted {
  border-style: dotted;
}

.divider.thick,
hr.thick {
  border-top-width: 0.2rem;
}

.divider[class*="border-"],
.divider[class*="border-"].dashed,
.divider[class*="border-"].dotted,
hr[class*="border-"],
hr[class*="border-"].dashed,
hr[class*="border-"].dotted {
  border-right-style: none !important;
  border-bottom-style: none !important;
  border-left-style: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #333;
  font-weight: 700;
  line-height: 1;
  margin: 0 0 2rem;
}

h1 {
  font-size: 4.8rem;
}

h2 {
  font-size: 3.2rem;
}

h3 {
  font-size: 2.4rem;
}

h3.widget-title,
h4 {
  font-size: 2.1rem;
}

h5 {
  font-size: 1.8rem;
}

h6 {
  font-size: 1.6rem;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
}

h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover {
  color: inherit;
}

h1 span[class*="border-"],
h1 span[class*="bkg-"],
h1[class*="border-"],
h1[class*="bkg-"],
h2 span[class*="border-"],
h2 span[class*="bkg-"],
h2[class*="border-"],
h2[class*="bkg-"],
h3 span[class*="border-"],
h3 span[class*="bkg-"],
h3[class*="border-"],
h3[class*="bkg-"],
h4 span[class*="border-"],
h4 span[class*="bkg-"],
h4[class*="border-"],
h4[class*="bkg-"],
h5 span[class*="border-"],
h5 span[class*="bkg-"],
h5[class*="border-"],
h5[class*="bkg-"],
h6 span[class*="border-"],
h6 span[class*="bkg-"],
h6[class*="border-"],
h6[class*="bkg-"],
p[class*="border-"],
p[class*="bkg-"] {
  padding: 0.4rem 0.6rem;
}

.lead {
  margin-bottom: 3rem;
  font-size: 2.2rem;
  line-height: 1.5;
  font-weight: 200;
}

.separator {
  width: 10rem;
  height: 0.4rem;
  display: block;
  background-color: #333;
  margin-bottom: 2rem;
}

.separator.small {
  width: 7rem;
  height: 0.2rem;
}

.separator.large {
  width: 13rem;
  height: 0.6rem;
}

.separator.xlarge {
  width: 16rem;
  height: 0.8rem;
}

.center .separator,
.separator.center {
  margin-right: auto;
  margin-left: auto;
}

.right .separator,
.separator.right {
  margin-left: auto;
}

.font-alt-1 {
  font-family: "Times New Roman", Arial, sans-serif;
}

em {
  font-style: italic;
}

strong {
  font-weight: 700;
}

small {
  font-size: 65%;
}

[class*="weight-"] a,
[class*="weight-"] p,
[class*="weight-"] span {
  font-weight: inherit;
}

.weight-light {
  font-weight: 100;
}

.weight-thin {
  font-weight: 200;
}

.weight-regular {
  font-weight: 400;
}

.weight-semi-bold {
  font-weight: 600;
}

.weight-bold {
  font-weight: 700;
}

.weight-xbold {
  font-weight: 900;
}

.text-shadow {
  text-shadow: 0.2rem 0.2rem 0.2rem rgba(0, 0, 0, 0.5);
}

[class*="lspacing-"] a,
[class*="lspacing-"] p,
[class*="lspacing-"] span {
  letter-spacing: inherit;
}

.lspacing-small {
  letter-spacing: 0.2rem;
}

.lspacing-medium {
  letter-spacing: 0.3rem;
}

.lspacing-large {
  letter-spacing: 0.5rem;
}

.lspacing-xlarge {
  letter-spacing: 0.8rem;
}

a,
p a {
  color: #666;
}

a:hover,
p a:hover {
  color: #333;
}

::selection {
  background: #333;
  color: #fff;
}

::-moz-selection {
  background: #333;
  color: #fff;
}

blockquote {
  margin: 0 0 3rem;
  font-size: 1.8rem;
  line-height: 1.5;
}

blockquote p {
  margin-bottom: 0;
  font-size: 1.8rem;
}

blockquote .cite,
blockquote cite {
  margin-top: 2rem;
  font-size: 60%;
  display: block;
}

blockquote.small,
blockquote.small p {
  font-size: 1.6rem;
}

blockquote.medium,
blockquote.medium p {
  font-size: 1.8rem;
}

blockquote.large,
blockquote.large p {
  font-size: 2.1rem;
}

blockquote.xlarge,
blockquote.xlarge p {
  font-size: 2.4rem;
}

blockquote.border {
  padding: 0 0 0 2.5rem;
  border-left: 1px solid #eee;
}

blockquote.border.small {
  padding-left: 2rem;
  padding-right: 2rem;
}

blockquote.border.medium {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

blockquote.border.large {
  padding-left: 3rem;
  padding-right: 3rem;
}

blockquote.border.xlarge {
  padding-left: 3.5rem;
  padding-right: 3.5rem;
}

blockquote.icon [class*="icon-"] {
  font-size: 150%;
  text-align: center;
  line-height: 0;
}

blockquote.avatar span,
blockquote.avatar span img {
  width: 5rem;
  height: 5rem;
  border-radius: 7rem;
  display: inline-block;
}

blockquote.avatar.small span,
blockquote.avatar.small span img {
  width: 4rem;
  height: 4rem;
}

blockquote.avatar.medium span,
blockquote.avatar.medium span img {
  width: 5rem;
  height: 5rem;
}

blockquote.avatar.large span,
blockquote.avatar.large span img {
  width: 6rem;
  height: 6rem;
}

blockquote.avatar.xlarge span,
blockquote.avatar.xlarge span img {
  width: 7rem;
  height: 7rem;
}

blockquote.pull-left {
  width: 40%;
  float: left;
  margin-right: 2rem;
  margin-bottom: 2rem;
}

blockquote.pull-right {
  width: 40%;
  float: right;
  margin-left: 2rem;
  margin-bottom: 2rem;
}

blockquote.border.center,
blockquote.center {
  margin-left: auto;
  margin-right: auto;
}

blockquote.border.right {
  padding: 0 2.5rem 0 0;
  border-left: none;
  border-right: 1px solid #eee;
}

blockquote.border.center {
  padding: 2.5rem 0 0;
  border-left: none;
  border-top: 1px solid #eee;
}

.breadcrumb {
  overflow: hidden;
}

.breadcrumb li {
  float: left;
}

.breadcrumb li,
.breadcrumb li a {
  font-size: 1.2rem;
}

.breadcrumb li:after,
.breadcrumb li:before {
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  font-family: icomoon;
}

.breadcrumb li:after {
  content: "\e640";
}

.breadcrumb.center li {
  display: inline-block;
  float: none;
}

.breadcrumb.right li:after {
  padding: 0;
  content: "";
}

.breadcrumb.right li:before {
  content: "\e63f";
}

.breadcrumb li:last-child:after,
.breadcrumb.right li:first-child:before {
  content: "";
  padding: 0;
}

code,
kbd,
samp {
  font-family: "Courier New", Courier, monospace, sans-serif;
  text-align: left;
}

[class*=" icon-"],
[class*="tms-arrow-nav"]:before,
[class*="tml-nav"]:before,
[class^="tms-arrow-nav"]:before,
[class^="tml-nav"]:before,
[class^="icon-"] {
  font-family: icomoon;
  speak: none;
  font-variant: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

pre {
  max-width: 100%;
  margin: 0;
  white-space: pre-wrap;
  word-break: break-all;
  word-wrap: break-word;
}

pre code {
  line-height: 1.5;
  white-space: pre-wrap;
}

.tag {
  color: #074a72;
}

.tag .att {
  color: #47a4db;
}

.tag .val {
  color: #d74950;
}

.tag .comm {
  color: #999;
}

@font-face {
  font-family: icomoon;
  src: url(../fonts/entypo.eot);
  src: url(../fonts/entypo.eot) format("embedded-opentype"),
    url(../fonts/entypo.woff) format("woff"), url(../fonts/entypo.ttf) format("truetype"),
    url(../fonts/entypo.svg) format("svg");
  font-weight: 400;
  font-style: normal;
}

[class*=" icon-"],
[class^="icon-"] {
  font-style: normal;
  font-weight: 400;
  text-transform: none;
  line-height: 1;
  display: inline-block;
}

.icon-add-to-list:before {
  content: "\e600";
}

.icon-add-user:before {
  content: "\e601";
}

.icon-address:before {
  content: "\e602";
}

.icon-adjust:before {
  content: "\e603";
}

.icon-air:before {
  content: "\e604";
}

.icon-aircraft-landing:before {
  content: "\e605";
}

.icon-aircraft-take-off:before {
  content: "\e606";
}

.icon-aircraft:before {
  content: "\e607";
}

.icon-align-bottom:before {
  content: "\e608";
}

.icon-align-horizontal-middle:before {
  content: "\e609";
}

.icon-align-left:before {
  content: "\e60a";
}

.icon-align-right:before {
  content: "\e60b";
}

.icon-align-top:before {
  content: "\e60c";
}

.icon-align-vertical-middle:before {
  content: "\e60d";
}

.icon-archive:before {
  content: "\e60e";
}

.icon-area-graph:before {
  content: "\e60f";
}

.icon-arrow-bold-down:before {
  content: "\e610";
}

.icon-arrow-bold-left:before {
  content: "\e611";
}

.icon-arrow-bold-right:before {
  content: "\e612";
}

.icon-arrow-bold-up:before {
  content: "\e613";
}

.icon-arrow-down:before {
  content: "\e614";
}

.icon-arrow-left:before {
  content: "\e615";
}

.icon-arrow-long-down:before {
  content: "\e616";
}

.icon-arrow-long-left:before {
  content: "\e617";
}

.icon-arrow-long-right:before {
  content: "\e618";
}

.icon-arrow-long-up:before {
  content: "\e619";
}

.icon-arrow-right:before {
  content: "\e61a";
}

.icon-arrow-up:before {
  content: "\e61b";
}

.icon-arrow-with-circle-down:before {
  content: "\e61c";
}

.icon-arrow-with-circle-left:before {
  content: "\e61d";
}

.icon-arrow-with-circle-right:before {
  content: "\e61e";
}

.icon-arrow-with-circle-up:before {
  content: "\e61f";
}

.icon-attachment:before {
  content: "\e620";
}

.icon-awareness-ribbon:before {
  content: "\e621";
}

.icon-back-in-time:before {
  content: "\e622";
}

.icon-back:before {
  content: "\e623";
}

.icon-bar-graph:before {
  content: "\e624";
}

.icon-battery:before {
  content: "\e625";
}

.icon-beamed-note:before {
  content: "\e626";
}

.icon-bell:before {
  content: "\e627";
}

.icon-blackboard:before {
  content: "\e628";
}

.icon-block:before {
  content: "\e629";
}

.icon-book:before {
  content: "\e62a";
}

.icon-bookmark:before {
  content: "\e62b";
}

.icon-bookmarks:before {
  content: "\e62c";
}

.icon-bowl:before {
  content: "\e62d";
}

.icon-box:before {
  content: "\e62e";
}

.icon-briefcase:before {
  content: "\e62f";
}

.icon-browser:before {
  content: "\e630";
}

.icon-brush:before {
  content: "\e631";
}

.icon-bucket:before {
  content: "\e632";
}

.icon-bug:before {
  content: "\e633";
}

.icon-cake:before {
  content: "\e634";
}

.icon-calculator:before {
  content: "\e635";
}

.icon-calendar:before {
  content: "\e636";
}

.icon-camera:before {
  content: "\e637";
}

.icon-ccw:before {
  content: "\e638";
}

.icon-chat:before {
  content: "\e639";
}

.icon-check:before {
  content: "\e63a";
}

.icon-down:before {
  content: "\e63b";
}

.icon-left:before {
  content: "\e63c";
}

.icon-right:before {
  content: "\e63d";
}

.icon-down-open-mini:before {
  content: "\e63e";
}

.icon-left-open-mini:before {
  content: "\e63f";
}

.icon-right-open-mini:before {
  content: "\e640";
}

.icon-up-open-mini:before {
  content: "\e641";
}

.icon-down-open-big:before {
  content: "\e642";
}

#tms-prev:before,
.icon-left-open-big:before {
  content: "\e643";
}

.icon-right-open-big:before {
  content: "\e644";
}

.icon-up-open-big:before {
  content: "\e645";
}

.icon-up:before {
  content: "\e646";
}

.icon-down-circled:before {
  content: "\e647";
}

.icon-left-circled:before {
  content: "\e648";
}

.icon-right-circled:before {
  content: "\e649";
}

.icon-up-circled:before {
  content: "\e64a";
}

.icon-circle-with-cross:before {
  content: "\e64b";
}

.icon-circle-with-minus:before {
  content: "\e64c";
}

.icon-circle-with-plus:before {
  content: "\e64d";
}

.icon-circle:before {
  content: "\e64e";
}

.icon-circular-graph:before {
  content: "\e64f";
}

.icon-clapperboard:before {
  content: "\e650";
}

.icon-classic-computer:before {
  content: "\e651";
}

.icon-clipboard:before {
  content: "\e652";
}

.icon-clock:before {
  content: "\e653";
}

.icon-cloud:before {
  content: "\e654";
}

.icon-code:before {
  content: "\e655";
}

.icon-cog:before {
  content: "\e656";
}

.icon-colours:before {
  content: "\e657";
}

.icon-compass:before {
  content: "\e658";
}

.icon-fast-backward:before {
  content: "\e659";
}

.icon-fast-forward:before {
  content: "\e65a";
}

.icon-jump-to-start:before {
  content: "\e65b";
}

.icon-next:before {
  content: "\e65c";
}

.icon-paus:before {
  content: "\e65d";
}

.icon-play:before {
  content: "\e65e";
}

.icon-record:before {
  content: "\e65f";
}

.icon-stop:before {
  content: "\e660";
}

.icon-volume:before {
  content: "\e661";
}

.icon-copy:before {
  content: "\e662";
}

.icon-creative-commons-attribution:before {
  content: "\e663";
}

.icon-creative-commons-noderivs:before {
  content: "\e664";
}

.icon-creative-commons-noncommercial-eu:before {
  content: "\e665";
}

.icon-creative-commons-noncommercial-us:before {
  content: "\e666";
}

.icon-creative-commons-public-domain:before {
  content: "\e667";
}

.icon-creative-commons-remix:before {
  content: "\e668";
}

.icon-creative-commons-share:before {
  content: "\e669";
}

.icon-creative-commons-sharealike:before {
  content: "\e66a";
}

.icon-creative-commons:before {
  content: "\e66b";
}

.icon-credit-card:before {
  content: "\e66c";
}

.icon-credit:before {
  content: "\e66d";
}

.icon-crop:before {
  content: "\e66e";
}

.icon-cancel:before {
  content: "\e66f";
}

.icon-cup:before {
  content: "\e670";
}

.icon-cw:before {
  content: "\e671";
}

.icon-cycle:before {
  content: "\e672";
}

.icon-database:before {
  content: "\e673";
}

.icon-dial-pad:before {
  content: "\e674";
}

.icon-direction:before {
  content: "\e675";
}

.icon-document-landscape:before {
  content: "\e676";
}

.icon-document:before {
  content: "\e677";
}

.icon-documents:before {
  content: "\e678";
}

.icon-dot-single:before {
  content: "\e679";
}

.icon-dots-three-horizontal:before {
  content: "\e67a";
}

.icon-dots-three-vertical:before {
  content: "\e67b";
}

.icon-dots-two-horizontal:before {
  content: "\e67c";
}

.icon-dots-two-vertical:before {
  content: "\e67d";
}

.icon-download:before {
  content: "\e67e";
}

.icon-drink:before {
  content: "\e67f";
}

.icon-drive:before {
  content: "\e680";
}

.icon-drop:before {
  content: "\e681";
}

.icon-edit:before {
  content: "\e682";
}

.icon-email:before {
  content: "\e683";
}

.icon-emoji-flirt:before {
  content: "\e684";
}

.icon-emoji-happy:before {
  content: "\e685";
}

.icon-emoji-neutral:before {
  content: "\e686";
}

.icon-emoji-sad:before {
  content: "\e687";
}

.icon-erase:before {
  content: "\e688";
}

.icon-eraser:before {
  content: "\e689";
}

.icon-export:before {
  content: "\e68a";
}

.icon-eye-with-line:before {
  content: "\e68b";
}

.icon-eye:before {
  content: "\e68c";
}

.icon-feather:before {
  content: "\e68d";
}

.icon-fingerprint:before {
  content: "\e68e";
}

.icon-flag:before {
  content: "\e68f";
}

.icon-flash:before {
  content: "\e690";
}

.icon-flashlight:before {
  content: "\e691";
}

.icon-flat-brush:before {
  content: "\e692";
}

.icon-flow-branch:before {
  content: "\e693";
}

.icon-flow-cascade:before {
  content: "\e694";
}

.icon-flow-line:before {
  content: "\e695";
}

.icon-flow-parallel:before {
  content: "\e696";
}

.icon-flow-tree:before {
  content: "\e697";
}

.icon-flower:before {
  content: "\e698";
}

.icon-folder-images:before {
  content: "\e699";
}

.icon-folder-music:before {
  content: "\e69a";
}

.icon-folder-video:before {
  content: "\e69b";
}

.icon-folder:before {
  content: "\e69c";
}

.icon-forward:before {
  content: "\e69d";
}

.icon-funnel:before {
  content: "\e69e";
}

.icon-game-controller:before {
  content: "\e69f";
}

.icon-gauge:before {
  content: "\e6a0";
}

.icon-globe:before {
  content: "\e6a1";
}

.icon-graduation-cap:before {
  content: "\e6a2";
}

.icon-grid:before {
  content: "\e6a3";
}

.icon-hair-cross:before {
  content: "\e6a4";
}

.icon-hand:before {
  content: "\e6a5";
}

.icon-heart-outlined:before {
  content: "\e6a6";
}

.icon-heart:before {
  content: "\e6a7";
}

.icon-help-with-circle:before {
  content: "\e6a8";
}

.icon-help:before {
  content: "\e6a9";
}

.icon-home:before {
  content: "\e6aa";
}

.icon-hour-glass:before {
  content: "\e6ab";
}

.icon-image-inverted:before {
  content: "\e6ac";
}

.icon-image:before {
  content: "\e6ad";
}

.icon-images:before {
  content: "\e6ae";
}

.icon-inbox:before {
  content: "\e6af";
}

.icon-infinity:before {
  content: "\e6b0";
}

.icon-info-with-circle:before {
  content: "\e6b1";
}

.icon-info:before {
  content: "\e6b2";
}

.icon-install:before {
  content: "\e6b3";
}

.icon-key:before {
  content: "\e6b4";
}

.icon-keyboard:before {
  content: "\e6b5";
}

.icon-lab-flask:before {
  content: "\e6b6";
}

.icon-landline:before {
  content: "\e6b7";
}

.icon-language:before {
  content: "\e6b8";
}

.icon-laptop:before {
  content: "\e6b9";
}

.icon-layers:before {
  content: "\e6ba";
}

.icon-leaf:before {
  content: "\e6bb";
}

.icon-level-down:before {
  content: "\e6bc";
}

.icon-level-up:before {
  content: "\e6bd";
}

.icon-lifebuoy:before {
  content: "\e6be";
}

.icon-light-bulb:before {
  content: "\e6bf";
}

.icon-light-down:before {
  content: "\e6c0";
}

.icon-light-up:before {
  content: "\e6c1";
}

.icon-line-graph:before {
  content: "\e6c2";
}

.icon-link:before {
  content: "\e6c3";
}

.icon-list:before {
  content: "\e6c4";
}

.icon-location-pin:before {
  content: "\e6c5";
}

.icon-location:before {
  content: "\e6c6";
}

.icon-lock-open:before {
  content: "\e6c7";
}

.icon-lock:before {
  content: "\e6c8";
}

.icon-log-out:before {
  content: "\e6c9";
}

.icon-login:before {
  content: "\e6ca";
}

.icon-loop:before {
  content: "\e6cb";
}

.icon-magnet:before {
  content: "\e6cc";
}

.icon-magnifying-glass:before {
  content: "\e6cd";
}

.icon-mail:before {
  content: "\e6ce";
}

.icon-man:before {
  content: "\e6cf";
}

.icon-map:before {
  content: "\e6d0";
}

.icon-mask:before {
  content: "\e6d1";
}

.icon-medal:before {
  content: "\e6d2";
}

.icon-megaphone:before {
  content: "\e6d3";
}

.icon-menu:before {
  content: "\e6d4";
}

.icon-merge:before {
  content: "\e6d5";
}

.icon-message:before {
  content: "\e6d6";
}

.icon-mic:before {
  content: "\e6d7";
}

.icon-minus:before {
  content: "\e6d8";
}

.icon-mobile:before {
  content: "\e6d9";
}

.icon-modern-mic:before {
  content: "\e6da";
}

.icon-moon:before {
  content: "\e6db";
}

.icon-mouse-pointer:before {
  content: "\e6dc";
}

.icon-mouse:before {
  content: "\e6dd";
}

.icon-music:before {
  content: "\e6de";
}

.icon-network:before {
  content: "\e6df";
}

.icon-new-message:before {
  content: "\e6e0";
}

.icon-new:before {
  content: "\e6e1";
}

.icon-news:before {
  content: "\e6e2";
}

.icon-newsletter:before {
  content: "\e6e3";
}

.icon-note:before {
  content: "\e6e4";
}

.icon-notification:before {
  content: "\e6e5";
}

.icon-notifications-off:before {
  content: "\e6e6";
}

.icon-old-mobile:before {
  content: "\e6e7";
}

.icon-old-phone:before {
  content: "\e6e8";
}

.icon-open-book:before {
  content: "\e6e9";
}

.icon-palette:before {
  content: "\e6ea";
}

.icon-paper-plane:before {
  content: "\e6eb";
}

.icon-pencil:before {
  content: "\e6ec";
}

.icon-phone:before {
  content: "\e6ed";
}

.icon-pie-chart:before {
  content: "\e6ee";
}

.icon-pin:before {
  content: "\e6ef";
}

.icon-plus:before {
  content: "\e6f0";
}

.icon-popup:before {
  content: "\e6f1";
}

.icon-power-plug:before {
  content: "\e6f2";
}

.icon-price-ribbon:before {
  content: "\e6f3";
}

.icon-price-tag:before {
  content: "\e6f4";
}

.icon-print:before {
  content: "\e6f5";
}

.icon-progress-empty:before {
  content: "\e6f6";
}

.icon-progress-full:before {
  content: "\e6f7";
}

.icon-progress-one:before {
  content: "\e6f8";
}

.icon-progress-two:before {
  content: "\e6f9";
}

.icon-publish:before {
  content: "\e6fa";
}

.icon-quote:before {
  content: "\e6fb";
}

.icon-radio:before {
  content: "\e6fc";
}

.icon-remove-user:before {
  content: "\e6fd";
}

.icon-reply-all:before {
  content: "\e6fe";
}

.icon-reply:before {
  content: "\e6ff";
}

.icon-resize-100:before {
  content: "\e700";
}

.icon-resize-full-screen:before {
  content: "\e701";
}

.icon-retweet:before {
  content: "\e702";
}

.icon-rocket:before {
  content: "\e703";
}

.icon-round-brush:before {
  content: "\e704";
}

.icon-rss:before {
  content: "\e705";
}

.icon-ruler:before {
  content: "\e706";
}

.icon-save:before {
  content: "\e707";
}

.icon-scissors:before {
  content: "\e708";
}

.icon-select-arrows:before {
  content: "\e709";
}

.icon-share-alternative:before {
  content: "\e70a";
}

.icon-share:before {
  content: "\e70b";
}

.icon-shareable:before {
  content: "\e70c";
}

.icon-shield:before {
  content: "\e70d";
}

.icon-shop:before {
  content: "\e70e";
}

.icon-shopping-bag:before {
  content: "\e70f";
}

.icon-shopping-basket:before {
  content: "\e710";
}

.icon-shopping-cart:before {
  content: "\e711";
}

.icon-shuffle:before {
  content: "\e712";
}

.icon-signal:before {
  content: "\e713";
}

.icon-sound-mix:before {
  content: "\e714";
}

.icon-sound-mute:before {
  content: "\e715";
}

.icon-sound:before {
  content: "\e716";
}

.icon-sports-club:before {
  content: "\e717";
}

.icon-spreadsheet:before {
  content: "\e718";
}

.icon-squared-cross:before {
  content: "\e719";
}

.icon-squared-minus:before {
  content: "\e71a";
}

.icon-squared-plus:before {
  content: "\e71b";
}

.icon-star-outlined:before {
  content: "\e71c";
}

.icon-star:before {
  content: "\e71d";
}

.icon-stopwatch:before {
  content: "\e71e";
}

.icon-suitcase:before {
  content: "\e71f";
}

.icon-swap:before {
  content: "\e720";
}

.icon-sweden:before {
  content: "\e721";
}

.icon-switch:before {
  content: "\e722";
}

.icon-tablet-mobile-combo:before {
  content: "\e723";
}

.icon-tablet:before {
  content: "\e724";
}

.icon-tag:before {
  content: "\e725";
}

.icon-text-document-inverted:before {
  content: "\e726";
}

.icon-text-document:before {
  content: "\e727";
}

.icon-text:before {
  content: "\e728";
}

.icon-thermometer:before {
  content: "\e729";
}

.icon-thumbs-down:before {
  content: "\e72a";
}

.icon-thumbs-up:before {
  content: "\e72b";
}

.icon-thunder-cloud:before {
  content: "\e72c";
}

.icon-ticket:before {
  content: "\e72d";
}

.icon-time-slot:before {
  content: "\e72e";
}

.icon-tools:before {
  content: "\e72f";
}

.icon-traffic-cone:before {
  content: "\e730";
}

.icon-trash:before {
  content: "\e731";
}

.icon-tree:before {
  content: "\e732";
}

.icon-triangle-down:before {
  content: "\e733";
}

.icon-triangle-left:before {
  content: "\e734";
}

.icon-triangle-right:before {
  content: "\e735";
}

.icon-triangle-up:before {
  content: "\e736";
}

.icon-trophy:before {
  content: "\e737";
}

.icon-tv:before {
  content: "\e738";
}

.icon-typing:before {
  content: "\e739";
}

.icon-uninstall:before {
  content: "\e73a";
}

.icon-unread:before {
  content: "\e73b";
}

.icon-untag:before {
  content: "\e73c";
}

.icon-upload-to-cloud:before {
  content: "\e73d";
}

.icon-upload:before {
  content: "\e73e";
}

.icon-user:before {
  content: "\e73f";
}

.icon-users:before {
  content: "\e740";
}

.icon-v-card:before {
  content: "\e741";
}

.icon-video-camera:before {
  content: "\e742";
}

.icon-video:before {
  content: "\e743";
}

.icon-vinyl:before {
  content: "\e744";
}

.icon-voicemail:before {
  content: "\e745";
}

.icon-wallet:before {
  content: "\e746";
}

.icon-warning:before {
  content: "\e747";
}

.icon-water:before {
  content: "\e748";
}

.icon-px-with-circle:before {
  content: "\e749";
}

.icon-px:before {
  content: "\e74a";
}

.icon-app-store:before {
  content: "\e74b";
}

.icon-baidu:before {
  content: "\e74c";
}

.icon-basecamp:before {
  content: "\e74d";
}

.icon-behance:before {
  content: "\e74e";
}

.icon-creative-cloud:before {
  content: "\e74f";
}

.icon-dribbble-with-circle:before {
  content: "\e750";
}

.icon-dribbble:before {
  content: "\e751";
}

.icon-dropbox:before {
  content: "\e752";
}

.icon-evernote:before {
  content: "\e753";
}

.icon-facebook-with-circle:before {
  content: "\e754";
}

.icon-facebook:before {
  content: "\e755";
}

.icon-flattr:before {
  content: "\e756";
}

.icon-flickr-with-circle:before {
  content: "\e757";
}

.icon-flickr:before {
  content: "\e758";
}

.icon-foursquare:before {
  content: "\e759";
}

.icon-github-with-circle:before {
  content: "\e75a";
}

.icon-github:before {
  content: "\e75b";
}

.icon-google-drive:before {
  content: "\e75c";
}

.icon-google-hangouts:before {
  content: "\e75d";
}

.icon-google-play:before {
  content: "\e75e";
}

.icon-google-with-circle:before {
  content: "\e75f";
}

.icon-google:before {
  content: "\e760";
}

.icon-grooveshark:before {
  content: "\e761";
}

.icon-houzz:before {
  content: "\e762";
}

.icon-icloud:before {
  content: "\e763";
}

.icon-instagram-with-circle:before {
  content: "\e764";
}

.icon-instagram:before {
  content: "\e765";
}

.icon-lastfm-with-circle:before {
  content: "\e766";
}

.icon-lastfm:before {
  content: "\e767";
}

.icon-linkedin-with-circle:before {
  content: "\e768";
}

.icon-linkedin:before {
  content: "\e769";
}

.icon-mail-with-circle:before {
  content: "\e76a";
}

.icon-medium-with-circle:before {
  content: "\e76b";
}

.icon-medium:before {
  content: "\e76c";
}

.icon-mixi:before {
  content: "\e76d";
}

.icon-onedrive:before {
  content: "\e76e";
}

.icon-paypal:before {
  content: "\e76f";
}

.icon-picasa:before {
  content: "\e770";
}

.icon-pinterest-with-circle:before {
  content: "\e771";
}

.icon-pinterest:before {
  content: "\e772";
}

.icon-qq-with-circle:before {
  content: "\e773";
}

.icon-qq:before {
  content: "\e774";
}

.icon-raft-with-circle:before {
  content: "\e775";
}

.icon-raft:before {
  content: "\e776";
}

.icon-rainbow:before {
  content: "\e777";
}

.icon-rdio-with-circle:before {
  content: "\e778";
}

.icon-rdio:before {
  content: "\e779";
}

.icon-renren:before {
  content: "\e77a";
}

.icon-scribd:before {
  content: "\e77b";
}

.icon-sina-weibo:before {
  content: "\e77c";
}

.icon-skype-with-circle:before {
  content: "\e77d";
}

.icon-skype:before {
  content: "\e77e";
}

.icon-slideshare:before {
  content: "\e77f";
}

.icon-smashing:before {
  content: "\e780";
}

.icon-soundcloud:before {
  content: "\e781";
}

.icon-spotify-with-circle:before {
  content: "\e782";
}

.icon-spotify:before {
  content: "\e783";
}

.icon-stumbleupon-with-circle:before {
  content: "\e784";
}

.icon-stumbleupon:before {
  content: "\e785";
}

.icon-swarm:before {
  content: "\e786";
}

.icon-tripadvisor:before {
  content: "\e787";
}

.icon-tumblr-with-circle:before {
  content: "\e788";
}

.icon-tumblr:before {
  content: "\e789";
}

.icon-twitter-with-circle:before {
  content: "\e78a";
}

.icon-twitter:before {
  content: "\e78b";
}

.icon-vimeo-with-circle:before {
  content: "\e78c";
}

.icon-vimeo:before {
  content: "\e78d";
}

.icon-vine-with-circle:before {
  content: "\e78e";
}

.icon-vine:before {
  content: "\e78f";
}

.icon-vk-alternitive:before {
  content: "\e790";
}

.icon-vk-with-circle:before {
  content: "\e791";
}

.icon-vk:before {
  content: "\e792";
}

.icon-windows-store:before {
  content: "\e793";
}

.icon-xing-with-circle:before {
  content: "\e794";
}

.icon-xing:before {
  content: "\e795";
}

.icon-yelp:before {
  content: "\e796";
}

.icon-youko-with-circle:before {
  content: "\e797";
}

.icon-youko:before {
  content: "\e798";
}

.icon-youtube-with-circle:before {
  content: "\e799";
}

.icon-youtube:before {
  content: "\e79a";
}

[class*="icon-"].small {
  font-size: 1.6rem;
}

[class*="icon-"].medium {
  font-size: 2.4rem;
}

[class*="icon-"].large {
  font-size: 3.2rem;
}

[class*="icon-"].xlarge {
  font-size: 4.8rem;
}

.icon-boxed,
.icon-circled {
  width: 6.6rem;
  height: 6.6rem;
  margin-bottom: 0.4rem;
  line-height: 6.6rem;
  text-align: center;
  border: 1px solid #eee;
}

.icon-boxed.small,
.icon-circled.small {
  width: 5.8rem;
  height: 5.8rem;
  line-height: 5.6rem;
}

.icon-boxed.medium,
.icon-circled.medium {
  width: 6.6rem;
  height: 6.6rem;
  line-height: 6.4rem;
}

.icon-boxed.large,
.icon-circled.large {
  width: 7.4rem;
  height: 7.4rem;
  line-height: 7.2rem;
}

.icon-boxed.xlarge,
.icon-circled.xlarge {
  width: 9rem;
  height: 9rem;
  line-height: 8.8rem;
}

.icon-boxed.rounded {
  border-radius: 0.3rem;
}

.icon-circled {
  border-radius: 10rem;
}

[class*="border-"].padded,
[class*="bkg-"].padded {
  padding: 1rem 2rem;
}

[class*="border-"] {
  border: 1px solid #eee;
}

[class*="border-hover-"]:hover,
[class*="border-"]:not([class*="bkg-"]),
[class*="border-"][class*="bkg-hover-"] {
  background: 0 0;
  background: rgba(0, 0, 0, 0);
}

[class*="border-"].solid {
  border-style: solid;
  border-width: 1px;
}

[class*="border-"].dashed {
  border-style: dashed;
  border-width: 1px;
}

[class*="border-"].dotted {
  border-style: dotted;
  border-width: 1px;
}

[class*="border-"].thick,
[class*="border-"].thick * {
  border-width: 0.2rem;
}

.border-turquoise {
  border-color: #00a186;
}

.border-hover-turquoise:hover {
  border-color: #008b73;
}

.border-turquoise-light {
  border-color: #00bd9c;
}

.border-hover-turquoise-light:hover {
  border-color: #00a186;
}

.border-green {
  border-color: #1bb05d;
  color: #1bb05d;
}

.border-hover-green:hover {
  border-color: #179851;
}

.border-green-light {
  border-color: #1ecd6d;
}

.border-hover-green-light:hover {
  border-color: #1bb05d;
}

.border-blue {
  border-color: #227fbb;
}

.border-hover-blue:hover {
  border-color: #1d6ea3;
}

.border-blue-light {
  border-color: #2c96dd;
}

.border-hover-blue-light:hover {
  border-color: #227fbb;
}

.border-navy {
  border-color: #2c3e51;
}

.border-hover-navy:hover {
  border-color: #1f2d3b;
}

.border-navy-light {
  border-color: #33495f;
}

.border-hover-navy-light:hover {
  border-color: #2c3e51;
}

.border-purple {
  border-color: #8f3faf;
}

.border-hover-purple:hover {
  border-color: #7b3697;
}

.border-purple-light {
  border-color: #b078c6;
}

.border-hover-purple-light:hover {
  border-color: #8f3faf;
}

.border-yellow {
  border-color: #f3b100;
}

.border-hover-yellow:hover {
  border-color: #e2a50b;
}

.border-yellow-light {
  border-color: #ffbe12;
}

.border-hover-yellow-light:hover {
  border-color: #f3b100;
}

.border-orange {
  border-color: #e87f04;
}

.border-hover-orange:hover {
  border-color: #df6f0b;
}

.border-orange-light {
  border-color: #f59d00;
}

.border-hover-orange-light:hover {
  border-color: #e87f04;
}

.border-red {
  border-color: #c23824;
}

.border-hover-red:hover {
  border-color: #a9311f;
}

.border-red-light {
  border-color: #ea4b35;
}

.border-hover-red-light:hover {
  border-color: #c23824;
}

.border-hover-white:hover,
.border-white {
  border-color: #fff;
}

.border-grey {
  border-color: #666;
}

.border-hover-grey:hover {
  border-color: #555;
}

.border-grey-light {
  border-color: #999;
}

.border-hover-grey-light:hover {
  border-color: #888;
}

.border-grey-ultralight {
  border-color: #f4f4f4;
}

.border-hover-grey-ultralight:hover {
  border-color: #f1f1f1;
}

.border-charcoal {
  border-color: #333;
}

.border-hover-charcoal:hover {
  border-color: #222;
}

.border-charcoal-light {
  border-color: #555;
}

.border-hover-charcoal-light:hover {
  border-color: #444;
}

.border-black,
.border-hover-black:hover {
  border-color: #000;
}

.border-base {
  border-color: #eee;
}

.border-hover-base:hover {
  border-color: #d0d0d0;
}

.border-behance,
.border-hover-behance:hover {
  border-color: #1769ff;
  color: #1769ff;
}

.border-dribbble,
.border-hover-dribbble:hover {
  border-color: #ea4c89;
  color: #ea4c89;
}

.border-facebook,
.border-hover-facebook:hover {
  border-color: #3b5998;
  color: #3b5998;
}

.border-flickr,
.border-hover-flickr:hover {
  border-color: #0063dc;
  color: #0063dc;
}

.border-github,
.border-hover-github:hover {
  border-color: #333;
  color: #333;
}

.border-google,
.border-hover-google:hover {
  border-color: #d34836;
  color: #d34836;
}

.border-hover-instagram:hover,
.border-instagram {
  border-color: #517fa4;
  color: #517fa4;
}

.border-hover-linkedin:hover,
.border-linkedin {
  border-color: #007bb6;
  color: #007bb6;
}

.border-hover-pinterest:hover,
.border-pinterest {
  border-color: #cb2027;
  color: #cb2027;
}

.border-hover-skype:hover,
.border-skype {
  border-color: #12a5f4;
  color: #12a5f4;
}

.border-hover-soundcloud:hover,
.border-soundcloud {
  border-color: #f80;
  color: #f80;
}

.border-hover-spotify:hover,
.border-spotify {
  border-color: #7ab800;
  color: #7ab800;
}

.border-hover-tumblr:hover,
.border-tumblr {
  border-color: #35465c;
  color: #35465c;
}

.border-hover-twitter:hover,
.border-twitter {
  border-color: #00aced;
  color: #00aced;
}

.border-hover-vimeo:hover,
.border-vimeo {
  border-color: #1ab7ea;
  color: #1ab7ea;
}

.border-hover-youtube:hover,
.border-youtube {
  border-color: #b00;
  color: #b00;
}

[class*="bkg-hover-"]:hover *,
[class*="bkg-hover-"]:visited * {
  color: inherit;
}

.bkg-turquoise {
  background: #00a186;
  border-color: #00a186;
}

.bkg-hover-turquoise:hover {
  background: #008b73;
  border-color: #008b73;
}

.bkg-turquoise-light {
  background: #00bd9c;
  border-color: #00bd9c;
}

.bkg-hover-turquoise-light:hover {
  background: #00a186;
  border-color: #00a186;
}

.bkg-green {
  background: #1bb05d;
  border-color: #1bb05d;
}

.bkg-hover-green:hover {
  background: #179851;
  border-color: #179851;
}

.bkg-green-light {
  background: #1ecd6d;
  border-color: #1ecd6d;
}

.bkg-hover-green-light:hover {
  background: #1bb05d;
  border-color: #1bb05d;
}

.bkg-blue {
  background: #227fbb;
  border-color: #227fbb;
}

.bkg-hover-blue:hover {
  background: #1d6ea3;
  border-color: #1d6ea3;
}

.bkg-blue-light {
  background: #2c96dd;
  border-color: #2c96dd;
}

.bkg-hover-blue-light:hover {
  background: #227fbb;
  border-color: #227fbb;
}

.bkg-navy {
  background: #2c3e51;
  border-color: #2c3e51;
}

.bkg-hover-navy:hover {
  background: #1f2d3b;
  border-color: #1f2d3b;
}

.bkg-navy-light {
  background: #33495f;
  border-color: #33495f;
}

.bkg-hover-navy-light:hover {
  background: #2c3e51;
  border-color: #2c3e51;
}

.bkg-purple {
  background: #8f3faf;
  border-color: #8f3faf;
}

.bkg-hover-purple:hover {
  background: #7b3697;
  border-color: #7b3697;
}

.bkg-purple-light {
  background: #b078c6;
  border-color: #b078c6;
}

.bkg-hover-purple-light:hover {
  background: #8f3faf;
  border-color: #8f3faf;
}

.bkg-yellow {
  background: #f3b100;
  border-color: #f3b100;
}

.bkg-hover-yellow:hover {
  background: #e2a50b;
  border-color: #e2a50b;
}

.bkg-yellow-light {
  background: #ffbe12;
  border-color: #ffbe12;
}

.bkg-hover-yellow-light:hover {
  background: #f3b100;
  border-color: #f3b100;
}

.bkg-orange {
  background: #e87f04;
  border-color: #e87f04;
}

.bkg-hover-orange:hover {
  background: #df6f0b;
  border-color: #df6f0b;
}

.bkg-orange-light {
  background: #f59d00;
  border-color: #f59d00;
}

.bkg-hover-orange-light:hover {
  background: #e87f04;
  border-color: #e87f04;
}

.bkg-red {
  background: #c23824;
  border-color: #c23824;
}

.bkg-hover-red:hover {
  background: #a9311f;
  border-color: #a9311f;
}

.bkg-red-light {
  background: #ea4b35;
  border-color: #ea4b35;
}

.bkg-hover-red-light:hover {
  background: #c23824;
  border-color: #c23824;
}

.bkg-black,
.bkg-hover-black:hover {
  background: #000;
}

.bkg-hover-white:hover,
.bkg-white {
  background: #fff;
  border-color: #fff;
}

.bkg-grey {
  background-color: #666;
  border-color: #666;
}

.bkg-hover-grey:hover {
  background-color: #555;
  border-color: #555;
}

.bkg-grey-light {
  background-color: #999;
  border-color: #999;
}

.bkg-hover-grey-light:hover {
  background-color: #888;
  border-color: #888;
}

.bkg-grey-ultralight {
  background-color: #f4f4f4;
  border-color: #f4f4f4;
}

.bkg-hover-grey-ultralight:hover {
  background-color: #f1f1f1;
  border-color: #f1f1f1;
}

.bkg-charcoal {
  background-color: #333;
  border-color: #333;
}

.bkg-hover-charcoal:hover {
  background-color: #222;
  border-color: #222;
}

.bkg-charcoal-light {
  background-color: #555;
  border-color: #555;
}

.bkg-hover-charcoal-light:hover {
  background-color: #444;
  border-color: #444;
}

.bkg-black,
.bkg-hover-black:hover {
  background-color: #000;
  border-color: #000;
}

.bkg-base {
  background: #eee;
  border-color: #666;
  border-color: #eee;
}

.bkg-hover-base:hover {
  background: #d0d0d0;
  border-color: #666;
  border-color: #eee;
}

.bkg-behance,
.bkg-hover-behance:hover {
  background-color: #1769ff;
  border-color: #1769ff;
}

.bkg-dribbble,
.bkg-hover-dribbble:hover {
  background-color: #ea4c89;
  border-color: #ea4c89;
}

.bkg-facebook,
.bkg-hover-facebook:hover {
  background-color: #3b5998;
  border-color: #3b5998;
}

.bkg-flickr,
.bkg-hover-flickr:hover {
  background-color: #0063dc;
  border-color: #0063dc;
}

.bkg-github,
.bkg-hover-github:hover {
  background-color: #333;
  border-color: #333;
}

.bkg-google,
.bkg-hover-google:hover {
  background-color: #d34836;
  border-color: #d34836;
}

.bkg-hover-instagram:hover,
.bkg-instagram {
  background-color: #517fa4;
  border-color: #517fa4;
}

.bkg-hover-linkedin:hover,
.bkg-linkedin {
  background-color: #007bb6;
  border-color: #007bb6;
}

.bkg-hover-pinterest:hover,
.bkg-pinterest {
  background-color: #cb2027;
  border-color: #cb2027;
}

.bkg-hover-skype:hover,
.bkg-skype {
  background-color: #12a5f4;
  border-color: #12a5f4;
}

.bkg-hover-soundcloud:hover,
.bkg-soundcloud {
  background-color: #f80;
  border-color: #f80;
}

.bkg-hover-spotify:hover,
.bkg-spotify {
  background-color: #7ab800;
  border-color: #7ab800;
}

.bkg-hover-tumblr:hover,
.bkg-tumblr {
  background-color: #35465c;
  border-color: #35465c;
}

.bkg-hover-twitter:hover,
.bkg-twitter {
  background-color: #00aced;
  border-color: #00aced;
}

.bkg-hover-vimeo:hover,
.bkg-vimeo {
  background-color: #1ab7ea;
  border-color: #1ab7ea;
}

.bkg-hover-youtube:hover,
.bkg-youtube {
  background-color: #b00;
  border-color: #b00;
}

[class*="color-"] * {
  color: inherit !important;
}

.color-turquoise {
  color: #00a186;
}

.color-hover-turquoise:hover {
  color: #008b73;
}

.color-turquoise-light {
  color: #00bd9c;
}

.color-hover-turquoise-light:hover {
  color: #00a186;
}

.color-green {
  color: #1bb05d;
}

.color-hover-green:hover {
  color: #179851;
}

.color-green-light {
  color: #1ecd6d;
}

.color-hover-green-light:hover {
  color: #1bb05d;
}

.color-blue {
  color: #227fbb;
}

.color-hover-blue:hover {
  color: #1d6ea3;
}

.color-blue-light {
  color: #2c96dd;
}

.color-hover-blue-light:hover {
  color: #227fbb;
}

.color-navy {
  color: #2c3e51;
}

.color-hover-navy:hover {
  color: #1f2d3b;
}

.color-navy-light {
  color: #33495f;
}

.color-hover-navy-light:hover {
  color: #2c3e51;
}

.color-purple {
  color: #8f3faf;
}

.color-hover-purple:hover {
  color: #7b3697;
}

.color-purple-light {
  color: #b078c6;
}

.color-hover-purple-light:hover {
  color: #8f3faf;
}

.color-yellow {
  color: #f3b100;
}

.color-hover-yellow:hover {
  color: #e2a50b;
}

.color-yellow-light {
  color: #ffbe12;
}

.color-hover-yellow-light:hover {
  color: #f3b100;
}

.color-orange {
  color: #e87f04;
}

.color-hover-orange:hover {
  color: #df6f0b;
}

.color-orange-light {
  color: #f59d00;
}

.color-hover-orange-light:hover {
  color: #e87f04;
}

.color-red {
  color: #c23824;
}

.color-hover-red:hover {
  color: #a9311f;
}

.color-red-light {
  color: #ea4b35;
}

.color-hover-red-light:hover {
  color: #c23824;
}

.color-hover-white:hover,
.color-white {
  color: #fff;
}

.color-grey {
  color: #666;
}

.color-hover-grey:hover {
  color: #555;
}

.color-grey-light {
  color: #999;
}

.color-hover-grey-light:hover {
  color: #888;
}

.color-grey-ultralight {
  color: #f4f4f4;
}

.color-hover-grey-ultralight:hover {
  color: #f1f1f1;
}

.color-charcoal {
  color: #333;
}

.color-hover-charcoal:hover {
  color: #222;
}

.color-charcoal-light {
  color: #555;
}

.color-hover-charcoal-light:hover {
  color: #444;
}

.color-black,
.color-hover-black:hover {
  color: #000;
}

.color-base,
.color-hover-base:hover {
  color: #666;
}

.color-behance,
.color-hover-behance:hover {
  color: #1769ff;
}

.color-dribbble,
.color-hover-dribbble:hover {
  color: #ea4c89;
}

.color-facebook,
.color-hover-facebook:hover {
  color: #3b5998;
}

.color-flickr,
.color-hover-flickr:hover {
  color: #0063dc;
}

.color-github,
.color-hover-github:hover {
  color: #333;
}

.color-google,
.color-hover-google:hover {
  color: #d34836;
}

.color-hover-instagram:hover,
.color-instagram {
  color: #517fa4;
}

.color-hover-linkedin:hover,
.color-linkedin {
  color: #007bb6;
}

.color-hover-pinterest:hover,
.color-pinterest {
  color: #cb2027;
}

.color-hover-skype:hover,
.color-skype {
  color: #12a5f4;
}

.color-hover-soundcloud:hover,
.color-soundcloud {
  color: #f80;
}

.color-hover-spotify:hover,
.color-spotify {
  color: #7ab800;
}

.color-hover-tumblr:hover,
.color-tumblr {
  color: #35465c;
}

.color-hover-twitter:hover,
.color-twitter {
  color: #00aced;
}

.color-hover-vimeo:hover,
.color-vimeo {
  color: #1ab7ea;
}

.color-hover-youtube:hover,
.color-youtube {
  color: #b00;
}

[class*="color-"]::-webkit-input-placeholder,
[class*="border-"]::-webkit-input-placeholder,
[class*="bkg-"]::-webkit-input-placeholder {
  color: inherit;
}

[class*="color-"]::-moz-placeholder,
[class*="border-"]::-moz-placeholder,
[class*="bkg-"]::-moz-placeholder {
  color: inherit;
}

[class*="color-"]:-ms-input-placeholder,
[class*="border-"]:-ms-input-placeholder,
[class*="bkg-"]:-ms-input-placeholder {
  color: inherit;
}

.highlight {
  background: #eee;
  border-color: #eee;
}

.info {
  background: #d9edf7;
  color: #31708f;
  border-color: #d9edf7;
}

.success {
  background: #dff0d8;
  color: #3c763d;
  border-color: #dff0d8;
}

.alert {
  background: #fcf8e3;
  color: #8a6d3b;
  border-color: #fcf8e3;
}

.warning {
  background: #f2dede;
  color: #a9444a;
  border-color: #f2dede;
}

a,
button,
input,
textarea {
  -webkit-transition-property: background, border-color, color, opacity;
  transition-property: background, border-color, color, opacity;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

.bar,
.box,
.overlay-info,
.thumbnail img {
  -webkit-transition-property: padding, background, color, opacity, box-shadow, -webkit-transform;
  transition-property: padding, background, color, opacity, box-shadow, transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

.no-transition,
.no-transition-reset {
  -webkit-transition-duration: 0s !important;
  transition-duration: 0s !important;
}

@media only screen and (max-width: 1140px) {
  .row,
  body.boxed .wrapper-inner {
    max-width: 96rem;
  }
  .navigation li:last-child .sub-menu {
    right: 0;
  }
  .content-grid-5 .grid-item,
  .content-grid-6 .grid-item {
    width: 25%;
  }
  .feature-column-group .width-3 {
    width: 50%;
  }
}

@media only screen and (max-width: 1023px) {
  .navigation > ul > li:nth-last-child(2) > .sub-menu {
    right: 0;
  }
  .navigation > ul > li:nth-last-child(2) > .sub-menu ul {
    right: 18rem;
    left: auto;
  }
  .content-grid-5 .grid-item,
  .content-grid-6 .grid-item {
    width: 33.33333%;
  }
}

/* @media only screen and (min-width: 960px) {
  .pricing-table .pricing-table-column:not(: first-child):not(.callout), .table>tbody>tr>td:first-child {
    border-left: none;
  }
} */

@media only screen and (max-width: 960px) {
  .row,
  body.boxed .wrapper-inner {
    max-width: 76rem;
  }
  header .primary-navigation {
    display: none !important;
  }
  .nav-bar .logo,
  .nav-bar .logo.logo-center {
    margin: 0;
    float: left;
  }
  .aux-navigation {
    display: table !important;
    visibility: visible;
  }
  .feature-column-group .feature-column {
    padding: 4rem;
  }
  .pricing-table.columns-2 .pricing-table-column,
  .pricing-table.columns-3 .pricing-table-column,
  .pricing-table.columns-4 .pricing-table-column,
  .pricing-table.columns-5 .pricing-table-column {
    width: 50%;
  }
  .pricing-table[class*="border-"].columns-3 .pricing-table-column:last-child,
  .pricing-table[class*="border-"].columns-4 .pricing-table-column:nth-last-child(2),
  .pricing-table[class*="border-"].columns-5 .pricing-table-column:last-child,
  .pricing-table[class*="border-"].columns-5 .pricing-table-column:nth-last-child(3) {
    border-left-width: 1px;
  }
  .pricing-table.rounded.columns-3 .pricing-table-column:last-child,
  .pricing-table.rounded.columns-5 .pricing-table-column:last-child {
    border-radius: 0.3rem;
  }
  .pricing-table.rounded.columns-4 .pricing-table-column:nth-last-child(2),
  .pricing-table.rounded.columns-5 .pricing-table-column:nth-last-child(3) {
    border-radius: 0.3rem 0 0 0.3rem;
  }
  .table:not(.non-responsive) {
    width: 100%;
    display: block;
    position: relative;
  }
  .table:not(.non-responsive) > thead,
  .table:not(.non-responsive) > thead > tr {
    display: block;
    float: left;
  }
  .table:not(.non-responsive) > thead > tr > th {
    display: block;
  }
  .table:not(.non-responsive) > tbody {
    display: block;
    position: relative;
    overflow-x: auto;
    white-space: nowrap;
  }
  .table:not(.non-responsive) > tbody > tr {
    display: inline-block;
    vertical-align: top;
    margin-right: -0.4rem;
  }
  .table:not(.non-responsive) > tbody > tr > td {
    display: block;
  }
  .table:not(.non-responsive) tr > td:last-child {
    border-bottom: none;
  }
  .table:not(.non-responsive) th + th {
    border-left: none;
    border-bottom: 1px solid transparent;
  }
}

@media only screen and (max-width: 768px) {
  .width-1,
  .width-10,
  .width-11,
  .width-12,
  .width-2,
  .width-3,
  .width-4,
  .width-5,
  .width-6,
  .width-7,
  .width-8,
  .width-9,
  [class*="content-grid-"] .grid-item {
    width: 100%;
  }
  .row,
  body.boxed .wrapper-inner {
    max-width: 60rem;
  }
  [class*="offset-"] {
    margin-left: 0;
  }
  .column[class*="push-"],
  .column[class*="pull-"] {
    position: static;
    left: 0;
    right: 0;
  }
  .dropdown .login-form-container .button {
    width: 100%;
    display: block;
    text-align: center;
  }
  .button.no-label-on-mobile span:not([class*="icon-"]),
  .timeline.vertical-indication li:last-child .timeline-title:before,
  button.no-label-on-mobile span:not([class*="icon-"]) {
    display: none;
  }
  .button.no-label-on-mobile [class*="icon-"],
  button.no-label-on-mobile [class*="icon-"] {
    margin: 0;
  }
  blockquote {
    width: 100% !important;
  }
  .lead {
    font-size: 1.8rem;
    line-height: 1.5;
    font-weight: 200;
  }
  .feature-column-group .width-3 {
    width: 100%;
  }
  .timeline.vertical-indication .timeline-description,
  .timeline.vertical-indication .timeline-title {
    padding-left: 7rem;
  }
  .timeline.vertical-indication .timeline-title:before {
    width: 0.1rem;
    content: "";
    background-color: #e7e7e7;
    position: absolute;
    top: 0;
    bottom: 0;
  }
  .timeline.vertical-indication .timeline-title:after {
    left: 1.5rem;
  }
  .timeline.vertical-indication .timeline-description:before,
  .timeline.vertical-indication .timeline-title:before {
    left: 2rem;
  }
  .pricing-table.columns-3 .pricing-table-column.callout {
    top: 0;
  }
  .pricing-table.columns-3 .pricing-table-column {
    margin-bottom: 0.5rem;
  }
  .hide-on-mobile,
  .hide-on-mobile * {
    display: none !important;
    visibility: hidden !important;
  }
  .show-on-mobile,
  .show-on-mobile * {
    display: block !important;
    visibility: visible !important;
  }
  .pull-left-on-mobile {
    float: left !important;
  }
  .pull-right-on-mobile {
    float: right !important;
  }
  .clear-float-on-mobile {
    float: none !important;
  }
  .v-align-bottom,
  .v-align-bottom-on-mobile {
    padding-bottom: 2rem !important;
    vertical-align: bottom !important;
  }
  .v-align-top,
  .v-align-top-on-mobile {
    padding-top: 2rem !important;
    vertical-align: top !important;
  }
  .no-padding-on-mobile {
    padding: 0 !important;
  }
  .no-background-on-mobile {
    background: 0 0 !important;
    border: none !important;
  }
  .thumbnail .v-align-bottom,
  .thumbnail .v-align-bottom-on-mobile,
  .thumbnail .v-align-top,
  .thumbnail .v-align-top-on-mobile {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .background-on-mobile {
    width: 100%;
    padding-top: 3rem;
    padding-bottom: 3rem;
    background: #f4f4f4;
    color: #666;
    text-align: left;
    position: relative;
    z-index: 1;
  }
  .v-align-top-on-mobile .background-on-mobile {
    margin-top: -2rem;
  }
  .v-align-bottom-on-mobile .background-on-mobile {
    margin-bottom: -2rem;
  }
  .left-on-mobile {
    text-align: left !important;
  }
  .center-on-mobile {
    text-align: center !important;
  }
  .right-on-mobile {
    text-align: right !important;
  }
  .row.background-on-mobile {
    padding-left: 0;
    padding-right: 0;
  }
  .background-on-mobile .column > :last-child {
    margin-bottom: 0;
  }
  .row > .background-on-mobile {
    margin-left: 0;
    margin-right: 0;
  }
  .no-transition-on-mobile {
    -webkit-transition-duration: 0s !important;
    transition-duration: 0s !important;
  }
}

@media only screen and (max-width: 600px) {
  .row,
  body.boxed .wrapper-inner {
    max-width: 48rem;
  }
  .pricing-table.columns-2 .pricing-table-column,
  .pricing-table.columns-3 .pricing-table-column,
  .pricing-table.columns-4 .pricing-table-column,
  .pricing-table.columns-5 .pricing-table-column {
    width: 100%;
  }
  .pricing-table.rounded .pricing-table-column {
    border-radius: 0.3rem !important;
  }
  .pricing-table .pricing-table-column.callout {
    top: 0;
  }
  .pricing-table .pricing-table-column {
    margin-bottom: 0.5rem;
  }
  .pricing-table[class*="border-"] .pricing-table-column {
    border-left-width: 1px;
  }
}

@media only screen and (max-width: 480px) {
  .row,
  body.boxed .wrapper-inner {
    max-width: 35rem;
  }
  [class*="content-grid-"] .grid-item {
    width: 100%;
  }
  .side-navigation-inner,
  .side-navigation-wrapper {
    width: 30rem;
  }
  .element-reveal-left {
    -webkit-transform: translate3d(30rem, 0, 0);
    transform: translate3d(30rem, 0, 0);
  }
  .element-reveal-right {
    -webkit-transform: translate3d(-30rem, 0, 0);
    transform: translate3d(-30rem, 0, 0);
  }
  .side-navigation-wrapper.hide {
    right: 30rem;
  }
  .side-navigation-wrapper.enter-right.hide {
    right: -30rem;
  }
  .element-show-left {
    left: 30rem;
  }
  .element-show-right {
    left: -30rem;
  }
  .list-group {
    margin-bottom: 0;
  }
  blockquote {
    width: 100% !important;
  }
  .tab-nav li,
  .tabs.vertical .tab-nav,
  .tabs.vertical .tab-panes,
  .tabs.vertical.right .tab-nav,
  .tabs.vertical.right .tab-panes {
    width: 100%;
    float: none;
  }
  .tab-nav li a {
    width: 100%;
  }
  .tabs.rounded li:first-child a {
    border-radius: 0.3rem 0.3rem 0 0;
  }
  .tabs.rounded .tab-panes {
    border-radius: 0 0 0.3rem 0.3rem;
  }
  .tabs li.active:last-child a {
    border-bottom: 1px solid #eee;
  }
  .tabs.vertical li.active a,
  .tabs.vertical.right li.active a {
    border-left: 1px solid #eee;
  }
  .tabs.vertical .tab-panes,
  .tabs.vertical.right .tab-panes {
    border-top: none;
  }
}

@media only screen and (max-width: 350px) {
  .row,
  body.boxed .wrapper-inner {
    max-width: 100%;
  }
}

.mobile .tm-slider-parallax-container .tm-slider-container,
.tm-slider-container {
  background: 0 0;
  position: relative;
}

.tm-slider-parallax-container .tm-slider-container {
  position: fixed;
}

.tm-slider-container.background-slider {
  position: absolute;
  z-index: 0;
}

.tm-slider-container.full-width-slider,
.tm-slider-container.fullscreen {
  width: 100%;
}

.tm-slider-container.fullscreen {
  width: 100%;
  height: 100%;
  height: 100vh;
}

.tms-overlay,
.tms-slide,
.tms-slides {
  height: 100%;
  width: 100%;
}

.tm-slider-container.tms-carousel {
  overflow: hidden;
}

.tm-slider-container.tms-carousel > ul {
  opacity: 0;
}

.tm-slider-container.tms-carousel > ul > li {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.tm-slider-container.tms-scalable-height,
.tms-content-scalable {
  -webkit-transition-property: height, opacity;
  -moz-transition-property: height, opacity;
  -o-transition-property: height, opacity;
  -ms-transition-property: height, opacity;
  transition-property: height, opacity;
  -webkit-transition-duration: 0.6s;
  -moz-transition-duration: 0.6s;
  -o-transition-duration: 0.6s;
  -ms-transition-duration: 0.6s;
  transition-duration: 0.6s;
}

.tms-slides {
  margin: 0;
  padding: 0;
  overflow: hidden;
  position: relative;
}

.tms-slide {
  overflow: hidden;
  visibility: hidden;
  position: absolute;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  left: 0;
  z-index: 1;
}

.tms-carousel .tms-slide {
  float: left;
  position: relative;
}

.tms-slide.active {
  visibility: visible;
}

.tms-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.tms-error {
  width: 100%;
  font-size: 1.4rem;
  text-align: center;
  position: relative;
  top: 50%;
}

.tms-slide > embed,
.tms-slide > iframe,
.tms-slide > object,
.tms-slide > video {
  position: relative;
  opacity: 0;
}

.tms-slide > video {
  z-index: 1;
}

.tms-caption,
.tms-content,
.tms-progress-bar {
  z-index: 2;
  position: absolute;
}

.tms-slide.tms-video-no-ratio:not(.tms-forcefit) > embed,
.tms-slide.tms-video-no-ratio:not(.tms-forcefit) > iframe,
.tms-slide.tms-video-no-ratio:not(.tms-forcefit) > object,
.tms-slide.tms-video-no-ratio:not(.tms-forcefit) > video {
  width: 100% !important;
  height: 100% !important;
  position: static;
}

.tms-slide img,
.tms-slide.tms-forcefit > embed,
.tms-slide.tms-forcefit > iframe,
.tms-slide.tms-forcefit > object {
  position: relative;
}

.tms-slide img {
  max-width: 100%;
  max-height: 100%;
  margin: 0 auto;
  display: block;
  float: none;
  transform: translate(0, 0);
}

.tms-carousel .tms-slide img {
  max-height: none;
}

.tms-fullscreen .tms-slide > img,
.tms-slide.tms-forcefit > img,
.tms-slide > video + img {
  max-width: none;
  max-height: none;
  float: none;
  position: absolute;
}

.mobile [data-video-bkg-vimeo] iframe,
.mobile [data-video-bkg-youtube] iframe,
[data-video-bkg-vimeo] iframe,
[data-video-bkg-vimeo] img,
[data-video-bkg-youtube] iframe,
[data-video-bkg-youtube] img,
[data-video-bkg] img {
  opacity: 0 !important;
}

[data-video-bkg-vimeo],
[data-video-bkg-youtube] {
  background-size: 0;
}

.mobile .tms-slide,
.tms-slide.error,
.video-bkg-loaded iframe {
  background-size: cover !important;
  opacity: 1 !important;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -webkit-transition-duration: 1s;
  transition-duration: 1s;
}

.tms-progress-bar {
  width: 0;
  height: 0.5rem;
  background: rgba(0, 0, 0, 0.3);
  top: 0;
}

.tm-loader {
  z-index: 2;
}

.tms-caption:not([class*="width-"]) {
  width: auto;
}

.tms-caption {
  display: inline-block !important;
  background-image: url(../images/blank.png);
  opacity: 0;
}

.tms-caption.scale {
  line-height: 1;
}

.tms-caption embed,
.tms-caption iframe,
.tms-caption object,
.tms-caption video {
  width: 100% !important;
  height: 100% !important;
}

.tms-caption.no-transition {
  opacity: 1 !important;
  visibility: hidden;
  display: block !important;
}

.tms-caption:last-child {
  margin-bottom: 0;
}

.tms-content {
  width: 100%;
  max-width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  display: table;
  table-layout: fixed;
  text-align: center;
}

.tms-content-inner {
  height: 100%;
  vertical-align: middle;
  display: table-cell;
  float: none;
}

.tms-content-scalable {
  padding-bottom: 3rem;
  opacity: 0;
}

.tms-content-inner .tms-caption {
  position: static;
}

.tms-fullscreen .tms-caption,
.tms-slide .row .tms-content {
  position: relative;
}

.tms-content-inner.left {
  text-align: left;
}

.tms-content-inner.right {
  text-align: right;
}

.tms-content-inner.v-align-top {
  padding-top: 7rem;
  vertical-align: top;
}

.tms-content-inner.v-align-bottom {
  padding-bottom: 7rem;
  vertical-align: bottom;
}

.tms-slide .column,
.tms-slide .row {
  height: 100%;
}

.tms-content .column,
.tms-content .row {
  height: auto;
}

@media only screen and (max-width: 768px) {
  .tms-slide .row .tms-content,
  .tms-slide .row .tms-content-inner {
    display: block;
  }
  .tms-caption.hide-on-mobile {
    display: none !important;
  }
}

.tms-arrow-nav,
.tms-pagination {
  display: none;
  z-index: 10;
  position: absolute;
  text-align: center;
}

.tms-arrow-nav {
  margin-top: -2.5rem;
  width: 5rem;
  height: 5rem;
  background-color: rgba(0, 0, 0, 0.3);
  color: #ccc;
  font-size: 3rem;
  line-height: 5rem;
}

.tms-arrow-nav:hover {
  width: 7rem;
  color: #fff;
}

#tms-next {
  top: 50%;
  right: 0;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

#tms-next:before {
  content: "\e644";
}

#tms-prev {
  top: 50%;
  left: 0;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.tms-pagination {
  width: 100%;
  bottom: 2rem;
  line-height: 0;
}

.tms-bullet-nav {
  width: 0.8rem;
  height: 0.8rem;
  margin: 0 0.3rem;
  display: inline-block;
  background: #fff;
  opacity: 0.5;
  border-radius: 50%;
}

#tml-next,
#tml-prev {
  margin-top: -2.1rem;
  top: 50%;
}

.tm-lightbox,
.tm-slider-container.pagination-below .tms-bullet-nav {
  background: #000;
}

.tms-thumb-nav {
  width: 5rem;
  height: 5rem;
  opacity: 0.5;
}

.tms-bullet-nav.active,
.tms-bullet-nav:hover,
.tms-thumb-nav.active,
.tms-thumb-nav:hover {
  opacity: 1;
}

.tm-slider-container.show-on-hover .tms-arrow-nav,
.tm-slider-container.show-on-hover .tms-pagination {
  opacity: 0;
}

.tm-slider-container.show-on-hover:hover .tms-arrow-nav,
.tm-slider-container.show-on-hover:hover .tms-pagination {
  opacity: 1;
}

#tml-caption,
#tml-content,
.tm-lightbox {
  opacity: 0;
  width: 100%;
  -webkit-backface-visibility: hidden;
}

.tm-slider-container.background-slider .tms-arrow-nav,
.tm-slider-container.background-slider .tms-pagination {
  display: none !important;
}

.tm-slider-container.pagination-below .tms-pagination {
  bottom: -3rem;
}

.tms-arrow-nav,
.tms-pagination {
  -webkit-transition-property: width, background, color, opacity;
  -moz-transition-property: width, background, color, opacity;
  -o-transition-property: width, background, color, opacity;
  -ms-transition-property: width, background, color, opacity;
  transition-property: width, background, color, opacity;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  -ms-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

@font-face {
  font-family: icomoon;
  src: url(../fonts/entypo.eot);
  src: url(../fonts/entypo.eot) format("embedded-opentype"),
    url(../fonts/entypo.woff) format("woff"), url(../fonts/entypo.ttf) format("truetype"),
    url(../fonts/entypo.svg) format("svg");
  font-weight: 400;
  font-style: normal;
}

[class*="tms-arrow-nav"]:before,
[class^="tms-arrow-nav"]:before {
  font-style: normal;
  font-weight: 400;
  text-transform: none;
  line-height: 1;
  display: inline-block;
}

.modal-open {
  overflow: hidden;
}

#tml-content,
#tml-content-wrapper,
#tml-lightbox {
  position: absolute;
}

.tm-lightbox {
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
  top: 0;
  left: 0;
  position: fixed;
  z-index: 1000;
}

#tml-caption,
#tml-tool-bar,
.tml-nav {
  position: absolute;
}

#tm-lightbox.tml-modal-mode {
  background: rgba(0, 0, 0, 0.2);
  overflow-x: hidden;
  overflow-y: auto;
}

#tml-content {
  visibility: hidden;
}

.tml-modal-mode .modal-dialog {
  background: #fff;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.5);
}

.tml-modal-mode .modal-dialog > div {
  padding-left: 3rem;
  padding-right: 3rem;
}

#tml-content-wrapper.zoomed {
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
}

#tml-content.scrollable-content:not(.modal-dialog) {
  background: #fff;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

#tml-content.tml-error {
  width: 100%;
  background: 0 0;
  font-size: 1.4rem;
  font-weight: 700;
  text-align: center;
  top: 50%;
  left: 0;
  box-shadow: none;
}

@media only screen and (max-width: 479px) {
  #tml-content-wrapper {
    left: 10% !important;
    right: 10% !important;
  }
  .tml-modal-mode #tml-content-wrapper {
    left: 5% !important;
    right: 5% !important;
  }
}

#tml-content embed,
#tml-content iframe,
#tml-content object,
#tml-content video {
  width: 100% !important;
  height: 100% !important;
}

#tml-caption {
  font-size: 1.2rem;
  font-style: italic;
  text-align: center;
  bottom: 0;
}

#tml-caption span {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  display: block;
  padding: 1rem 1.5rem;
  border-top: 0.1rem solid #eee;
}

.tml-nav {
  width: 4.4rem;
  height: 4.4rem;
  font-size: 2rem;
  text-align: center;
  text-transform: capitalize;
  line-height: 4.4rem;
  opacity: 0.4;
  z-index: 10;
}

.tml-nav:hover {
  opacity: 1;
}

#tml-next {
  right: 3rem;
}

#tml-next:before {
  content: "\e644";
}

#tml-prev {
  left: 3rem;
}

#tml-prev:before {
  content: "\e643";
}

#tml-exit {
  top: 1.5rem;
  right: 3rem;
}

.tml-modal-mode #tml-exit {
  right: 1.5rem;
}

#tml-tool-bar {
  margin: 0;
  display: inline-block;
  top: 1.5rem;
  left: 3rem;
}

#tml-tool-bar .tml-nav {
  font-size: 1.5rem;
}

#tml-tool-bar #tml-share-wrapper,
#tml-tool-bar .tml-nav,
#tml-tool-bar .tml-social-list {
  float: left;
  position: relative;
}

#tml-tool-bar .tml-social-list.list-vertical,
#tml-tool-bar .tml-social-list.list-vertical .tml-nav {
  float: none;
  text-align: left;
}

#tml-tool-bar .tml-social-list {
  margin: 0;
  text-align: left;
  opacity: 0;
  visibility: hidden;
  position: relative;
  z-index: 101;
  -webkit-transition-property: -webkit-transform, opacity, visibility;
  -moz-transition-property: -moz-transform, opacity, visibility;
  -o-transition-property: -o-transform, opacity, visibility;
  transition-property: transform, opacity, visibility;
  -webkit-transition-duration: 0.4s;
  -moz-transition-duration: 0.4s;
  -o-transition-duration: 0.4s;
  transition-duration: 0.4s;
}

#tml-tool-bar .tml-social-list,
#tml-tool-bar .tml-social-list li {
  overflow: hidden;
}

#tml-tool-bar .tml-social-list li a {
  margin-right: 1rem;
}

#tml-tool-bar .tml-social-list.active {
  opacity: 1;
  visibility: visible;
}

#tml-tool-bar .tml-social-list .tml-nav {
  width: auto;
  min-width: 4.4rem;
  font-size: 1.1rem;
}

#tml-tool-bar #tml-zoom:before {
  content: "\e6cd";
}

#tml-tool-bar #tml-share:before {
  content: "\e70b";
}

#tml-exit:before {
  content: "\e66f";
}

.tml-nav {
  -webkit-transition-property: color, opacity;
  -moz-transition-property: color, opacity;
  -o-transition-property: color, opacity;
  -ms-transition-property: color, opacity;
  transition-property: color, opacity;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  -ms-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

@font-face {
  font-family: icomoon;
  src: url(../fonts/entypo.eot);
  src: url(../fonts/entypo.eot) format("embedded-opentype"),
    url(../fonts/entypo.woff) format("woff"), url(../fonts/entypo.ttf) format("truetype"),
    url(../fonts/entypo.svg) format("svg");
  font-weight: 400;
  font-style: normal;
}

[class*="tml-nav"]:before,
[class^="tml-nav"]:before {
  font-style: normal;
  font-weight: 400;
  text-transform: none;
  line-height: 1;
  display: inline-block;
}

.parallax {
  height: 55rem;
  background: #000;
  position: relative;
  overflow: hidden;
}

.parallax.fullscreen {
  width: 100%;
  height: 100%;
  height: 100vh;
}

.parallax .horizon {
  opacity: 0;
  visibility: hidden;
}

.tm-parallax,
.tmp-content,
.tmp-content-inner {
  width: 100%;
  height: 100%;
}

.tmp-content {
  max-width: 114rem;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  display: table;
}

.tm-parallax {
  overflow: hidden;
  visibility: visible;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  -webkit-backface-visibility: hidden;
}

.tmp-content-inner {
  height: 100%;
  vertical-align: middle;
  display: table-cell;
  float: none;
  position: relative;
  z-index: 1;
}

.tmp-content-inner.left {
  text-align: left;
}

.tmp-content-inner.right {
  text-align: right;
}

.tmp-content-inner.v-align-top {
  padding-top: 7rem;
  vertical-align: top;
}

.tmp-content-inner.v-align-bottom {
  padding-bottom: 7rem;
  vertical-align: bottom;
}

.tmp-mobile {
  height: "auto";
}

.tm-parallax img {
  width: 100%;
  max-width: none;
  position: absolute;
  opacity: 1;
  -webkit-backface-visibility: hidden;
}

.grid-container.full-width .row,
.header .header-inner,
.masonry-set-dimensions.full-width .row {
  max-width: 100%;
}

.tm-loader,
.tm-loader #circle {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.tm-loader {
  width: 2rem;
  height: 2rem;
  margin: -1rem 0 0 -1rem;
  position: absolute;
  top: 50%;
  left: 50%;
}

.content,
.section-block {
  position: relative;
  z-index: 1;
}

.tm-loader #circle {
  width: 2rem;
  height: 2rem;
  background: #666;
  -webkit-animation: preloader 1s ease infinite;
  -moz-animation: preloader 1s ease infinite;
  -o-animation: preloader 1s ease infinite;
  animation: preloader 1s ease infinite;
}

@-webkit-keyframes preloader {
  from {
    opacity: 1;
    -webkit-transform: scale(1);
  }
  to {
    opacity: 0;
    -webkit-transform: scale(0);
  }
}

@keyframes preloader {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0);
  }
}

*,
:after,
:before {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.tmh-perspective {
  overflow: visible !important;
}

.tmh-perspective-parallax {
  perspective: 1600px;
  overflow: visible !important;
}

.tmh-parallax-item {
  opacity: 1;
  visibility: visible !important;
}

.no-js,
.no-js .horizon {
  opacity: 1;
  visibility: visible;
}

.content,
body {
  background-color: #fff;
}

body.boxed {
  background-color: #000;
}

body.boxed .wrapper-inner {
  background-color: #fff;
}

.section-block {
  width: 100%;
  padding-top: 7rem;
  padding-bottom: 7rem;
  background-size: cover;
  background-position: center;
  overflow: hidden;
}

.replicable-content {
  padding-bottom: 4rem;
}

.section-block.featured-media,
body.boxed .section-block.featured-media {
  padding-top: 0;
  padding-bottom: 0;
}

body.boxed .section-block {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

body.boxed .section-block.featured-media {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}

.grid-container.small-margins .grid,
.grid-container.small-margins.full-width .grid {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
}

@media only screen and (min-width: 768px) {
  .section-block.section-overlap {
    padding-top: 0;
    margin-top: -7rem;
    background-color: transparent !important;
    z-index: 2;
  }
}

@media only screen and (max-width: 960px) {
  .content-inner:not([class*="offset-"]),
  .sidebar {
    width: 100%;
  }
  .blog [class*="pagination-"] .column[class*="push-"],
  .blog [class*="pagination-"] .column[class*="pull-"],
  .content-inner.column[class*="push-"],
  .content-inner.column[class*="pull-"],
  .sidebar.column[class*="push-"],
  .sidebar.column[class*="pull-"] {
    position: static;
    left: 0;
    right: 0;
  }
}

.background-fixed,
.background-fixed:after,
.background-fixed:before {
  background-attachment: fixed;
}

.mobile .background-fixed,
.mobile .background-fixed:after,
.mobile .background-fixed:before {
  background-attachment: scroll;
}

.background-cover,
.background-cover:after,
.background-cover:before,
.fullscreen-section.background-cover .background-image {
  background-position: center center;
  -webkit-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
}

.background-contain,
.background-contain:after,
.background-contain:before,
.fullscreen-section.background-contain .background-image {
  background-position: center center;
  -webkit-background-size: contain;
  background-size: contain;
  background-repeat: no-repeat;
}

.background-full,
.fullscreen-section.background-full .background-image {
  background-size: 100%;
  background-position: center center;
  background-repeat: no-repeat;
}

.background-none {
  background: 0 0 !important;
}

.background-image-none {
  background-image: none !important;
}

.horizon {
  opacity: 0;
  visibility: hidden;
}

.aux-navigation-active .one-page-nav + .wrapper {
  position: absolute;
}

.aux-navigation-active .overlay-navigation-wrapper.one-page-nav,
.aux-navigation-active .side-navigation-wrapper.one-page-nav {
  position: fixed;
}

.wrapper.inactive .header-fixed,
.wrapper.inactive .tm-slider-parallax-container .tm-slider-container {
  position: relative !important;
}

.wrapper.inactive .header-fixed-on-mobile .header-inner {
  position: absolute !important;
}

.media-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

.media-overlay + * {
  position: relative;
}

.opacity-01 {
  opacity: 0.1;
}

.opacity-02 {
  opacity: 0.2;
}

.opacity-03 {
  opacity: 0.3;
}

.opacity-04 {
  opacity: 0.4;
}

.opacity-05 {
  opacity: 0.5;
}

.opacity-06 {
  opacity: 0.6;
}

.opacity-07 {
  opacity: 0.7;
}

.opacity-08 {
  opacity: 0.8;
}

.opacity-09 {
  opacity: 0.9;
}

.opacity-1 {
  opacity: 1;
}

.grid-container {
  position: relative;
}

.grid-container .grid {
  max-width: 100%;
  min-height: 30rem;
  margin-bottom: -3rem;
  visibility: hidden;
  z-index: 1;
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;
}

.masonry.fade-in-progressively .grid-item,
.masonry.fade-in-progressively .masonry-stamp {
  opacity: 0;
}

.masonry .filtering {
  -webkit-transition-property: height;
  transition-property: height;
  -webkit-transition-duration: 0.8s;
  transition-duration: 0.8s;
}

.masonry .tm-loader {
  top: 6rem;
}

.grid-container > .row > .column {
  padding-left: 0;
  padding-right: 0;
}

.grid-container .grid .grid-item {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.grid-container .thumbnail {
  margin-bottom: 0;
  float: none;
}

.grid-container.small-margins > .row > .column {
  padding-left: 1rem;
  padding-right: 1rem;
}

.grid-container.small-margins.full-width > .row > .column {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

body.boxed .grid-container.small-margins.full-width > .row > .column {
  padding-left: 1rem;
  padding-right: 1rem;
}

.grid-container.small-margins.no-margins.full-width > .row > .column {
  padding-left: 0;
  padding-right: 0;
}

.grid-container.small-margins .grid {
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
}

.grid-container.small-margins .grid .grid-item {
  margin-bottom: 0;
  padding: 0.5rem;
  position: relative;
}

.grid-container.no-margins > .row > .column {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.grid-container.no-margins .grid {
  margin-bottom: 0;
}

.grid-container.no-margins .grid,
.grid-container.no-margins .grid .grid-item {
  padding: 0 !important;
  margin: 0 !important;
}

.grid-container.full-width .grid {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.grid-container.full-width > .row > .column {
  padding-left: 0;
  padding-right: 0;
}

.grid-container.full-width.no-margins {
  padding: 0 !important;
}

.grid-container.full-width.no-padding-bottom .grid {
  margin-bottom: 0;
}

.masonry-set-dimensions.full-width.no-padding-bottom {
  padding-bottom: 1.5rem !important;
}

.masonry-set-dimensions.full-width.small-margins.no-padding-bottom {
  padding-bottom: 0.5rem !important;
}

.masonry-set-dimensions {
  padding-top: 7rem;
  padding-bottom: 6rem;
}

.masonry-set-dimensions.full-width .grid {
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;
}

.masonry-set-dimensions.full-width > .row > .column {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.masonry-set-dimensions.small-margins.full-width > .row > .column {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.masonry-set-dimensions.no-margins .grid,
.masonry-set-dimensions.no-margins .grid .grid-item {
  padding: 0 !important;
  margin: 0 !important;
}

.masonry-set-dimensions .grid,
.masonry-set-dimensions > .row > .column,
.masonry > .row > .column {
  padding-left: 0;
  padding-right: 0;
}

.masonry-set-dimensions.full-width.no-margins {
  padding: 0;
}

.masonry-set-dimensions .grid {
  margin-top: -1.5rem;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: -1.5rem;
}

.masonry-set-dimensions .grid .grid-item {
  margin-bottom: 0;
  padding: 1.5rem;
  position: relative;
}

.masonry-set-dimensions.small-margins > .row > .column {
  padding-left: 1rem;
  padding-right: 1rem;
}

.masonry-set-dimensions.small-margins .grid {
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
}

.masonry-set-dimensions.small-margins .grid .grid-item {
  padding: 0.5rem;
}

.masonry-set-dimensions.no-margins > .row > .column {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.masonry-set-dimensions.no-margins.full-width > .row > .column {
  padding-left: 0;
  padding-right: 0;
}

.masonry-set-dimensions .thumbnail {
  height: 100%;
  overflow: hidden;
  margin-bottom: 0;
}

.masonry-set-dimensions .grid-item img {
  width: 100%;
}

.masonry-set-dimensions .content-outer {
  width: 100%;
  height: 100%;
  display: table;
  position: relative;
  bottom: 0;
}

.masonry-set-dimensions .thumbnail + .content-outer {
  position: absolute;
}

.masonry-set-dimensions .content-inner {
  width: 100%;
  height: 100%;
  padding: 3rem;
  display: table-cell;
  vertical-align: middle;
}

.masonry-set-dimensions .content-inner > :first-child {
  margin-top: 0;
}

.masonry-set-dimensions .content-inner > :last-child {
  margin-bottom: 0;
}

.masonry-set-dimensions .content-slider,
.masonry-set-dimensions iframe,
.masonry-set-dimensions video {
  width: 100% !important;
  height: 100% !important;
}

.masonry-set-dimensions .content-slider img {
  width: auto;
}

.masonry-set-dimensions .mejs-container {
  width: 20rem;
  height: 3rem;
  margin-left: -10rem;
  margin-top: -1.5rem;
  position: absolute;
  left: 50%;
  top: 50%;
}

.masonry-stamp {
  padding: 1.5rem;
}

.small-margins .masonry-stamp {
  padding: 0.5rem;
}

.no-margins .masonry-stamp {
  padding: 0;
}

.grid-filter-menu {
  padding-top: 3.5rem;
  padding-bottom: 0;
  text-align: center;
}

.grid-filter-menu.left {
  text-align: left;
}

.grid-filter-menu.left li:first-child a {
  padding-left: 0;
  margin-left: 0;
}

.grid-filter-menu.right {
  text-align: right;
}

.grid-filter-menu.right li:last-child a {
  padding-right: 0;
  margin-right: 0;
}

.grid-filter-menu ul {
  width: 100%;
  padding-top: 3.5rem;
  padding-bottom: 0;
  margin-bottom: 0;
}

.grid-filter-menu li {
  padding: 0 2rem;
  display: inline-block;
  font-size: 1.2rem;
  line-height: 1;
  text-transform: uppercase;
}

.grid-filter-menu a {
  padding: 1rem 0.8rem;
  -webkit-transition-property: background, border-color, color, opacity;
  transition-property: background, border-color, color, opacity;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
}

.grid-filter-menu a:hover {
  color: #333;
}

.grid-filter-menu a.active {
  color: #333;
  border-bottom: 1px solid #333;
}

.grid .project-description,
.grid .project-title {
  display: block;
}

.grid .project-title + .project-description {
  margin-top: 0.5rem;
}

.grid .item-description {
  width: 100%;
  margin-top: 2rem;
}

.grid .item-description .project-title {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.grid .item-description:last-child {
  margin-bottom: 0;
}

.isotope-item {
  z-index: 2;
}

.isotope-hidden.isotope-item {
  pointer-events: none;
  z-index: 1;
}

.isotope,
.isotope .isotope-item {
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
}

.isotope {
  -webkit-transition-property: height, width;
  transition-property: height, width;
}

.isotope .isotope-item {
  -webkit-transition-property: -webkit-transform, opacity;
  transition-property: transform, opacity;
}

.isotope .isotope-item.no-transition,
.isotope.no-transition,
.isotope.no-transition .isotope-item {
  -webkit-transition-duration: 0s;
  transition-duration: 0s;
}

@media only screen and (max-width: 1300px) {
  .grid-container .content-grid-5 .grid-item,
  .grid-container .content-grid-6 .grid-item {
    width: 25%;
  }
}

@media only screen and (max-width: 1140px) {
  .grid-container .grid .grid-item {
    width: 33.33333%;
  }
  .content-inner .grid-container .grid .grid-item,
  .grid-container .content-grid-2 .grid-item,
  .grid-container.full-width.no-margins .content-grid-2 .grid-item,
  .grid-container.full-width.small-margins .content-grid-2 .grid-item {
    width: 50%;
  }
}

@media only screen and (max-width: 960px) {
  .content-inner .grid-container .grid .grid-item,
  .grid-container .grid .grid-item {
    width: 50%;
  }
  .grid-container.full-width.no-margins .grid .grid-item,
  .grid-container.full-width.small-margins .grid .grid-item {
    width: 33.33333%;
  }
}

@media only screen and (max-width: 768px) {
  .grid-container .grid .grid-item.large,
  .grid-container .grid .grid-item.portrait.large {
    width: 100%;
  }
  .grid-container.full-width .grid .grid-item,
  .grid-container.full-width.no-margins .grid .grid-item,
  .grid-container.full-width.small-margins .grid .grid-item {
    width: 50%;
  }
}

@media only screen and (max-width: 600px) {
  .content-inner .grid-container .grid .grid-item,
  .grid-container .grid .grid-item,
  .grid-container.full-width .grid .grid-item {
    width: 100%;
  }
}

@media only screen and (max-width: 480px) {
  .grid-container.full-width.no-margins .grid .grid-item,
  .grid-container.full-width.small-margins .grid .grid-item {
    width: 100%;
  }
}

.header {
  width: 100%;
  position: relative;
}

.header .header-inner {
  width: 100%;
  background-color: #232323;
}

.header .header-inner > .nav-bar {
  margin-bottom: -1px;
}

.header-transparent .header-inner {
  background-color: transparent;
  background-image: url(../images/blank.png);
}

.header-absolute {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
}

.header-fixed {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
}

.header-bottom,
.ie-browser .wrapper.inactive .header-bottom {
  position: absolute;
}

.header-bottom {
  bottom: 0;
  top: auto;
  z-index: 100;
}

.header .header-inner {
  opacity: 1;
  position: relative;
  z-index: 100;
  -webkit-transform: translateZ(0);
}

.header-fixed-width .header-inner,
body.boxed .header .header-inner {
  max-width: 114rem;
  margin-left: auto;
  margin-right: auto;
}

.header-sticky .header-inner {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  bottom: auto;
  z-index: 100;
}

body.boxed .header .header-inner {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.header .header-inner .dropdown > .nav-icon,
.header .header-inner .navigation > ul > li,
.header .header-inner .navigation > ul > li > a:not(.button),
.header .logo {
  height: 8rem;
  line-height: 8rem;
}

.header-compact .header-inner .dropdown > .nav-icon,
.header-compact .header-inner .navigation > ul > li,
.header-compact .header-inner .navigation > ul > li > a:not(.button),
.header-compact .logo {
  height: 6rem;
  line-height: 6rem;
}

.header .header-inner .navigation > ul > li,
.header .logo {
  display: table;
}

.header .logo-inner,
.header .v-align-middle {
  height: 100%;
  display: table-cell;
  vertical-align: middle;
  position: relative;
}

.header .logo-inner {
  overflow: hidden;
}

.header .logo {
  width: 13rem;
}

.header .logo span {
  font-size: 2rem;
  line-height: 2;
}

.header-compact .logo {
  width: 10rem;
}

.header-compact .logo span {
  font-size: 1.5rem;
  line-height: 2;
}

.header .logo a {
  font-weight: 700;
  color: #fff;
  display: block;
  -webkit-transition-property: opacity, background, color, visibility, -webkit-transform;
  transition-property: opacity, background, color, visibility, transform;
}

.header .logo a:hover {
  opacity: 0.6 !important;
}

.header .logo img {
  width: 100%;
  height: auto;
  max-height: 100%;
}

.header .logo a:first-child {
  display: none;
}

.header .logo a:last-child,
.header-background .logo a:first-child,
.mobile .header .logo a:first-child {
  display: table-cell;
}

.header-background .logo a + a {
  display: none !important;
}

.header .navigation {
  float: right;
}

.header .secondary-navigation.nav-left:not(.with-division) > ul {
  margin-left: -1.5rem;
}

.header .secondary-navigation.nav-right:not(.with-division) > ul {
  margin-right: -1.5rem;
}

.header .navigation .nav-icon,
.header .navigation .v-align-middle,
.header .navigation.nav-left:first-child > .nav-icon,
.header .navigation.nav-right:last-child > .nav-icon {
  padding: 0 1.5rem;
}

.header .with-division .nav-icon,
.header .with-division .v-align-middle,
.header .with-division > ul > li:last-child > .nav-icon {
  padding: 0 2rem !important;
}

.header .navigation .nav-icon,
.header .navigation .nav-icon [class*="icon-"],
.header .navigation .nav-icon [class*="icon-"]:before,
.header .social-list [class*="icon-"] {
  margin: 0;
  line-height: inherit;
  float: none;
}

.header .navigation .nav-icon [class*="icon-"] {
  display: initial;
}

.header .navigation .nav-icon {
  font-size: 1.4rem;
  background: 0 0;
  border: none;
}

.header .with-division ul {
  float: left;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
}

.header .with-division li {
  border-right: 1px solid rgba(0, 0, 0, 0.2);
}

@media only screen and (min-width: 960px) {
  .header-transparent .with-division ul {
    border-left: 1px solid rgba(255, 255, 255, 0.2);
  }
  .header-transparent .with-division li {
    border-right: 1px solid rgba(255, 255, 255, 0.2);
  }
}

.header .button,
.header .dropdown,
.header .navigation-show {
  margin: 0;
  line-height: initial;
}

.header .navigation-show.nav-icon {
  width: auto;
  height: auto;
}

.header .navigation-show {
  display: block;
}

.header .navigation-show * {
  padding: 0;
  margin: 0;
}

.header .nav-left .dropdown-list {
  left: 0;
}

.header .nav-right .dropdown-list {
  right: 0;
}

.header .cart-indication {
  position: relative;
}

.header .cart-indication .badge {
  background: #232323;
  top: -0.8rem;
  right: -0.8rem;
}

.header .cart + .dropdown-list,
.header .cart + .sub-menu {
  width: 30rem;
  padding: 2rem;
  margin-top: 0;
  right: 0;
}

.header .search + .dropdown-list,
.header .search + .sub-menu {
  padding: 2rem;
  margin-top: 0;
  width: 25rem;
  right: 0;
}

.header .social-list.pull-left li:last-child span {
  margin-left: 0;
}

.header .social-list.center li:last-child span,
.header .social-list.pull-right li:last-child span {
  margin-right: 0;
}

.header-background .header-inner {
  background: #232323;
}

.mobile .header {
  position: static !important;
}

.mobile .header-fixed-on-mobile .header-inner {
  width: 100%;
  position: fixed !important;
  left: 0;
  top: 0;
}

.mobile .header-sticky .header-inner {
  position: relative;
}

.mobile .header,
.mobile .header .header-inner {
  height: 8rem !important;
  padding-top: 0;
  background: #232323;
}

.mobile .header .header-inner,
.mobile .header .nav-bar {
  border-top: none !important;
}

.mobile .header .header-inner-top {
  display: none;
}

.mobile .header .nav-bar,
.mobile .header .nav-bar-inner {
  height: 100%;
}

.mobile .header .logo {
  height: 100%;
  padding: 0 !important;
  margin: 0 !important;
  display: table;
  float: left;
}

.mobile .header .logo-inner {
  display: table-cell;
  vertical-align: middle;
}

.mobile .header .logo-inner a {
  display: block;
  position: relative;
}

.mobile .header .logo a:first-child {
  display: table-cell;
  vertical-align: middle;
}

.mobile .header .logo a + a {
  display: none !important;
}

.mobile .header .navigation-show {
  display: block !important;
  visibility: visible !important;
}

.mobile .header .header-inner,
.mobile .header .logo,
.mobile .header .logo span,
.mobile .header .navigation-show,
.mobile .header .navigation-show a span {
  transition: none;
}

.header.header-animated {
  -webkit-transition-property: height, transform, visibility;
  transition-property: height, transform, visibility;
}

.header-animated .dropdown > .nav-icon,
.header-animated .header-inner,
.header-animated .header-inner .navigation,
.header-animated .header-inner .navigation > ul > li,
.header-animated .header-inner .navigation > ul > li > a,
.header-animated .navigation-show a {
  -webkit-transition-property: height, background, border, padding, margin, font-size, line-height,
    color, opacity;
  transition-property: height, background, border, padding, margin, font-size, color, line-height,
    opacity;
}

.header-animated .logo,
.header-animated .logo span {
  -webkit-transition-property: width, height, background, border, padding, margin, font-size,
    line-height, opacity;
  transition-property: width, height, background, border, padding, margin, font-size, line-height,
    opacity;
}

.header-animated,
.header-animated .dropdown > .nav-icon,
.header-animated .header-inner,
.header-animated .header-inner .navigation,
.header-animated .header-inner .navigation > ul > li,
.header-animated .header-inner .navigation > ul > li > a,
.header-animated .logo,
.header-animated .logo a,
.header-animated .logo span,
.header-animated .navigation-show a {
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
}

.header-hide .header-inner {
  opacity: 0;
}

.contact-form-container input[disabled="disabled"][type="submit"],
.signup-form-container input[disabled="disabled"][type="submit"] {
  opacity: 0.6;
}

.header-positioned .header-inner {
  -webkit-transform: translate3d(0, -10rem, 0);
  transform: translate3d(0, -10rem, 0);
}

.header-in .header-inner,
.header-out .header-inner {
  -webkit-transition-property: -webkit-transform;
  transition-property: transform;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
}

.header-in .header-inner {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.header-out .header-inner {
  -webkit-transform: translate3d(0, -10rem, 0);
  transform: translate3d(0, -10rem, 0);
}

.header-in .header-inner .logo,
.header-in .header-inner .navigation,
.header-in .navigation-show {
  transition: none !important;
}

@media only screen and (max-width: 1140px) {
  body.boxed .header-fixed-on-mobile .header-inner {
    max-width: 96rem;
  }
}

@media only screen and (max-width: 960px) {
  .header {
    background: 0 0;
    position: static !important;
  }
  .header-fixed-on-mobile .header-inner,
  body.boxed .header-fixed-on-mobile {
    width: 100%;
    position: fixed !important;
    left: 0;
    top: 0;
  }
  body.boxed .header-fixed-on-mobile .header-inner {
    max-width: 76rem;
    position: static !important;
  }
  .header-sticky .header-inner {
    position: relative;
  }
  .header .header-inner {
    background: #232323;
  }
  .header .header-inner,
  .header .nav-bar {
    border-top: none !important;
  }
  .header,
  .header .header-inner {
    height: 8rem !important;
    padding-top: 0;
    border: none;
  }
  .header .header-inner-top {
    display: none;
  }
  .header .nav-bar,
  .header .nav-bar-inner {
    height: 100%;
  }
  .header .logo-inner a {
    display: block;
    position: relative;
  }
  .header .logo a + a {
    display: none !important;
  }
  .header .header-inner,
  .header .logo,
  .header .logo span,
  .header .navigation-show,
  .header .navigation-show a span {
    transition: none;
  }
  .header .navigation-show {
    display: block !important;
    visibility: visible !important;
  }
}

.hero-2,
.hero-3,
.intro-title-2 {
  background-repeat: no-repeat;
}

@media only screen and (max-width: 768px) {
  body.boxed .header-fixed-on-mobile .header-inner {
    max-width: 60rem;
  }
}

@media only screen and (max-width: 600px) {
  body.boxed .header-fixed-on-mobile .header-inner {
    max-width: 48rem;
  }
  .header .v-align-middle,
  .navigation .dropdown,
  .navigation > ul > li {
    position: static;
  }
  .navigation .dropdown-list {
    width: auto !important;
    left: 1.5rem !important;
    right: 1.5rem !important;
  }
}

@media only screen and (max-width: 480px) {
  body.boxed .header-fixed-on-mobile .header-inner {
    max-width: 35rem;
  }
}

@media only screen and (max-width: 350px) {
  body.boxed .header-fixed-on-mobile .header-inner {
    max-width: 100%;
  }
}

.side-navigation-header,
.side-navigation-header .logo {
  min-height: 8rem;
  line-height: 8rem;
}

.side-navigation-footer,
.side-navigation-header {
  font-size: 1.4rem;
  line-height: 1.8;
}

.side-navigation-header {
  padding: 0 4rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid #232323;
}

.side-navigation-header .logo {
  width: 100%;
}

.side-navigation-header .logo a {
  font-size: 1.5rem;
  font-weight: 700;
  text-decoration: none;
  color: #666;
}

.hero-1,
.hero-1 h2,
.hero-1 h6,
.hero-2,
.hero-2 h2,
.hero-2 h6,
.intro-title-2 .subtitle,
.intro-title-2 h1,
.overlay-navigation-footer .social-list a:hover,
.side-navigation-footer .social-list a:hover {
  color: #fff;
}

.side-navigation-wrapper .navigation-hide {
  position: absolute;
  right: 0;
  top: 0;
}

.side-navigation {
  margin-bottom: 3rem;
}

.side-navigation-inner > :nth-last-child(2) {
  padding-bottom: 8rem;
  margin-bottom: 0;
}

.side-navigation .cart-indication {
  margin-left: 0.5rem;
  position: relative;
}

.side-navigation .cart-indication .badge {
  top: 0.3rem;
  left: 0.3rem;
}

.side-navigation .cart-overview li:first-child {
  padding-top: 2rem;
}

.side-navigation .cart-overview li:last-child {
  padding-bottom: 2rem;
}

.side-navigation .custom-content {
  width: 100%;
  padding: 0 4rem;
  margin-top: 0;
  right: 0;
}

.side-navigation-footer {
  width: 100%;
  padding: 1rem 4rem;
  font-size: 1.2rem;
  position: absolute;
  bottom: 0;
}

.side-navigation-footer .social-list {
  margin-bottom: 1rem;
}

.side-navigation-wrapper.center .logo,
.side-navigation-wrapper.center .side-navigation,
.side-navigation-wrapper.center .side-navigation-footer {
  text-align: center;
}

.side-navigation-wrapper.center .side-navigation .sub-menu a {
  padding-left: 4rem;
}

.overlay-navigation-header {
  width: 100%;
  padding: 1rem 0;
  margin-bottom: 3rem;
  position: absolute;
  top: 2rem;
}

.overlay-navigation-header .logo {
  width: 13rem;
  margin: 0 auto;
}

.hero-content-inner > :first-child,
.title-container-inner > :first-child {
  margin-top: 0;
}

.overlay-navigation-wrapper .navigation-hide {
  min-height: 4rem;
  line-height: 4rem;
  position: absolute;
  right: 3rem;
  top: 0;
}

.overlay-navigation {
  margin-bottom: 3rem;
}

.overlay-navigation:first-child {
  padding-top: 5rem;
}

.overlay-navigation:last-child {
  padding-bottom: 8rem;
  margin-bottom: 0;
}

.overlay-navigation .cart-indication {
  margin-left: 0.5rem;
  position: relative;
}

.overlay-navigation .cart-indication .badge {
  top: 0.3rem;
  left: 0.3rem;
}

.overlay-navigation .cart + .dropdown-list,
.overlay-navigation .cart + .sub-menu {
  width: 100%;
  padding: 0;
  margin-top: 0;
  right: 0;
}

.overlay-navigation .cart-overview li:first-child {
  padding-top: 2rem;
}

.overlay-navigation .cart-overview li:last-child {
  padding-bottom: 2rem;
}

.overlay-navigation-footer {
  padding: 1rem 4rem;
  font-size: 1.2rem;
  position: absolute;
  bottom: 0;
}

.hero-3.center:not(.middle),
.hero-3.top {
  padding-top: 0;
}

.intro-title-2,
[class*="hero-"] {
  position: relative;
}

.overlay-navigation-footer .copyright {
  font-size: 1.1rem;
}

.overlay-navigation-footer .social-list {
  margin-bottom: 1rem;
}

.hero-content-inner > :last-child,
.title-container-inner > :last-child,
.title-container[class^="title-"] {
  margin-bottom: 0;
}

.title-container,
[class*="intro-title-"] .column,
[class*="intro-title-"] .row {
  height: 100%;
}

.title-container {
  width: 100%;
  display: table;
}

.title-container-inner {
  display: table-cell;
  vertical-align: middle;
}

[class*="intro-title-"] .subtitle {
  font-size: 2.2rem;
  line-height: 1.5;
  font-weight: 300;
  font-style: italic;
}

.testimonial-1 cite,
.testimonial-2 cite,
.testimonial-5 cite {
  font-style: normal;
  letter-spacing: 0.1rem;
}

.intro-title-1 {
  height: 40rem;
}

/* .intro-title-2 {
  height: 40rem;
  background-image: url(../images/slider/slide-9-fw.jpg);
  background-position: center center;
  -webkit-background-size: cover;
  background-size: cover;
} */

@media only screen and (max-width: 768px) {
  [class*="intro-title-"] h1 {
    font-size: 4rem;
  }
}

@media only screen and (max-width: 480px) {
  [class*="intro-title-"] h1 {
    font-size: 3.5rem;
  }
}

.call-to-action-2 p,
.call-to-action-3 p {
  font-size: 2.5rem;
}

/* @media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (min-device-pixel-ratio: 2),
  only screen and (min-resolution: 192dpi),
  only screen and (min-resolution: 2dppx) {
  .intro-title-2 {
    background-image: url(../images/slider/slide-2-fs-hp@2x.jpg);
  }
} */

[class*="hero-"] > .row:not(.flex),
[class*="hero-"] > .row:not(.flex) > .column {
  height: 100%;
}

.hero-content:not([class*="width-"]) {
  width: 100%;
}

.hero-content,
.hero-slider .tms-caption.no-transition {
  height: 100%;
  display: table !important;
  table-layout: fixed;
}

.hero-slider .hero-content-inner,
.section-block .hero-content-inner {
  display: table-cell;
  vertical-align: middle;
}

.section-block .hero-content-inner .column,
.section-block .hero-content-inner .row {
  height: auto;
}

.hero-1,
.hero-2,
.hero-3 {
  height: 50rem;
  text-align: left;
}

.hero-1 {
  background-color: #1ecd6d;
}

/* .hero-2 {
  background-image: url(../images/slider/slide-2-fs-hp.jpg);
} */

/* .hero-3 {
  display: table;
  background-image: url(../images/slider/slide-5-fw.jpg);
} */

.hero-3 > div {
  display: table-cell;
  vertical-align: middle;
  float: none;
}

.hero-3 > div > .row,
.hero-3 > div > .row > .column {
  height: auto;
}

.hero-3.right > div > .row > .column {
  float: right;
}

.hero-3.top > div {
  vertical-align: top;
}

.hero-3.top .hero-content {
  border-bottom: 8px solid #333;
}

.hero-3.bottom {
  padding-bottom: 0;
}

.hero-3.bottom > div {
  vertical-align: bottom;
}

.hero-3.bottom .hero-content {
  border-top: 8px solid #333;
}

.hero-3.center-left > div > .row > .column {
  text-align: left;
}

.hero-3.center > div > .row > .column {
  margin: auto;
  float: none;
}

.hero-slider,
.hero-slider .hero-content:last-child {
  margin-bottom: 0;
}

.hero-3.center-right > div > .row > .column {
  text-align: right;
  float: right;
}

.hero-3 > div > .row {
  width: 100%;
  bottom: 0;
}

.hero-3 .hero-content {
  background: #fff;
  position: relative;
  bottom: 0;
}

/* .hero-4,
.hero-5 .media-column {
  background-image: url(../images/slider/slide-9-fw.jpg);
} */

.hero-3 .hero-content-inner {
  padding: 3rem;
}

.hero-3 h2,
.hero-3 h6 {
  color: #666;
}

.hero-4,
.hero-4 h2,
.hero-4 h6 {
  color: #fff;
}

.hero-4 {
  height: 50rem;
  text-align: left;
  background-repeat: no-repeat;
}

.hero-4 > .row:before {
  width: 50%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  content: "";
  position: absolute;
  top: 0;
  left: 0;
}

.hero-4.right > .row:before {
  top: 0;
  right: 0;
  left: auto;
}

.hero-4 .column {
  position: relative;
}

.hero-5 > .row > .column {
  position: relative;
  z-index: 1;
}

.hero-5 .media-column,
.hero-slider .tms-slide > .media-column {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: 0;
  position: absolute;
  top: 0;
}

.hero-5 .media-column {
  height: 100%;
  left: 0;
}

.hero-5 .media-column .map-container,
.hero-5 .media-column iframe,
.hero-5 .media-column video {
  width: 100%;
  height: 100%;
}

.hero-5 .media-column .hero-content-inner {
  padding: 8.5rem;
}

.hero-5 .media-column + .media-column,
.hero-5.right > .media-column {
  left: auto;
  right: 0;
}

.hero-5 .media-column .content-slider {
  height: 100% !important;
}

.hero-slider {
  width: 100% !important;
  height: auto;
  min-height: 50rem;
}

.hero-slider .hero-content,
.hero-slider .tms-slide,
.hero-slider .tms-slides {
  height: 100%;
}

.hero-slider .tms-slides {
  display: flex;
  flex-wrap: wrap;
}

.hero-slider .tms-slide {
  padding: 0 !important;
  display: inherit;
}

.hero-slider .tms-slide > .media-column {
  height: 100%;
  left: 0;
}

.hero-slider .tms-slide.right > .media-column {
  left: auto;
  right: 0;
}

.hero-slider .tms-pagination {
  width: 50%;
  text-align: right;
  padding-right: 5rem;
}

.hero-slider .hero-content:not([class*="width-"]) {
  width: 50%;
}

.hero-slider .hero-content {
  min-height: 50rem;
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 0;
  left: 0;
}

.call-to-action-3,
.feature-2,
.feature-3 {
  position: relative;
}

.hero-slider .media-column + .row .hero-content {
  background: 0 0;
}

.hero-slider .tms-content-scalable {
  padding-top: 10rem;
  padding-bottom: 10rem;
}

.hero-slider .tms-slide[class*="bkg-"] .hero-content {
  background-color: inherit;
}

.hero-slider .hero-content.right {
  text-align: left;
  left: auto;
  right: 0;
}

@media only screen and (max-width: 1040px) {
  .hero-5 .media-column .hero-content-inner {
    padding: 2.5rem;
  }
}

@media only screen and (max-width: 768px) {
  .hero-1,
  .hero-2,
  .hero-3,
  .hero-4,
  .hero-5 {
    height: auto !important;
  }
  [class*="hero-"] .column:first-child .hero-content {
    margin-bottom: 5rem;
  }
  [class*="hero-"] .column:last-child .hero-content {
    margin-bottom: 0;
  }
  .hero-content {
    text-align: center;
  }
  .hero-3[class*="center"] {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }
  .hero-4 > .row:after,
  .hero-4 > .row:before {
    width: 100%;
    left: 0;
    top: 0;
  }
  .hero-5 .media-column {
    display: none;
    visibility: hidden;
  }
  .hero-5.show-media-column-on-mobile {
    padding-top: 0;
  }
  .hero-5.show-media-column-on-mobile .media-column {
    display: block;
    visibility: visible;
    width: 100%;
    position: relative;
  }
  .hero-5.show-media-column-on-mobile .media-column,
  .hero-5.show-media-column-on-mobile .media-column iframe,
  .hero-5.show-media-column-on-mobile .media-column video {
    height: 40rem;
  }
  .hero-5 .media-column .split-hero-content {
    max-width: 60rem;
    margin: 0 auto;
  }
  .hero-5 .media-column .hero-content-inner {
    padding: 1.5rem;
  }
  .hero-5 .column:first-child:not(.media-column) .split-hero-content {
    margin-top: 11rem;
  }
  .hero-5 .column:not(.media-column) .split-hero-content {
    margin-top: 5rem;
    margin-bottom: 0;
  }
  .hero-5 .row + .media-column {
    margin-top: 8rem;
    margin-bottom: -11rem;
  }
  .hero-5 .tms-pagination {
    text-align: center !important;
  }
  .hero-slider .tms-pagination {
    width: 100%;
    padding: 0;
    text-align: center;
  }
  .hero-slider .hero-content-inner {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .hero-slider .hero-content {
    width: 100%;
    height: inherit;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.6);
  }
  .hero-slider .hero-content,
  .hero-slider .hero-content * {
    color: #fff;
  }
  .hero-slider .media-column + .row {
    max-width: 100% !important;
  }
}

.section-block.feature-1,
.section-block.feature-2 {
  padding-bottom: 0;
}

.call-to-action-1,
.call-to-action-2 {
  padding-left: 3rem;
  padding-right: 3rem;
  text-align: center;
}

@media only screen and (max-width: 600px) {
  .hero-5 .media-column .split-hero-content,
  [class*="hero-"] > .row > .column {
    max-width: 48rem;
  }
}

@media only screen and (max-width: 480px) {
  .hero-5 .media-column .split-hero-content,
  [class*="hero-"] > .row > .column {
    max-width: 35rem;
  }
}

@media only screen and (max-width: 350px) {
  .hero-5 .media-column .split-hero-content,
  [class*="hero-"] > .row > .column {
    width: 100%;
  }
}

/* @media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (min-device-pixel-ratio: 2),
  only screen and (min-resolution: 192dpi),
  only screen and (min-resolution: 2dppx) {
  .hero-2 {
    background-image: url(../images/slider/slide-2-fs-hp@2x.jpg);
  }
  .hero-3 {
    background-image: url(../images/slider/slide-5-fw@2x.jpg);
  }
  .hero-4 {
    background-image: url(../images/slider/dev-video-cta-3@2x.jpg);
  } */
/* .hero-5 .media-column {
    background-image: url(../images/slider/slide-9-fw@2x.jpg);
  }
} */

.feature-content,
.feature-image,
.feature-slider {
  width: 100%;
  height: 100%;
  display: table;
  table-layout: fixed;
}

.feature-content-inner,
.feature-image-inner,
.feature-slider-inner {
  display: table-cell;
  vertical-align: middle;
}

.feature-slider .tm-slider-container {
  width: 100% !important;
}

.feature-slider .tms-pagination {
  bottom: 0;
}

.feature-content-inner:first-child {
  margin-top: 0;
}

.feature-1 {
  text-align: center;
}

.feature-2 .row .feature-image,
.feature-2 .row.flex .feature-image {
  width: 170%;
  margin-right: -70%;
}

.feature-2.right .row .feature-image {
  margin-left: -70%;
}

.feature-3 .feature-image {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.feature-3 .feature-image-inner {
  max-height: 53rem;
}

@media only screen and (max-width: 960px) {
  .feature-2 {
    padding-bottom: 7rem;
  }
  .feature-2 .row .feature-image {
    height: 100%;
  }
  .header + .content > [class*="feature-"]:first-child {
    padding-top: 5rem;
  }
}

@media only screen and (min-width: 768px) {
  .feature-2 > .row > .column,
  .feature-2 > .row > .column > .box,
  .feature-3 > .row > .column,
  .feature-3 > .row > .column > .box {
    height: auto;
    display: inherit;
  }
  .feature-2 > .row > .column > :not(.box),
  .feature-2 > .row > .column > div > :not(.box),
  .feature-3 > .row > .column > :not(.box),
  .feature-3 > .row > .column > div > :not(.box) {
    height: auto;
    margin-top: auto;
    margin-bottom: auto;
  }
  .feature-2 .feature-content-inner:last-child {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 768px) {
  .feature-2.left,
  .feature-3 .column:last-child .feature-content {
    padding-bottom: 0;
  }
  .feature-2 .column,
  .feature-3 .column {
    height: auto !important;
  }
  .feature-2 .row .feature-image {
    width: 100% !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    float: none;
  }
  .feature-2.right .column + .column .feature-content {
    margin-bottom: 0;
  }
  .feature-3 .feature-image {
    text-align: center;
  }
  .feature-3 .feature-image img,
  .feature-3 .feature-slider {
    max-width: 60%;
    margin: 0 auto;
  }
  .feature-2.right .feature-image,
  .feature-3 .feature-image,
  .feature-3 .feature-slider {
    margin-bottom: 5rem;
  }
  .feature-3 .column:last-child .feature-column:last-child,
  .feature-3 .column:last-child .feature-image {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 480px) {
  .feature-3 .feature-image img,
  .feature-3 .feature-slider {
    max-width: 70%;
  }
}

[class*="call-to-action-"] .column,
[class*="call-to-action-"] .row {
  height: 100%;
}

.call-to-action-content {
  width: 100%;
  height: 100%;
  display: table !important;
}

.call-to-action-content-inner {
  display: table-cell;
  vertical-align: middle;
}

[class*="call-to-action-"] .column:last-child .call-to-action-content-inner {
  padding-bottom: 0;
}

.call-to-action-content-inner:first-child {
  margin-top: 0;
}

.call-to-action-content-inner:last-child {
  margin-bottom: 0;
}

[class*="call-to-action-"] {
  background-color: inherit;
}

.call-to-action-1 {
  width: 100%;
  display: block;
}

.call-to-action-1:hover {
  background-color: #1ecd6d;
}

.call-to-action-1 span {
  display: block;
}

@media only screen and (min-width: 768px) {
  .call-to-action-2 .inline {
    display: block;
  }
  .call-to-action-2 p + .button {
    margin-left: 2rem;
  }
}

.contact-form-container .row,
.signup-form-container .row {
  margin-left: -1rem;
  margin-right: -1rem;
}

.call-to-action-3 {
  background-color: #ffbe12;
  color: #fff;
}

.call-to-action-3 .call-to-action-button {
  padding-right: 0;
  position: absolute;
  top: 0;
  right: 0;
}

.call-to-action-3 .call-to-action-button .button {
  padding: 2rem 0;
  width: 100%;
  height: 100%;
  margin-bottom: 0;
  font-size: 1.5rem;
  font-weight: 300;
  text-align: center;
  text-transform: uppercase;
  border-radius: 0;
  display: table;
}

.call-to-action-3 .call-to-action-button > .button > span {
  height: 100%;
  display: table-cell;
  vertical-align: middle;
}

@media only screen and (max-width: 960px) {
  .call-to-action-1,
  .call-to-action-2 p,
  .call-to-action-3 p {
    font-size: 1.9rem;
    line-height: 2.9rem;
  }
}

@media only screen and (max-width: 768px) {
  [class*="call-to-action"] {
    height: auto;
  }
  .call-to-action-2 .call-to-action-content-inner,
  .call-to-action-3 .call-to-action-content-inner {
    padding-bottom: 2.5rem;
    text-align: center;
  }
  .call-to-action-3 .row {
    max-width: 100%;
  }
  .call-to-action-3 .call-to-action-button {
    position: static;
    padding-left: 0;
    padding-right: 0;
  }
}

.contact-form-container [class*="width-"],
.signup-form-container [class*="width-"] {
  padding-left: 1rem;
  padding-right: 1rem;
}

.form-element[class*="border-"] {
  background: #fff;
}

.form-honeypot {
  display: none;
}

.contact-form-container {
  position: relative;
  margin-bottom: 3rem;
}

.contact-form-container .contact-form {
  position: relative;
}

.contact-form-container .form-element {
  margin-bottom: 2rem;
}

.contact-form-container .form-response {
  font-size: 1.2rem;
  line-height: 1.3;
  color: #666;
  position: absolute;
  bottom: 0;
}

.contact-form-container input[type="submit"] {
  margin-bottom: 0;
}

.contact-1 h2 {
  margin-bottom: 3rem;
}

.contact-1 .contact-form-container {
  margin-bottom: 0;
}

.contact-1 .contact-form-container .form-response,
.contact-2 .contact-form-container .form-response {
  bottom: -3rem;
}

.signup-form-container {
  position: relative;
  margin-bottom: 3rem;
}

.signup-form-container .form-element,
.signup-form-container input[type="submit"] {
  margin-bottom: 2rem;
}

.signup-form-container .signup-form {
  position: relative;
}

.signup-form-container .form-response {
  font-size: 1.2rem;
  line-height: 1.3;
  position: absolute;
  bottom: 0;
  -ms-word-break: break-all;
  word-break: break-word;
}

.team-3 .social-list a:hover,
.team-4 .thumbnail .social-list a:hover {
  opacity: 0.5;
}

.signup-form-container.boxed {
  padding: 4rem;
}

.signup-1 .signup-form-container {
  margin-bottom: 0;
}

.signup-1 .signup-form-container .form-response {
  bottom: -1.3rem;
}

/* .signup-2 {
  background-image: url(../images/slider/slide-5-fw.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  background-size: cover;
} */

.signup-2 .signup-form-container {
  padding: 4rem;
  margin-bottom: 0;
  background: rgba(0, 0, 0, 0.5);
}

.login-form-container [class*="width-"],
.search-form-container [class*="width-"] {
  padding-left: 1rem;
  padding-right: 1rem;
}

.signup-2 .signup-form-container .form-response {
  bottom: 2.5rem;
}

.signup-1 .form-submit,
.signup-2 .form-submit {
  width: auto;
}

/* .signup-3 {
  background-image: url(../images/slider/slide-5-fw.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  background-size: cover;
} */

.signup-3 .signup-inner {
  background: rgba(0, 0, 0, 0);
}

.signup-3 .row {
  text-align: center;
}

.signup-3 .signup-form-container {
  width: 30rem;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
}

.login-form-container .row,
.search-form-container .row {
  margin-left: -1rem;
  margin-right: -1rem;
}

.signup-3 .form-element {
  width: 100%;
}

.signup-3 .signup-form-container .form-response {
  width: 100%;
  bottom: -1.3rem;
}

.team-3 .team-slider .tms-pagination,
.testimonial-slider .tms-pagination {
  bottom: 0;
}

.search-form-container input[type="submit"] {
  margin-bottom: 0;
}

.search-form-container .form-element {
  margin-bottom: 2rem;
}

.login-form-container input[type="submit"] {
  margin-bottom: 0;
}

.login-form-container .form-element {
  margin-bottom: 2rem;
}

.login-form-container .recuperate-password {
  font-size: 1rem;
  display: block;
}

.testimonial-1 h6,
.testimonial-2 h6,
.testimonial-3 h6,
.testimonial-4 h6 {
  font-size: 1.3rem;
  text-transform: uppercase;
}

@media only screen and (max-width: 768px) {
  .signup-form-container input[type="submit"] {
    width: auto;
  }
}

@media only screen and (max-width: 480px) {
  .signup-3 .signup-form-container {
    width: 100%;
  }
}

/* @media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (min-device-pixel-ratio: 2),
  only screen and (min-resolution: 192dpi),
  only screen and (min-resolution: 2dppx) {
  .signup-2 {
    background-image: url(../images/slider/slide-5-fw@2x.jpg);
  }
} */

.testimonial-1 h6 {
  margin-bottom: 4rem;
}

.testimonial-1 blockquote {
  width: 70%;
  padding: 0;
  margin-bottom: 0;
}

.testimonial-1 blockquote.avatar span,
.testimonial-1 blockquote.avatar span img {
  width: 9rem;
  height: 9rem;
}

.testimonial-1 p {
  margin-top: 3rem;
}

.testimonial-1 cite {
  text-transform: uppercase;
  font-weight: 700;
  line-height: 1;
}

.testimonial-2 h6 {
  margin-bottom: 1rem;
}

.testimonial-2 .brief {
  height: 100%;
  border-right: 1px solid #ddd;
  display: table;
}

.testimonial-2 .brief p:last-child {
  margin-bottom: 0;
}

.testimonial-2 .brief-inner {
  display: table-cell;
  vertical-align: middle;
}

.testimonial-2 blockquote {
  width: 100%;
  padding: 0;
  margin-bottom: 3rem;
}

.testimonial-2 blockquote:last-child {
  margin-bottom: 0;
}

.testimonial-2 cite {
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
}

.testimonial-3 [class*="content-grid"] {
  margin-bottom: -3rem;
}

.testimonial-3 h6 {
  margin-bottom: 4rem;
}

.testimonial-3 blockquote {
  width: 100%;
  padding: 0;
  margin: 0;
}

.testimonial-3 cite {
  text-transform: uppercase;
  letter-spacing: 0.1rem;
}

.testimonial-4 [class*="content-grid"] {
  margin-bottom: -3rem;
}

.testimonial-4 h6 {
  margin-bottom: 4rem;
}

.testimonial-4 blockquote {
  height: 100%;
  padding: 2.5rem;
  margin: 0;
  border-radius: 0.3rem;
  border: 1px solid #ddd;
}

.testimonial-4 cite {
  text-transform: uppercase;
  letter-spacing: 0.1rem;
}

/* .testimonial-5 {
  color: #fff;
  background-image: url(../images/slider/slide-2-fs-hp.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  background-size: cover;
  position: relative;
} */

.testimonial-5 blockquote {
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  float: none;
}

.testimonial-5 cite {
  text-transform: uppercase;
  font-weight: 700;
}

.testimonial-slider {
  width: 100% !important;
  min-height: 10rem;
  margin: 0 auto;
  background: 0 0;
}

.testimonial-slider.left,
.testimonial-slider.right {
  margin: 0;
}

.center .testimonial-slider blockquote,
.testimonial-slider blockquote {
  width: 100%;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.testimonial-slider.left .tms-pagination,
.testimonial-slider.left blockquote {
  text-align: left;
}

.testimonial-slider.left blockquote {
  padding-left: 0;
  padding-right: 1rem;
}

.testimonial-slider.right .tms-pagination,
.testimonial-slider.right blockquote {
  text-align: right;
}

.testimonial-slider.right blockquote {
  padding-left: 1rem;
  padding-right: 0;
}

.testimonial-slider blockquote span {
  margin-top: 0;
  margin-bottom: 1.5rem;
}

@media only screen and (max-width: 960px) {
  .testimonial-1 p,
  .testimonial-5 .testimonial-slider p {
    font-size: 1.9rem;
  }
}

.team-1 h6.occupation,
.team-2 h6.occupation {
  font-size: 1.2rem;
  color: #999;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
}

@media only screen and (max-width: 768px) {
  .testimonial-2 .brief {
    border-right: none;
  }
  .testimonial-2 .brief h6:last-child,
  .testimonial-2 .brief p:last-child {
    margin-bottom: 3rem;
  }
  .testimonial-3 blockquote {
    height: 100%;
    padding-bottom: 3rem;
    border-bottom: 1px solid #ddd;
  }
  .testimonial-3 .grid-item:last-child blockquote {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: none;
  }
  .testimonial-slider.center-on-mobile {
    margin: 0 auto;
  }
  .testimonial-slider.center-on-mobile .tms-pagination,
  .testimonial-slider.center-on-mobile blockquote {
    text-align: center;
  }
  .testimonial-slider.center-on-mobile blockquote {
    padding: 0 !important;
  }
}

/* @media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (min-device-pixel-ratio: 2),
  only screen and (min-resolution: 192dpi),
  only screen and (min-resolution: 2dppx) {
  .testimonial-5 {
    background-image: url(../images/slider/slide-2-fs-hp@2x.jpg);
  }
} */

[class*="team-"] .signature {
  width: 100%;
}

[class*="team-"] .thumbnail {
  float: none;
}

.social-list a {
  margin-right: 1rem;
}

.social-list:not(.center) li:last-child a {
  margin-right: 0;
}

.right .social-list a,
.social-list.right a {
  margin-left: 1rem;
  margin-right: 0;
}

.social-list.right li:last-child a {
  margin-left: 0;
}

.center .social-list a,
.center .social-list li:last-child a,
.social-list.center a {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}

.social-list [class*="icon-"] {
  margin: 0;
}

.team-slider .social-list,
.team-slider .social-list li {
  padding: 0;
  margin: 0;
}

.team-1 [class*="content-grid"] {
  margin-bottom: -3rem;
}

.team-1 .team-content-info h2 {
  margin-bottom: 3rem;
}

.team-1 .team-content-info h5 {
  margin-top: 2rem;
  margin-bottom: 0;
}

.team-1 h6.occupation {
  margin-top: 0;
  margin-bottom: 2rem;
}

.team-2 [class*="content-grid"] {
  margin-bottom: -3rem;
}

.team-2 .thumbnail {
  margin-bottom: 1rem;
}

.team-2 .team-content {
  background: #fff;
}

.team-2 .team-content-info {
  padding: 2rem;
}

.team-2 .team-content-info > :first-child {
  margin-top: 0;
}

.team-2 .team-content-info h2 {
  margin-bottom: 3rem;
}

.team-2 .team-content-info h5 {
  margin-top: 1rem;
  margin-bottom: 0;
}

.team-2 h6.occupation {
  margin-top: 0;
  margin-bottom: 2rem;
}

.team-2 .social-list .list-label {
  margin-right: 1rem;
  font-size: 1rem;
  font-weight: 700;
  color: #999;
  text-transform: uppercase;
  display: inline-block;
}

.team-3,
.team-3 .team-content-info {
  color: #fff;
}

.team-2 .social-list.boxed {
  margin: 0 -2rem -2rem;
  padding: 2rem;
  background: #e7e7e7;
}

/* .team-3 {
  background-image: url(../images/team/page-01-bg.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  background-size: cover;
  position: relative;
  text-align: left;
} */

.team-3 .slider-column,
body.boxed .team-3 {
  padding-left: 0;
  padding-right: 0;
}

.team-3 .team-slider .tms-pagination,
.team-3 .team-slider li {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.team-3 .team-slider {
  height: 45rem;
  margin-bottom: 0;
  background: 0 0;
}

.team-3 .team-slider .tms-slides {
  margin-bottom: 2rem;
}

.team-3 .team-content {
  margin-bottom: 4rem;
}

.team-3 .team-content-info h2 {
  margin-bottom: 3rem;
}

.team-3 .team-content-info h5 {
  margin-top: 2rem;
  margin-bottom: 0;
  color: #fff;
}

.team-3 h6.occupation {
  margin-top: 0;
  margin-bottom: 2rem;
  text-transform: uppercase;
  font-size: 1.2rem;
  color: #999;
  letter-spacing: 0.1rem;
}

.team-3 .social-list a {
  color: #fff;
}

.section-block.team-4 {
  padding-bottom: 0;
}

.team-4 {
  text-align: center;
  overflow: hidden;
}

.team-4 .grid-item {
  margin-bottom: 0;
}

.team-4 .overlay-info > span {
  padding: 3rem;
  font-size: 1.4rem;
  line-height: 1.8;
  font-weight: 400;
}

.team-4 .team-content-info h5 {
  margin-top: 2rem;
  margin-bottom: 0;
}

.team-4 h6.occupation {
  margin-top: 0;
  margin-bottom: 2rem;
  text-transform: uppercase;
  font-size: 1.2rem;
  color: #999;
  letter-spacing: 0.1rem;
}

.team-4 .social-list {
  display: block;
  margin-top: 2rem;
}

.team-4 .thumbnail .social-list a {
  margin: 0 0.5rem;
  color: #fff;
}

.masonry-set-dimensions-2 h5:last-child,
.masonry-set-dimensions-2 p:last-child,
.recent-slider .thumbnail {
  margin-bottom: 0;
}

.mobile .team-4 .overlay-info {
  height: auto;
  background: 0 0;
  position: relative;
  color: #999;
  transition: none;
  opacity: 1;
  display: block;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.mobile .team-4 .overlay-info > span {
  padding-left: 1rem;
  padding-right: 1rem;
}

.mobile .team-4 .social-list a {
  color: #999;
}

@media only screen and (min-width: 768px) {
  .team-4 .thumbnail {
    margin-bottom: 0;
  }
}

@media only screen and (min-width: 479px) and (max-width: 960px) {
  .team-2 .content-grid-4 .grid-item,
  .team-4 .content-grid-4 .grid-item {
    width: 50%;
  }
}

.masonry-set-dimensions-2 .thumbnail {
  float: left;
}

.masonry-set-dimensions-2 .description {
  padding: 2rem;
  text-align: center;
  background: #fff;
}

.masonry-set-dimensions-2 .half.image-left .thumbnail,
.masonry-set-dimensions-2 .half.image-right .thumbnail {
  width: 50%;
}

.masonry-set-dimensions-2 .two-third.image-left .thumbnail,
.masonry-set-dimensions-2 .two-third.image-right .thumbnail {
  width: 66.66667%;
}

.masonry-set-dimensions-2 .half.image-bottom .thumbnail,
.masonry-set-dimensions-2 .half.image-top .thumbnail,
.masonry-set-dimensions-2 .image-left.no-description .thumbnail,
.masonry-set-dimensions-2 .image-right.no-description .thumbnail,
.masonry-set-dimensions-2 .two-third.image-bottom .thumbnail,
.masonry-set-dimensions-2 .two-third.image-top .thumbnail {
  width: 100%;
}

.masonry-set-dimensions-2 .half.image-right .thumbnail,
.masonry-set-dimensions-2 .two-third.image-right .thumbnail {
  float: right;
}

.masonry-set-dimensions-2 .item-description {
  margin-top: 0;
  height: 100%;
  background: #f9f9f9;
  text-align: center;
  display: table;
  position: relative;
}

.masonry-set-dimensions-2 .item-description-inner {
  height: 100%;
  padding: 1rem 3rem 3rem;
  display: table-cell;
  vertical-align: middle;
}

.masonry-set-dimensions-2 .half.image-left .item-description {
  width: 50%;
  text-align: left;
  float: right;
}

.masonry-set-dimensions-2 .half.image-right .item-description {
  width: 50%;
  text-align: right;
  float: left;
}

.masonry-set-dimensions-2 .two-third.image-left .item-description {
  width: 33.33333%;
  text-align: left;
  float: right;
}

.masonry-set-dimensions-2 .two-third.image-right .item-description {
  width: 33.33333%;
  text-align: right;
  float: left;
}

.masonry-set-dimensions-2 .half.image-bottom .item-description,
.masonry-set-dimensions-2 .half.image-top .item-description {
  width: 100%;
  height: 50%;
  text-align: center;
}

.masonry-set-dimensions-2 .two-third.image-top .item-description {
  width: 100%;
  height: 33.33333%;
  text-align: center;
}

.masonry-set-dimensions-2 .item-description:after {
  width: 0;
  height: 0;
  margin-top: -10px;
  content: " ";
  position: absolute;
  pointer-events: none;
  z-index: 10;
  margin-left: -10px;
  border: solid transparent;
  border-bottom-color: #f9f9f9;
  border-width: 10px;
  top: auto;
  bottom: 100%;
  left: 50%;
}

.masonry-set-dimensions-2 .image-left .item-description:after {
  margin-top: -10px;
  border: solid transparent;
  border-right-color: #f9f9f9;
  border-width: 10px;
  top: 50%;
  right: 100%;
  bottom: auto;
  left: auto;
}

.masonry-set-dimensions-2 .image-right .item-description:after {
  margin-top: -10px;
  border: solid transparent;
  border-left-color: #f9f9f9;
  border-width: 10px;
  top: 50%;
  bottom: auto;
  right: -20px;
  left: auto;
}

.masonry-set-dimensions-2 .image-bottom .item-description:after {
  margin-left: -10px;
  border: solid transparent;
  border-top-color: #f9f9f9;
  border-width: 10px;
  top: auto;
  bottom: -2rem;
  left: 50%;
}

.recent-slider .column.slider-column {
  padding-left: 0;
  padding-right: 0;
}

.recent-slider .recent-slider {
  padding: 0;
  margin-bottom: 0;
  background: 0 0;
}

.recent-slider .next-recent-slider,
.recent-slider .previous-recent-slider {
  width: 3rem;
  height: 3rem;
  margin-right: 0.5rem;
  line-height: 3rem;
  text-align: center;
  display: inline-block;
  border: 1px solid #ddd;
}

.recent-slider [class*="icon-"] {
  margin: 0;
}

.recent-slider .grid-description {
  width: 100%;
  height: 100%;
  padding: 0;
  display: block;
}

.portfolio-media,
.portfolio-media iframe,
.portfolio-media img,
.portfolio-media object {
  margin-bottom: 3rem;
}

.portfolio-media:last-child {
  margin-bottom: 0;
}

.project-details h6 {
  margin-bottom: 1rem;
}

.project-details ul {
  margin-bottom: 2rem;
}

@media only screen and (max-width: 960px) {
  .recent-slider .column {
    width: 100%;
  }
  .recent-slider .grid-description {
    margin-bottom: 2rem;
  }
}

@media only screen and (max-width: 768px) {
  .masonry-set-dimensions-2 .two-third.horizontal,
  .masonry-set-dimensions-2 .two-third.horizontal .thumbnail {
    width: 100% !important;
    height: auto !important;
  }
  .masonry-set-dimensions-2 .two-third.image-left .item-description,
  .masonry-set-dimensions-2 .two-third.image-right .item-description {
    width: 100%;
    float: left;
  }
  .masonry-set-dimensions-2 .two-third.image-left .item-description:after,
  .masonry-set-dimensions-2 .two-third.image-right .item-description:after {
    margin-left: -1rem;
    border: solid transparent;
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #fff;
    border-width: 10px;
    top: auto !important;
    bottom: 100%;
    left: 50%;
  }
}

@media only screen and (max-width: 600px) {
  .masonry-set-dimensions-2 .half.horizontal,
  .masonry-set-dimensions-2 .half.horizontal .thumbnail {
    width: 100% !important;
    height: auto !important;
  }
  .masonry-set-dimensions-2 .half.image-left .item-description,
  .masonry-set-dimensions-2 .half.image-right .item-description {
    width: 100%;
    float: left;
  }
  .masonry-set-dimensions-2 .image-left .item-description:after,
  .masonry-set-dimensions-2 .image-right .item-description:after {
    margin-left: -1rem;
    border: solid transparent;
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #fff;
    border-width: 10px;
    top: auto !important;
    bottom: 100%;
    left: 50%;
  }
}

.logos-2 [class*="content-grid"],
.logos-3 [class*="content-grid"] {
  margin-left: 0;
  margin-right: 0;
}

.logos-3 .grid-item:after,
.logos-4 .grid-item:after {
  border-bottom: 1px solid #ddd;
  bottom: -1px;
  width: 100%;
}

[class*="logos-"] .grid-item {
  margin-bottom: 0;
  text-align: center;
}

.logos-1 [class*="content-grid"] {
  margin-top: -2rem;
  margin-bottom: -2rem;
}

.logos-1 a,
.logos-1 span {
  padding: 2rem 0;
  display: block;
  opacity: 1;
}

.logos-1 a:hover {
  opacity: 0.5;
}

/* .logos-2 {
  background-image: url(../images/slider/slide-5-fw@2x.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  background-size: cover;
  position: relative;
} */

.logos-2 .grid-item {
  padding: 0 0 1px 1px;
}

.logos-2 a,
.logos-2 span {
  width: 100%;
  padding: 5rem 1.5rem;
  background-color: rgba(0, 0, 0, 0.4);
  color: #fff;
  opacity: 1;
  display: block;
}

.logos-2 a:hover {
  background-color: rgba(0, 0, 0, 0.6);
}

.logos-3 .grid-item {
  padding: 0;
  border-right: 1px solid #ddd;
  border-top: 1px solid #ddd;
  position: relative;
}

.logos-3 .grid-item:after,
.logos-3 .grid-item:before {
  content: "";
  position: absolute;
}

.logos-3 .grid-item:before {
  height: 100%;
  border-left: 1px solid #ddd;
  top: 0;
  left: -1px;
}

.logos-3 .grid-item:after {
  left: 0;
}

.logos-3 a,
.logos-3 span {
  width: 100%;
  padding: 5rem 1.5rem;
  opacity: 1;
  display: block;
}

.logos-3 a:hover {
  background-color: #ddd;
}

.logos-4 [class*="content-grid"] {
  margin-left: 0;
  margin-right: 0;
  overflow: hidden;
}

.logos-4 .grid-item {
  padding: 0;
  position: relative;
}

.logos-4 .grid-item:after,
.logos-4 .grid-item:before {
  content: "";
  position: absolute;
  z-index: 1;
}

.logos-4 .grid-item:before {
  height: 100%;
  border-left: 1px solid #ddd;
  top: 0;
  left: -1px;
}

.logos-4 .grid-item:after {
  left: 0;
}

.fullscreen-section,
.fullscreen-sections-wrapper {
  position: relative;
  z-index: 0;
}

.logos-4 a,
.logos-4 span {
  width: 100%;
  padding: 5rem 1.5rem;
  opacity: 1;
  display: block;
}

.logos-4 a:hover {
  opacity: 0.5;
}

.logo-slider {
  min-height: 10rem;
}

.logo-slider .tms-pagination {
  bottom: 0;
}

.logo-slider .tms-bullet-nav {
  background-color: #333;
}

.logo-slider .tms-content-scalable {
  padding-bottom: 0;
}

@media only screen and (max-width: 960px) {
  [class*="logos-"] {
    height: auto;
  }
  [class*="logos-"] .grid-item {
    width: 33.3333%;
  }
  .logos-2 a,
  .logos-2 span {
    padding: 4.5rem 1.5rem;
  }
}

@media only screen and (max-width: 768px) {
  [class*="logos-"] .grid-item {
    width: 33.3333%;
  }
  .logos-2 a,
  .logos-2 span,
  .logos-3 a,
  .logos-3 span,
  .logos-4 a,
  .logos-4 span {
    padding: 3rem 1.5rem;
  }
}

@media only screen and (max-width: 480px) {
  [class*="logos-"] .grid-item {
    width: 50%;
  }
  .logos-2 a,
  .logos-2 span,
  .logos-3 a,
  .logos-3 span,
  .logos-4 a,
  .logos-4 span {
    padding: 2rem 1.5rem;
  }
}

/* @media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (min-device-pixel-ratio: 2),
  only screen and (min-resolution: 192dpi),
  only screen and (min-resolution: 2dppx) {
  .logos-2 {
    background-image: url(../images/slider/slide-2-fs-hp@2x.jpg);
  }
} */

.clients-1 {
  line-height: 1;
}

.clients-1 .client-name {
  margin-top: 0;
  margin-bottom: 1rem;
}

.clients-1 .client-description {
  font-size: 1.1rem;
  opacity: 0.7;
}

@media only screen and (max-width: 768px) {
  [class*="clients-"] .grid-item {
    width: 33.3333%;
  }
  [class*="clients-"] .client-description {
    display: none;
  }
  [class*="clients-"] .client-name {
    font-size: 1.4rem;
  }
}

@media only screen and (max-width: 480px) {
  [class*="clients-"] .grid-item {
    width: 50%;
  }
}

.social-1 {
  text-align: center;
}

.social-1 h6 {
  font-size: 1.3rem;
  text-transform: uppercase;
  margin-bottom: 1.5rem;
}

.social-1 ul {
  margin-bottom: 0;
}

.social-1 ul li {
  padding: 0 1rem;
  margin-bottom: 1rem;
  display: inline-block;
}

.social-2 {
  padding-top: 0;
  padding-bottom: 0;
  text-align: center;
}

body.boxed .social-2 {
  padding-left: 0;
  padding-right: 0;
}

.safari-browser .social-2 {
  width: 100.4%;
}

.social-2 [class*="content-grid-"] {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.social-2.full-width [class*="content-grid-"] {
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
}

.social-2 .grid-item {
  padding-right: 0;
  padding-left: 0;
  margin-bottom: 0;
}

.social-3 ul li,
.social-4 ul li {
  padding: 0 1rem;
  display: inline-block;
}

.social-2 a {
  width: 100%;
  height: 15rem;
  margin: 0;
  font-size: 2rem;
  line-height: 2rem;
  font-family: "Times New Roman", serif;
  font-style: italic;
  display: table;
}

.social-2 a:hover {
  background: 0 0;
  color: #999;
}

.social-2 a span {
  display: table-cell;
  vertical-align: middle;
}

.social-2 [class*="icon"] {
  width: 100%;
  margin: 0 0 1rem;
  font-size: 3rem;
  display: inline-block;
}

/* .social-3 {
  text-align: center;
  background-image: url(../images/slider/slide-2-fs-hp@2x.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  background-size: cover;
  position: relative;
} */

.social-3 ul {
  margin-bottom: -1rem;
  line-height: 1;
}

.social-3 ul li {
  margin-bottom: 1rem;
  text-align: center;
}

.social-3 a[class*="icon"] {
  width: 5rem;
  height: 5rem;
  margin: 0;
  line-height: 5rem;
  font-weight: 400;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
}

.social-3 a {
  color: #fff;
}

.social-3 a:hover {
  background: #fff;
}

.social-4 {
  text-align: center;
}

.social-4 h6 {
  font-size: 1.3rem;
  text-transform: uppercase;
  margin-bottom: 1.5rem;
}

.social-4 ul {
  margin-bottom: -1rem;
}

.social-4 ul li {
  margin: 0 2rem 1rem;
}

.social-4 ul li a {
  font-size: 1.7rem;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-weight: 700;
  color: #999;
}

@media only screen and (max-width: 480px) {
  .social-2 ul li {
    width: 50%;
  }
  .social-2 a {
    width: 100%;
  }
  .social-4 ul li {
    display: block;
  }
}

[class*="stats-"] > .row:not(.flex),
[class*="stats-"] > .row:not(.flex) > .column {
  height: 100%;
}

[class*="stats-"] .stat-content {
  width: 100%;
  height: 100%;
  display: table;
}

[class*="stats-"] .stat-content-inner {
  height: 100%;
  display: table-cell;
  vertical-align: middle;
}

[class*="stats-"] .stat {
  width: 100%;
  height: 100%;
  display: table;
}

[class*="stats-"] .stat-inner {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

[class*="stats-"].left .stat-inner {
  text-align: left;
}

[class*="stats-"].center .stat-inner {
  text-align: center;
}

[class*="stats-"].right .stat-inner {
  text-align: right;
}

/* .stats-1 {
  color: #fff;
  background-image: url(../images/slider/slide-2-fs-hp@2x.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  background-size: cover;
  position: relative;
} */

.stats-1 [class*="content-grid"] {
  margin-bottom: -3rem;
}

.stats-1 .counter {
  font-size: 6rem;
  line-height: 1;
  font-weight: 400;
}

.stats-1 p:last-child {
  margin-bottom: 0;
}

.stats-2 {
  color: #666;
}

.stats-2 [class*="content-grid"] {
  margin-bottom: -2rem;
}

.stats-2 .grid-item {
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin-bottom: 0;
  border-right: 1px solid #ddd;
}

.stats-2 .grid-item:last-child {
  border: none;
}

.stats-2 .counter {
  margin-bottom: 0.5rem;
  font-size: 3rem;
  line-height: 1;
  font-weight: 700;
}

.stats-2 .description {
  font-size: 1.2rem;
  text-transform: uppercase;
}

.stats-2 p:last-child {
  margin-bottom: 0;
}

.stats-3 [class*="content-grid"] {
  margin-top: -2rem;
  margin-bottom: -2rem;
}

.stats-3 .grid-item {
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin-bottom: 0;
  border-right: 1px dotted #ddd;
}

.stats-3 .counter,
.stats-3 .mega-stat .counter {
  margin-bottom: 0.5rem;
  line-height: 1;
  font-weight: 700;
}

.stats-3 .grid-item:last-child {
  border: none;
}

.stats-3 .counter {
  font-size: 3rem;
}

.stats-3 .mega-stat .counter {
  font-size: 7rem;
}

.stats-3 .description {
  font-size: 1.2rem;
  text-transform: uppercase;
  color: #999;
}

.stats-3 .mega-stat .description {
  margin-bottom: 0;
  font-size: 2rem;
  line-height: 1;
  font-weight: 700;
}

.stats-3 .description {
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px dotted #ddd;
  font-weight: 700;
}

.stats-3 .description:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border: none;
}

.stats-3 p:last-child {
  margin-bottom: 0;
}

.stats-3[class*="border-"] {
  border-width: 0;
}

.stats-3[class*="border-"] * {
  border-color: inherit;
}

@media only screen and (max-width: 1023px) {
  .stats-3 .mega-stat .counter {
    font-size: 5rem;
  }
}

@media only screen and (max-width: 768px) {
  .stats-2 .grid-item,
  .stats-2 .row-1 {
    border-bottom: 1px solid #ddd;
  }
  [class*="stats-"] {
    height: auto !important;
  }
  .stats-2 .grid-item {
    border-right: none;
  }
  .stats-3 .description,
  .stats-3 .grid-item.row-1 {
    border-bottom: 1px dotted #ddd;
  }
  .stats-2 .row-1:nth-of-type(even),
  .stats-2 .row-2:nth-of-type(even) {
    border-right: none !important;
  }
  .stats-3 .grid-item,
  .stats-3 .grid-item.row-1 {
    border-right: none;
  }
  .stats-3 .row-1:nth-of-type(even),
  .stats-3 .row-2:nth-of-type(even) {
    border-right: none !important;
  }
  [class*="stats-"].center-on-mobile .stat-inner {
    text-align: center;
  }
}

@media only screen and (max-width: 480px) {
  .stats-3 .counter,
  .stats-3 .mega-stat .counter {
    font-size: 4rem;
  }
  .stats-3 .mega-stat .description {
    font-size: 1.2rem;
  }
  .stats-3 .grid-item.row-1 {
    border: none;
  }
  .stats-3 .description {
    margin-bottom: 4rem;
    padding-bottom: 0;
    border: none;
  }
}

/* @media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (min-device-pixel-ratio: 2),
  only screen and (min-resolution: 192dpi),
  only screen and (min-resolution: 2dppx) {
  .social-3,
  .stats-1 {
    background-image: url(../images/slider/slide-2-fs-hp@2x.jpg);
  }
} */

.section-block.fullscreen-section,
.section-block.fullscreen-sections-wrapper {
  padding-top: 0;
  padding-bottom: 0;
}

.fullscreen-section {
  width: 100%;
  height: 100%;
  height: 100vh;
  display: table;
  table-layout: fixed;
  overflow: hidden;
}

body.boxed .fullscreen-section {
  padding-left: 0;
  padding-right: 0;
}

.fullscreen-section.in-view {
  z-index: 1;
}

.fullscreen-section .background-image {
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: 0;
}

.fullscreen-section .background-slider-wrapper,
.fullscreen-section.background-fixed .background-image {
  width: 100%;
  height: 100vh;
  position: absolute;
  background-attachment: fixed;
  z-index: 0;
}

.fullscreen-section .background-slider-wrapper {
  height: 100%;
}

.fullscreen-section.background-fixed .background-slider-wrapper {
  position: fixed;
}

.webkit .fullscreen-section .background-image-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  -webkit-backface-visibility: hidden;
  top: 0;
  left: 0;
}

.webkit .fullscreen-section.background-fixed .background-image-wrapper {
  position: fixed;
}

.webkit .fullscreen-section.background-fixed .background-image {
  position: relative;
  background-attachment: scroll;
}

.ie-browser.webkit .fullscreen-section.background-fixed .background-image-wrapper {
  height: 100vh;
  position: absolute !important;
}

.ie-browser.webkit .fullscreen-section.background-fixed .background-image {
  background-attachment: fixed;
}

.webkit.side-nav-open .background-fixed .background-image-wrapper {
  position: absolute;
}

.mobile .fullscreen-section {
  height: auto;
}

.mobile .fullscreen-section .background-image,
.mobile .fullscreen-section .background-image-wrapper,
.mobile .fullscreen-section.background-fixed .background-image-wrapper {
  width: 100vw;
  position: relative !important;
}

.fs-pagination {
  width: 2.8rem;
  padding: 1rem;
  text-align: center;
  visibility: visible;
  opacity: 0;
  position: fixed;
  right: 25px;
  top: 50%;
  z-index: 10;
  transition-property: opacity, visibility;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
  -webkit-backface-visibility: hidden;
}

.fs-bullet-nav {
  width: 0.8rem;
  height: 0.8rem;
  display: inline-block;
  background: #fff;
  opacity: 0.5;
  border-radius: 50%;
}

.fullscreen-sections-wrapper.nav-dark .fs-bullet-nav,
.nav-dark .fs-bullet-nav {
  background: #333;
}

.fs-bullet-nav.active,
.fs-bullet-nav:hover {
  opacity: 1;
}

.footer .footer-logo a:hover,
.shop .product-price del {
  opacity: 0.6;
}

.mobile .fs-pagination {
  display: none;
}

.fullscreen-section .fullscreen-inner {
  width: 100%;
  height: 100%;
  padding-top: 7rem;
  padding-bottom: 7rem;
  background: rgba(0, 0, 0, 0.6);
  display: table-cell;
  vertical-align: middle;
  position: relative;
  z-index: 2;
}

.fullscreen-section.no-overlay .fullscreen-inner {
  background: rgba(0, 0, 0, 0);
}

/* .coming-soon-1 .background-image,
.coming-soon-2 .background-image,
.error-404 .background-image,
.error-500 .background-image {
  background-image: url(../images/fullscreen/coming-soon-1.jpg);
} */

body.boxed .fullscreen-section .fullscreen-inner,
body.boxed .fullscreen-sections-wrapper .fullscreen-inner {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.fullscreen-section footer {
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  position: absolute;
  bottom: 0;
  z-index: 3;
}

.fullscreen-section .copyright {
  margin-bottom: 0;
  font-size: 1.1rem;
  font-weight: 400;
  letter-spacing: 0.1rem;
  color: #fff;
}

.fullscreen-section .social-list {
  margin-bottom: 0;
}

.fullscreen-inner.v-align-top {
  vertical-align: top;
}

.fullscreen-inner.v-align-bottom {
  vertical-align: bottom;
}

.fullscreen-section .hero-4,
.fullscreen-section .hero-5,
.fullscreen-section .hero-6 {
  height: 100vh;
}

.coming-soon-2 h1 {
  font-size: 10rem;
  line-height: 1;
}

.error-404 h1,
.error-500 h1 {
  font-size: 15rem;
  line-height: 1;
}

.error-404 .search-form-container {
  max-width: 60rem;
  margin-left: auto;
  margin-right: auto;
}

.error-404 .form-submit {
  width: 100%;
}

.error-500 .button {
  margin-right: 2rem;
}

.error-500 .button:last-child {
  margin-right: 0;
}

[class*="error-"] .background-image,
[class*="coming-soon-"] .background-image {
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  background-size: cover;
}

@media only screen and (max-width: 768px) {
  .fs-pagination {
    display: none;
  }
  .fullscreen-section footer {
    position: relative;
  }
  .fullscreen-section
    .column:last-child
    > :last-child:not(.hero-content):not(input[type="submit"]) {
    margin-bottom: 0;
  }
  .fullscreen-section.content-below-on-mobile {
    height: auto;
    display: block;
  }
  .fullscreen-section.content-below-on-mobile .fullscreen-inner {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
    background-color: transparent;
    display: block;
    text-align: left;
  }
  .fullscreen-section.no-overlay.content-below-on-mobile .background-on-mobile {
    padding: 0;
    margin-bottom: 0;
    background-color: #f4f4f4;
  }
  .fullscreen-section .background-on-mobile *,
  .fullscreen-section.no-overlay.content-below-on-mobile * {
    color: #666;
  }
  .fullscreen-section.content-below-on-mobile .alt-color-on-mobile {
    color: #fff;
  }
  .fullscreen-section .background-image,
  .fullscreen-section .background-image-wrapper,
  .fullscreen-section.background-fixed .background-image,
  .fullscreen-section.background-fixed .background-image-wrapper,
  .webkit .fullscreen-section .background-image-wrapper,
  .webkit .fullscreen-section.background-fixed .background-image,
  .webkit .fullscreen-section.background-fixed .background-image-wrapper {
    max-width: 100vw;
    background-attachment: scroll;
  }
  .content-below-on-mobile .background-image,
  .content-below-on-mobile .background-image-wrapper,
  .content-below-on-mobile .background-slider-wrapper,
  .fs-image-scale .background-image,
  .fs-image-scale .background-image-wrapper {
    position: relative !important;
  }
  .fullscreen-section.background-contain .background-image {
    background-position: center !important;
    background-size: 100% !important;
  }
  .mobile .fullscreen-section .background-image {
    max-width: 114rem;
    height: inherit;
  }
  .mobile .fullscreen-section {
    margin-top: -1px;
  }
  .coming-soon-2 h1 {
    font-size: 6rem;
  }
  .error-404 .form-submit {
    width: auto;
  }
  .error-404 h1,
  .error-500 h1 {
    font-size: 10rem;
  }
}

.pagination-1 a.disabled,
.pagination-1 a.disabled:hover,
.pagination-2 a.disabled,
.pagination-2 a.disabled:hover {
  color: #ddd;
}

.mobile .fullscreen-section[class*="error-"],
.mobile .fullscreen-section[class*="coming-soon-"] {
  height: 100vh;
  display: table;
}

.mobile [class*="error-"] .fullscreen-inner,
.mobile [class*="coming-soon-"] .fullscreen-inner {
  display: table-cell;
}

.mobile .fullscreen-section[class*="error-"] .background-image-wrapper,
.mobile .fullscreen-section[class*="coming-soon-"] .background-image-wrapper {
  position: fixed;
}

/* @media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (min-device-pixel-ratio: 2),
  only screen and (min-resolution: 192dpi),
  only screen and (min-resolution: 2dppx) {
  .coming-soon-1 .background-image,
  .coming-soon-2 .background-image,
  .error-404 .background-image,
  .error-500 .background-image {
    background-image: url(../images/fullscreen/coming-soon-1@2x.jpg);
  }
} */

.pagination-next.disabled,
.pagination-next.disabled:hover,
.pagination-previous.disabled,
.pagination-previous.disabled:hover {
  transition: none;
  cursor: default;
  background: 0 0 !important;
}

.pagination-1 small {
  font-size: 1.3rem;
  display: block;
}

.pagination-1 small span {
  font-size: 1.3rem;
  display: inline-block;
}

.pagination-1 [class*="icon-"] {
  height: 3.6rem;
  font-size: 3.6rem;
  display: inline-block;
}

.pagination-1 .pagination-previous {
  text-align: left;
}

.pagination-1 .pagination-previous [class*="icon-"] {
  margin-right: 1rem;
  float: left;
}

.pagination-1 .pagination-next [class*="icon-"],
.pagination-2 .pagination-previous [class*="icon-"] {
  margin-left: 1rem;
  float: right;
}

.pagination-1 .pagination-next {
  text-align: right;
}

.pagination-1 span {
  font-size: 2rem;
  font-weight: 700;
  display: block;
}

.pagination-1 .page-list,
.pagination-1 .return-to-index {
  margin-top: 0.3rem;
  margin-bottom: 0;
  font-size: 1.7rem;
  text-align: center;
  font-weight: 700;
}

.pagination-1 .page-list li {
  padding: 0 0.7rem;
}

.section-block.pagination-2 {
  padding-top: 0;
  padding-bottom: 0;
}

.pagination-2 .column,
.pagination-2 .column:hover {
  background-size: cover;
  background-position: center;
  -webkit-transform: translate(0, 0);
}

body.boxed .pagination-2 {
  padding-left: 0;
  padding-right: 0;
}

.pagination-2 .pagination-next,
.pagination-2 .pagination-previous {
  padding-top: 7rem;
  padding-bottom: 7rem;
  display: block;
  position: relative;
}

.pagination-2 .pagination-previous {
  padding-left: 3rem;
  padding-right: 5rem;
  background: #f9f9f9;
  text-align: right;
}

.pagination-2 .pagination-next {
  padding-right: 3rem;
  padding-left: 5rem;
  background: #eee;
  text-align: left;
}

.pagination-2 a:hover {
  background: red;
}

.pagination-2 small {
  font-size: 1.3rem;
  display: block;
}

.pagination-2 small span {
  font-size: 1.3rem;
  display: inline-block;
}

.pagination-2 [class*="icon-"] {
  height: 3.6rem;
  font-size: 3.6rem;
  display: inline-block;
}

.pagination-2 .pagination-next [class*="icon-"] {
  margin-right: 1rem;
  float: left;
}

.pagination-3 a,
.pagination-3 ul {
  margin: 0;
  text-align: center;
}

.pagination-2 span {
  font-size: 2rem;
  font-weight: 700;
  display: block;
}

.pagination-3 ul li {
  display: inline-block;
}

.pagination-3 a {
  width: 4rem;
  height: 4rem;
  padding: 1rem;
  display: block;
  border: 1px solid #ddd;
  border-radius: 50%;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.8rem;
}

.pagination-3 a.current,
.pagination-3 a:hover {
  background: #333;
  color: #fff;
  border: transparent;
}

.pagination-3 li:first-child {
  float: left;
}

.pagination-3 li:last-child {
  float: right;
}

.pagination-3 a.disabled,
.pagination-3 a.disabled:hover {
  color: #ddd;
  border-color: #ddd;
}

.pagination-4 {
  text-align: center;
}

.pagination-4 .button {
  width: auto;
  margin: 0 0.5rem;
  font-size: 1.6rem;
  font-weight: 700;
}

.pagination-4 .button.disabled,
.pagination-4 .button.disabled:hover {
  color: #ddd;
  background: 0 0;
  border-color: #ddd;
}

.pagination-4 .button [class*="icon-"]:before {
  margin: 0;
}

.pagination-4 .pagination-next [class*="icon-"],
.pagination-4 .pagination-previous [class*="icon-"] {
  display: none;
}

.pagination-5 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  border-top: 1px solid #ddd;
}

.pagination-5 ul {
  margin: 0;
  text-align: center;
}

.pagination-5 ul li {
  display: inline-block;
  margin-left: -0.4rem;
}

.pagination-5 a {
  width: 6rem;
  height: 6rem;
  color: #333;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 6rem;
  display: block;
}

.pagination-5 a.current {
  color: #999;
}

.pagination-5 a:hover {
  background: #333;
  color: #fff;
}

.pagination-5 li:first-child {
  float: left;
}

.pagination-5 li:last-child {
  float: right;
}

.pagination-5 a.disabled,
.pagination-5 a.disabled:hover {
  color: #ddd;
  border-color: #ddd;
}

.pagination-5 a.pagination-next,
.pagination-5 a.pagination-previous {
  width: auto;
  padding: 0 2rem;
  font-weight: 700;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}

.pagination-5 a.pagination-previous {
  padding-right: 3.5rem;
}

.pagination-5 a.pagination-next {
  padding-left: 3.5rem;
}

.pagination-5 a span {
  width: auto;
  float: left;
}

.pagination-5 a [class*="icon-"] {
  height: 100%;
  margin-right: 0;
  line-height: 6rem;
  font-size: 3.2rem;
}

@media only screen and (max-width: 960px) {
  .blog [class*="pagination-"] > .row > .column,
  .shop [class*="pagination-"] > .row > .column {
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .pagination-1 .page-list,
  .pagination-1 .pagination-next,
  .pagination-1 .return-to-index,
  .pagination-2 .pagination-previous {
    text-align: left;
  }
  .pagination-1 .page-list,
  .pagination-1 .pagination-previous,
  .pagination-1 .return-to-index {
    padding-bottom: 3.8rem;
    margin-bottom: 3.8rem;
    border-bottom: 1px solid #ddd;
    display: block;
  }
  .pagination-1 .pagination-previous [class*="icon-"] {
    margin-right: 0;
    float: right;
  }
  .pagination-1 .pagination-next [class*="icon-"] {
    margin-left: 0;
  }
  .pagination-2 [class*="pagination-"] {
    padding: 4rem 1.5rem;
  }
  .pagination-2 .pagination-previous:after {
    width: 10rem;
    height: 1rem;
    bottom: 0;
  }
  .pagination-2 .pagination-previous [class*="icon-"] {
    margin-left: 0;
    margin-right: 1rem;
    float: left;
  }
  .pagination-5 ul li a.pagination-next,
  .pagination-5 ul li a.pagination-previous {
    width: 6rem;
    padding: 0;
    text-align: center;
  }
  .pagination-5 ul li a span {
    float: none;
  }
  .pagination-5 ul li a.pagination-next span:first-child,
  .pagination-5 ul li a.pagination-previous span:last-child {
    display: none;
  }
}

@media only screen and (max-width: 490px) {
  .pagination-1,
  .pagination-3,
  .pagination-4 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .pagination-4 span {
    display: none;
  }
  .pagination-4 .back-to-index [class*="icon-"],
  .pagination-4 .pagination-next [class*="icon-"],
  .pagination-4 .pagination-previous [class*="icon-"] {
    display: block;
  }
  .pagination-3 ul li,
  .pagination-5 ul li {
    display: none;
  }
  .pagination-3 ul li:first-child,
  .pagination-3 ul li:last-child,
  .pagination-5 ul li:first-child,
  .pagination-5 ul li:last-child {
    display: inline-block;
  }
}

.section-block.map-wrapper {
  padding-top: 0;
  padding-bottom: 0;
}

.map-container {
  width: 100%;
  height: 40rem;
}

.map-container > div {
  width: 100%;
  height: 100%;
}

.map-container > div img {
  max-width: none;
}

.gm-style .gm-style-iw {
  padding: 0.3rem;
  color: #666;
  line-height: 1.5;
}

.footer {
  width: 100%;
  background-color: #222;
  position: relative;
  z-index: 1;
}

.footer .row.flex > .column {
  flex-direction: column;
}

body.boxed .footer .footer-bottom,
body.boxed .footer .footer-top {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.footer .footer-top {
  padding-top: 7rem;
  padding-bottom: 7rem;
}

.footer .widget {
  margin-bottom: 3rem;
}

.footer .footer-top-inner > :first-child,
.footer .widget:last-child > :first-child {
  margin-top: 0;
}

.footer .footer-top-inner > :last-child,
.footer .widget:last-child > :last-child {
  margin-bottom: 0;
}

.footer .footer-bottom {
  padding-bottom: 2rem;
  color: #555;
}

.footer .footer-bottom-inner {
  padding-top: 3rem;
  border-top: 1px solid #333;
}

.footer .footer-logo {
  width: 17rem;
  margin-bottom: 1rem;
  display: inline-block;
  line-height: 1;
}

.footer .footer-logo a {
  -webkit-transition-property: opacity, background, color, visibility, -webkit-transform;
  transition-property: opacity, background, color, visibility, transform;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
}

.footer .footer-bottom .copyright {
  font-size: 1.1rem;
  float: left;
}

.footer .navigation,
.footer .social-list {
  float: none;
}

.footer .navigation a {
  line-height: 2rem;
  text-transform: uppercase;
  background: 0 0;
  padding: 0 1rem;
}

.footer .navigation a:hover {
  background: 0 0;
  color: #fff;
}

.footer .navigation li:hover a {
  background: 0 0;
}

.footer .navigation li:first-child a {
  padding-left: 0;
  margin-left: 0;
}

.footer .navigation li:last-child a {
  padding-right: 0;
  margin-right: 0;
}

.footer .navigation li.current a,
.footer .navigation li.current a:hover {
  background: 0 0;
  color: #fff;
}

.footer .footer-bottom .navigation,
.footer .footer-bottom .social-list {
  float: right;
}

.footer .footer-bottom .copyright,
.footer .footer-bottom .navigation,
.footer .footer-bottom .social-list {
  margin-bottom: 1rem;
}

.mobile .footer-fixed {
  position: relative;
  bottom: auto;
}

@media only screen and (min-width: 960px) {
  .footer-fixed {
    position: fixed;
    bottom: 0;
    z-index: 0;
  }
  .content.reveal-footer {
    box-shadow: 0 2rem 5rem rgba(0, 0, 0, 0.5);
    position: relative;
    z-index: 1;
  }
}

@media only screen and (max-width: 960px) {
  .content.reveal-footer {
    margin-bottom: 0 !important;
  }
}

@media only screen and (max-width: 768px) {
  .footer-top > .row > .column {
    width: 100%;
  }
  [class*="footer-"] .footer-bottom .copyright,
  [class*="footer-"] .footer-bottom .navigation,
  [class*="footer-"] .footer-bottom .social-list {
    display: block;
    float: none;
  }
  .footer .navigation li {
    display: inline-block;
    float: none;
  }
}

.post {
  margin-bottom: 7rem;
}

.post:last-child {
  margin-bottom: 0 !important;
}

.post-content {
  position: relative;
  -webkit-transition-property: background, color;
  transition-property: background, color;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
}

.post-content + .post-media,
.post-media + .post-info-aside,
.post-media ~ .post-content {
  margin-top: 3rem;
}

.post-content.with-background > :first-child,
.post-info {
  margin-top: 0;
}

.blog-masonry.masonry-set-dimensions .post,
.blog-masonry.masonry-set-dimensions .post-media {
  height: 100%;
}

.blog-masonry.masonry-set-dimensions .post-content {
  width: 100%;
  height: auto;
  padding: 0;
  color: #fff;
  display: table;
  position: absolute;
  bottom: 0;
  z-index: 2;
}

.blog-masonry.masonry-set-dimensions .post-content:hover {
  background: rgba(0, 0, 0, 0.8);
}

.blog-masonry.masonry-set-dimensions .post-content-inner {
  height: 100%;
  padding: 2rem;
  display: table-cell;
  vertical-align: bottom;
}

.post-content > :last-child {
  margin-bottom: 0;
}

.post-content.with-background {
  padding: 3rem;
  margin-top: 0;
  background: #f9f9f9;
}

.blog-masonry.masonry-set-dimensions .post-content.with-background {
  background: rgba(0, 0, 0, 0.2);
}

.blog-masonry.masonry-set-dimensions .post-media:hover .post-content {
  background: rgba(0, 0, 0, 0.8);
}

.post-info {
  margin-bottom: 3rem;
}

.blog-masonry.masonry-set-dimensions .post-info {
  margin-bottom: 1rem;
}

.post-content .read-more,
.post-media > * {
  margin-bottom: 0;
}

.post-author-aside,
.post-comments-aside,
.post-info-aside {
  position: absolute;
  z-index: 1;
}

.post-author-aside + .author-bio,
.post-comments-aside + .comments-inner,
.post-info-aside + .post-content {
  padding-left: 10rem;
}

.post-info {
  padding: 0;
  margin-right: 0.2rem;
  font-size: 1.1rem;
  font-weight: 700;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  display: inline-block;
  border: none;
}

.post-author .author-avatar img,
.post-comments .user-avatar img {
  border-radius: 50%;
}

.post-info span:first-child {
  margin-left: 0;
}

.post-info span:last-child {
  margin-right: 0;
}

.post-info [class*="icon-"] {
  display: inline-block;
  position: relative;
  z-index: 1;
}

.post-info .post-love [class*="icon-"] {
  margin-right: 0.2rem;
}

.post-comments a [class*="icon-"],
.post-love a [class*="icon-"] {
  width: 1.2rem;
  text-align: center;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.2s;
  transform-transition-duration: 0.2s;
}

.post-comment a:hover [class*="icon-"],
.post-love a:hover [class*="icon-"] {
  -webkit-transform: scale3d(1.2, 1.2, 1);
  transform: scale3d(1.2, 1.2, 1);
}

.post-media {
  max-width: 114rem;
}

.post-media > * {
  float: none;
}

.blog-masonry.masonry-set-dimensions .post-media {
  position: relative;
  overflow: hidden;
}

.blog-masonry.masonry-set-dimensions.no-margins .post-media {
  position: static;
  overflow: hidden;
}

.blog-masonry .post-media .post-slider,
.blog-regular .post-media .post-slider,
.blog-single-post .post-media .post-slider {
  width: 82.5rem;
  height: 55rem;
}

.blog-wide .post-media .post-slider {
  width: 111rem;
  height: 74rem;
}

.post-media .mejs-audio,
.post-media .mejs-audio .mejs-button.mejs-mute,
.post-media .mejs-audio .mejs-button.mejs-pause,
.post-media .mejs-audio .mejs-button.mejs-play,
.post-media .mejs-audio .mejs-container .mejs-button,
.post-media .mejs-audio .mejs-controls,
.post-media .mejs-audio .mejs-controls div.mejs-horizontal-volume-slider,
.post-media .mejs-audio .mejs-controls div.mejs-time-rail {
  height: 4.9rem !important;
}

.post-media .mejs-audio .mejs-controls .mejs-time {
  margin-top: 1rem;
}

.post-media .mejs-audio .mejs-time-rail .mejs-time-total {
  margin-top: 2.1rem;
}

.post-media .mejs-audio .mejs-horizontal-volume-slider .mejs-horizontal-volume-current,
.post-media .mejs-audio .mejs-horizontal-volume-slider .mejs-horizontal-volume-total {
  top: 2.1rem;
}

.post-media .mejs-audio .mejs-button button {
  margin: 1.6rem 0.7rem;
}

.post-media .mejs-audio .mejs-button.mejs-pause,
.post-media .mejs-audio .mejs-button.mejs-play {
  width: 4.4rem !important;
}

.post-media .mejs-audio .mejs-button.mejs-pause button,
.post-media .mejs-audio .mejs-button.mejs-play button {
  margin: 1.6rem 1.4rem;
}

.blog-masonry.no-padding-top {
  padding-top: 1rem;
}

.blog-masonry.no-padding-bottom {
  padding-bottom: 0.5rem;
}

.blog-masonry.full-width.no-margins {
  padding: 0;
}

.blog-masonry [class*="content-grid"] {
  margin-bottom: -3rem;
}

.blog-masonry.masonry-set-dimensions [class*="content-grid"] {
  margin-bottom: -1.5rem;
}

.blog-masonry.no-margins [class*="content-grid"],
.blog-masonry.no-margins [class*="content-grid"] .grid-item {
  padding: 0 !important;
  margin: 0 !important;
}

.blog-masonry [class*="content-grid"],
.blog-masonry > .row > .column {
  padding-left: 0;
  padding-right: 0;
}

.blog-masonry [class*="content-grid"] {
  margin-left: 0;
  margin-right: 0;
}

.blog-masonry.full-width [class*="content-grid"] {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

body.boxed .blog-masonry.full-width [class*="content-grid"] {
  margin-left: 0;
  margin-right: 0;
}

.blog-masonry [class*="content-grid"] .grid-item {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.blog-masonry .grid-item .video,
.blog-masonry .grid-item .video-container {
  margin-bottom: 0;
}

.blog-masonry h2.post-title,
.blog-regular h2.post-title,
.blog-single-post h2.post-title,
.blog-wide h2.post-title {
  margin-top: 0;
  margin-bottom: 1rem;
}

.blog-masonry.small-margins > .row > .column {
  padding-left: 1rem;
  padding-right: 1rem;
}

.blog-masonry.small-margins.no-margins > .row > .column {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.blog-masonry.small-margins.full-width > .row > .column {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.blog-masonry.small-margins.no-margins.full-width > .row > .column {
  padding-left: 0;
  padding-right: 0;
}

.blog-masonry.small-margins.full-width [class*="content-grid"] {
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;
}

.blog-masonry.small-margins [class*="content-grid"] {
  padding-left: 0;
  padding-right: 0;
  margin-top: -0.5rem;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: -0.5rem;
}

.blog-masonry.small-margins [class*="content-grid"] .grid-item {
  margin-bottom: 0;
  padding: 0.5rem;
  position: relative;
}

.blog-masonry.no-margins > .row > .column {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.blog-masonry.full-width.no-margins > .row > .column {
  padding-left: 0;
  padding-right: 0;
}

.blog-masonry.full-width .row {
  max-width: 100%;
}

.sidebar.left .sidebar-inner {
  padding-right: 1rem;
}

.sidebar.right .sidebar-inner {
  padding-left: 1rem;
}

.sidebar.left .sidebar-inner,
.sidebar.right .sidebar-inner {
  text-align: left;
}

.sidebar .widget:first-child .widget-title {
  margin-top: 0;
}

.widget .post-info {
  display: block;
  margin-bottom: 0;
  line-height: 1;
}

@media only screen and (max-width: 1140px) {
  .blog-wide .post-media .post-slider {
    width: 93rem;
    height: 62rem;
  }
  .blog-regular .post-media .post-slider {
    width: 69rem;
    height: 46rem;
  }
  .blog-masonry [class*="content-grid"] .grid-item {
    width: 33.33333%;
  }
}

@media only screen and (max-width: 960px) {
  .blog-wide .post-media .post-slider {
    width: 73rem;
    height: 48.7rem;
  }
  .blog-regular .post-media .post-slider {
    width: 54rem;
    height: 36rem;
  }
  .sidebar .sidebar-inner {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .blog-masonry [class*="content-grid"] .grid-item {
    width: 50%;
  }
}

@media only screen and (max-width: 768px) {
  .blog-regular .post-media .post-slider,
  .blog-wide .post-media .post-slider {
    width: 57rem;
    height: 38rem;
  }
  .post-info .show-on-mobile a,
  .post-info [class*="icon-"],
  .post-info span.show-on-mobile {
    display: inline-block !important;
  }
  .post-author-aside,
  .post-comments-aside,
  .post-info-aside {
    display: none !important;
  }
  .post-author-aside + .author-bio,
  .post-comments-aside + .comments-inner,
  .post-info-aside + .post-content {
    padding-left: 0 !important;
  }
  .comment-list ul {
    margin-left: 0 !important;
  }
}

@media only screen and (max-width: 600px) {
  .blog-regular .post-media .post-slider,
  .blog-wide .post-media .post-slider {
    width: 45rem;
    height: 30rem;
  }
  .blog-masonry [class*="content-grid"] .grid-item {
    width: 100%;
  }
}

@media only screen and (max-width: 480px) {
  .blog-regular .post-media .post-slider,
  .blog-wide .post-media .post-slider {
    width: 32rem;
    height: 21.3rem;
  }
}

.single-post .post {
  margin-bottom: 0;
}

.single-post .post-content .post-media {
  margin-bottom: 3rem;
}

.single-post .post-content.with-background .post-media {
  margin-bottom: 0;
}

.single-post-tags {
  width: 100%;
  padding-top: 3rem;
  margin-top: 3rem;
  font-size: 1.2rem;
  border-top: 1px solid #ddd;
}

.single-post-tags .tags-title {
  font-weight: 700;
  margin-top: 0;
}

.single-post-tags a {
  margin-left: 0.8rem;
  margin-right: 0.8rem;
  display: inline-block;
  letter-spacing: 0.1rem;
}

.post-author,
.post-aux-info {
  padding-top: 3rem;
  margin-top: 3rem;
  border-top: 1px solid #ddd;
}

.post-author .author-avatar {
  max-width: 7rem;
  float: left;
}

.post-author.center .author-avatar {
  margin: 0 auto 2rem;
  float: none;
}

.post-author:not(.center) .author-bio .author-avatar + .author-content,
.post-comments .comment-content {
  margin-left: 10rem;
}

.post-comment-respond,
.post-comments {
  padding-top: 3rem;
  margin-top: 3rem;
  border-top: 1px solid #ddd;
}

.post-author .author-content:last-child {
  margin-bottom: 0;
}

.post-author .name {
  font-size: 1.6rem;
  margin-bottom: 0.6rem;
}

.post-comments .comments-title {
  margin-bottom: 3rem;
}

.post-comments .comment-list > li {
  margin-bottom: 4rem;
}

.post-comments .comment-list > li:last-child {
  margin-bottom: 0;
}

.post-comments .user-avatar {
  max-width: 7rem;
  float: left;
}

.post-comments .name {
  font-size: 1.6rem;
  margin-bottom: 0.6rem;
}

.post-comments .comment-meta {
  width: 100%;
  margin-bottom: 2rem;
  line-height: 1;
}

.post-comment-respond .reply-title,
.sidebar .widget {
  margin-bottom: 3rem;
}

.post-comments .comment-meta a {
  margin-left: 0.8rem;
  margin-right: 0.8rem;
  display: inline-block;
  font-size: 1.2rem;
  letter-spacing: 0.1rem;
  font-weight: 700;
}

.post-comments .comment-meta a:first-child {
  margin-left: 0;
}

.post-comment-respond .comment-form .column {
  float: none;
}

.sidebar .widget li {
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
}

@media only screen and (max-width: 960px) {
  .sidebar .sidebar-inner {
    padding-top: 3rem;
    margin-top: 3rem;
    border-top: 1px solid #ddd;
  }
}

[class^="title-"] {
  margin-bottom: 2rem;
}

.title-xlarge {
  font-size: 9rem;
  line-height: 9rem;
}

.title-large {
  font-size: 7rem;
  line-height: 7rem;
}

.title-medium {
  font-size: 5rem;
  line-height: 5rem;
}

.title-small {
  font-size: 3rem;
  line-height: 3rem;
}

.text-xlarge {
  font-size: 2.2rem;
}

.text-large {
  font-size: 1.8rem;
}

.text-medium {
  font-size: 1.5rem;
}

.text-small {
  font-size: 1.2rem;
}

.text-uppercase {
  text-transform: uppercase;
}

@media only screen and (max-width: 768px) {
  .title-xlarge {
    font-size: 4.5rem;
    line-height: 4.5rem;
  }
  .title-large {
    font-size: 4rem;
    line-height: 4rem;
  }
  .title-medium {
    font-size: 3rem;
    line-height: 3rem;
  }
  .title-small {
    font-size: 2rem;
    line-height: 2rem;
  }
  .text-xlarge {
    font-size: 2rem;
    line-height: 1.7;
  }
  .text-large {
    font-size: 1.6rem;
    line-height: 1.6;
  }
  .text-medium {
    font-size: 1.3rem;
    line-height: 1.5;
  }
  .text-small {
    font-size: 1.2rem;
    line-height: 1.3;
  }
}

.shop .product .onsale,
.shop .product .outofstock {
  width: auto;
  height: auto;
  padding: 0.3rem 0.8rem;
  background-color: #666;
  color: #fff;
  text-align: center;
  font-size: 1.2rem;
  line-height: 1.8;
  text-transform: uppercase;
  position: absolute;
  top: 1rem;
  left: 1rem;
  z-index: 100;
}

.shop .cart-overview .checkout [class*="icon-"] {
  margin-right: 0.2rem;
}

.shop .star-rating [class*="icon-"] {
  margin: 0;
}

.shop .cart-overview .product-remove a,
.shop .cart-overview a.product-remove {
  width: 1.6rem;
  height: 1.6rem;
  margin: 0;
  background-color: #eee;
  border-radius: 50%;
  font-size: 0.9rem;
  line-height: 1.7rem;
  text-align: center;
  color: #666;
}

.shop .quantity {
  max-width: 8rem;
}

.shop .cart-overview td,
.shop .cart-totals th,
.single-product .review-comments,
.widget .cart-subtotal {
  border-left: none;
  border-bottom: 1px solid #eee;
}

.shop .cart-overview .table,
.shop .cart-overview .table > thead {
  border-left: none;
  border-right: none;
}

.shop .cart-overview .table,
.shop .cart-overview .table > thead th {
  border-top: none;
  border-left: none;
}

.shop .cart-review .product-remove,
.shop .cart-review .product-subtotal {
  text-align: center;
}

.shop .cart-totals td {
  text-align: right;
}

.shop .cart-subtotal th {
  font-weight: 400;
}

.shop .cart-order-total {
  font-weight: 700;
}

.shop .grid-filter-options-inner {
  padding-top: 2rem;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}

.shop .grid-filter-options-inner .form-element {
  margin-bottom: 2rem;
}

.shop .products .product .product-details {
  margin-top: 2rem;
}

.shop .products .product .product-title {
  font-size: 1.4rem;
  margin-bottom: 0.5rem;
}

.shop .products .product .product-thumbnail .product-title {
  display: block;
  margin-bottom: 1rem;
}

.shop .products .product .product-description {
  margin-bottom: 1rem;
}

.shop .products .product .product-price {
  font-size: 1.2rem;
}

.shop .products .product .product-thumbnail .product-price {
  font-weight: 400;
}

.shop .products .product .product-price del,
.shop .products .product .product-price ins {
  padding: 0 0.3rem;
}

.shop .products .product .button {
  margin-bottom: 0;
}

.shop .products .product-result-count {
  float: left;
}

.shop .products .product .product-thumbnail .product-actions {
  width: 100%;
  position: absolute;
  bottom: 0;
  z-index: 101;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transform: translateY(4rem);
  transform: translateY(4rem);
}

.shop .products .product .product-thumbnail:hover .product-actions {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.shop .products .product .product-thumbnail .product-actions .add-to-cart-button {
  width: 100%;
  text-align: center;
}

.mobile.shop .products .product .product-thumbnail .product-actions,
.shop .products .product .product-actions-mobile {
  display: none;
}

.mobile.shop .products .product .product-actions-mobile {
  margin-top: 1rem;
  display: block;
}

.widget .product-list li {
  padding-top: 1.3rem;
  padding-bottom: 1.5rem;
}

.widget .product-list li a {
  display: block;
}

.widget .product-list .cart-item {
  position: relative;
}

.widget .cart-item .product-title {
  padding-right: 5rem;
}

.widget .product-list .product-thumbnail {
  width: 5rem;
  float: right;
}

.widget .product-list .product-price {
  font-size: 1.1rem;
}

.widget .product-list .product-title {
  margin-bottom: 0.4rem;
  display: block;
}

.widget .product-list .product-price del {
  padding-right: 0.6rem;
}

.widget .product-list .star-rating {
  display: block;
  line-height: 1;
}

.widget .cart-overview a.product-remove {
  margin: 0.3rem 0.8rem 0 0;
  color: inherit;
  display: inline;
  float: left;
}

.widget .cart-subtotal .amount,
.widget .product-list .cart-actions .checkout {
  float: right;
}

.widget .cart-subtotal {
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-weight: 700;
}

.widget .product-list .cart-actions a {
  display: inline-block;
}

.widget .product-tag-cloud a {
  padding: 0.4rem 0.8rem;
  margin: 0 0.5rem 1rem 0;
  display: inline-block;
  border: 1px solid #eee;
  font-size: 1rem;
  text-transform: uppercase;
}

.single-product .product-images .product-thumbnails .grid {
  min-height: 0;
  margin-bottom: 0;
}

.single-product .product-images .product-thumbnails .grid-item {
  width: 25%;
}

.single-product .product-summary .product-price del,
.single-product .product-summary .product-price ins {
  display: inline;
  font-size: 2rem;
  line-height: 1;
}

.single-product .product-summary .product-price del {
  padding-right: 1rem;
}

.single-product .product-summary .product-price {
  margin-bottom: 2rem;
}

.single-product .product-summary .product-rating {
  font-size: 1.2rem;
  float: right;
}

.single-product .product-summary .quantity {
  margin-right: 1rem;
}

.single-product .product-summary .add-to-cart-button,
.single-product .product-summary .quantity {
  float: left;
}

.single-product .product-summary .product-meta > span {
  display: table;
  table-layout: fixed;
}

.single-product .product-summary .product-addtional-info li:first-child a {
  border-top: 1px solid #eee;
}

.single-product .review-comments {
  margin-bottom: 3rem;
  padding-bottom: 3rem;
}

.single-product .review-comments .comments-title {
  margin-bottom: 3rem;
}

.single-product .review-comments .comment-list > li {
  margin-bottom: 4rem;
}

.single-product .review-comments .comment-list > li:last-child {
  margin-bottom: 0;
}

.single-product .review-comments .user-avatar {
  max-width: 7rem;
  float: left;
}

.single-product .review-comments .user-avatar img {
  border-radius: 50%;
}

.single-product .review-comments .comment-content {
  margin-left: 10rem;
}

.single-product .review-comments .name {
  font-size: 1.6rem;
  margin-bottom: 0.6rem;
}

.single-product .review-comments .comment-meta {
  width: 100%;
  margin-bottom: 2rem;
  line-height: 1;
  margin: 0 0 2rem;
  display: inline-block;
  font-size: 1.1rem;
  letter-spacing: 0.1rem;
  font-weight: 700;
}

.single-product .review-comments .comment-meta span {
  margin: 0;
}

.single-product .review-comments .review-star-rating {
  float: right;
}

.single-product .review-comments .comment-meta a:first-child {
  margin-left: 0;
}

.nav-block .cart-indication .badge {
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 50%;
  font-size: 0.9rem;
  line-height: 1.6rem;
  letter-spacing: 0;
  text-align: center;
  background: #232323;
  color: #fff;
  position: absolute;
}

.nav-block .cart-overview .cart-item {
  padding: 1rem 0;
  text-align: left;
  border: none;
}

.nav-block .cart-overview .cart-actions a,
.nav-block .cart-overview .cart-item a {
  letter-spacing: 0;
}

.nav-block .cart-overview .cart-actions:after,
.nav-block .cart-overview .cart-actions:before,
.nav-block .cart-overview .cart-item:after,
.nav-block .cart-overview .cart-item:before {
  height: 0;
  content: ".";
  display: block;
  overflow: hidden;
}

.nav-block .cart-overview .cart-actions:after,
.nav-block .cart-overview .cart-item:after {
  clear: both;
}

.nav-block .cart-overview li:first-child {
  padding-top: 0;
}

.nav-block .cart-overview .product-thumbnail {
  width: 5rem;
  margin-right: 1.3em;
  float: left;
}

.nav-block .cart-overview .product-details {
  position: relative;
  display: block;
  overflow: auto;
}

.nav-block .cart-overview .product-title {
  display: block;
  background: 0 0;
  font-size: 1.2rem;
  line-height: 1;
  font-weight: 400;
}

.nav-block .cart-overview .product-price,
.nav-block .cart-overview .product-quantity {
  font-size: 1.1rem;
}

.nav-block .cart-overview a.product-remove {
  position: absolute;
  top: 0;
  right: 0;
}

.nav-block .cart-overview .cart-subtotal {
  padding: 1rem 0;
  color: #fff;
  font-size: 1.2rem;
  font-weight: 700;
  text-align: left;
  text-transform: uppercase;
}

.nav-block .cart-overview .cart-subtotal > a:hover {
  background: 0 0;
}

.nav-block .cart-overview .cart-subtotal .amount {
  float: right;
}

.nav-block .cart-overview .cart-actions {
  padding-top: 2rem;
  border-top: 1px solid #444;
}

.nav-block .cart-overview .checkout,
.nav-block .cart-overview .view-cart {
  display: inline-block;
  float: left;
}

.nav-block .cart-overview .checkout:not(.button),
.nav-block .cart-overview .view-cart:not(.button) {
  padding: 0;
  background: 0 0;
}

.nav-block .cart-overview .checkout {
  float: right;
}

.cart .cart-review .product-thumbnail a {
  width: 8rem;
  display: block;
}

.cart .cart-review .form-element {
  margin-bottom: 0;
}

.cart .cart-review .cart-actions td {
  padding: 3rem 0 0;
}

.cart .cart-review .cart-coupon-form .form-element {
  max-width: 17rem;
}

.cart .cart-review .cart-coupon-form,
.cart .cart-review .cart-coupon-form .form-element {
  float: left;
}

.cart .cart-review .update-cart {
  float: right;
}

@media only screen and (max-width: 600px) {
  .cart .cart-review .cart-coupon-form .form-element {
    margin-bottom: 1rem;
  }
  .cart .cart-review .product-quantity input {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .cart .cart-review .product-thumbnail {
    display: none;
  }
}

@media only screen and (max-width: 480px) {
  .cart .cart-review .product-quantity {
    display: none;
  }
}

.checkout .cart-review .cart-order-total td {
  text-align: center;
}

.checkout .checkout-payment p {
  font-size: 1.2rem;
  font-style: italic;
  margin-bottom: 0;
}

.mt-0 {
  margin-top: 0;
}

.mt-5 {
  margin-top: 0.5rem;
}

.mt-10 {
  margin-top: 1rem;
}

.mt-20 {
  margin-top: 2rem;
}

.mt-30 {
  margin-top: 3rem;
}

.mt-40 {
  margin-top: 4rem;
}

.mt-50 {
  margin-top: 5rem;
}

.mt-60 {
  margin-top: 6rem;
}

.mt-70 {
  margin-top: 7rem;
}

.mt-80 {
  margin-top: 8rem;
}

.mt-90 {
  margin-top: 9rem;
}

.mt-100 {
  margin-top: 10rem;
}

.mt-110 {
  margin-top: 11rem;
}

.mt-120 {
  margin-top: 12rem;
}

.mt-130 {
  margin-top: 13rem;
}

.mt-140 {
  margin-top: 14rem;
}

.mt-150 {
  margin-top: 15rem;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-5 {
  margin-bottom: 0.5rem;
}

.mb-10 {
  margin-bottom: 1rem;
}

.mb-20 {
  margin-bottom: 2rem;
}

.mb-30 {
  margin-bottom: 3rem;
}

.mb-40 {
  margin-bottom: 4rem;
}

.mb-50 {
  margin-bottom: 5rem;
}

.mb-60 {
  margin-bottom: 6rem;
}

.mb-70 {
  margin-bottom: 7rem;
}

.mb-80 {
  margin-bottom: 8rem;
}

.mb-90 {
  margin-bottom: 9rem;
}

.mb-100 {
  margin-bottom: 10rem;
}

.mb-110 {
  margin-bottom: 11rem;
}

.mb-120 {
  margin-bottom: 12rem;
}

.mb-130 {
  margin-bottom: 13rem;
}

.mb-140 {
  margin-bottom: 14rem;
}

.mb-150 {
  margin-bottom: 15rem;
}

.pt-0,
.section-block.pt-0 {
  padding-top: 0;
}

.pt-5,
.section-block.pt-5 {
  padding-top: 0.5rem;
}

.pt-10,
.section-block.pt-10 {
  padding-top: 1rem;
}

.pt-20,
.section-block.pt-20 {
  padding-top: 2rem;
}

.pt-30,
.section-block.pt-30 {
  padding-top: 3rem;
}

.pt-40,
.section-block.pt-40 {
  padding-top: 4rem;
}

.pt-50,
.section-block.pt-50 {
  padding-top: 5rem;
}

.pt-60,
.section-block.pt-60 {
  padding-top: 6rem;
}

.pt-70,
.section-block.pt-70 {
  padding-top: 7rem;
}

.pt-80,
.section-block.pt-80 {
  padding-top: 8rem;
}

.pt-90,
.section-block.pt-90 {
  padding-top: 9rem;
}

.pt-100,
.section-block.pt-100 {
  padding-top: 10rem;
}

.pt-110,
.section-block.pt-110 {
  padding-top: 11rem;
}

.pt-120,
.section-block.pt-120 {
  padding-top: 12rem;
}

.pt-130,
.section-block.pt-130 {
  padding-top: 13rem;
}

.pt-140,
.section-block.pt-140 {
  padding-top: 14rem;
}

.pt-150,
.section-block.pt-150 {
  padding-top: 15rem;
}

.pb-0,
.section-block.pb-0 {
  padding-bottom: 0;
}

.pb-5,
.section-block.pb-5 {
  padding-bottom: 0.5rem;
}

.pb-10,
.section-block.pb-10 {
  padding-bottom: 1rem;
}

.pb-20,
.section-block.pb-20 {
  padding-bottom: 2rem;
}

.pb-30,
.section-block.pb-30 {
  padding-bottom: 3rem;
}

.pb-40,
.section-block.pb-40 {
  padding-bottom: 4rem;
}

.pb-50,
.section-block.pb-50 {
  padding-bottom: 5rem;
}

.pb-60,
.section-block.pb-60 {
  padding-bottom: 6rem;
}

.pb-70,
.section-block.pb-70 {
  padding-bottom: 7rem;
}

.pb-80,
.section-block.pb-80 {
  padding-bottom: 8rem;
}

.pb-90,
.section-block.pb-90 {
  padding-bottom: 9rem;
}

.pb-100,
.section-block.pb-100 {
  padding-bottom: 10rem;
}

.pb-110,
.section-block.pb-110 {
  padding-bottom: 11rem;
}

.pb-120,
.section-block.pb-120 {
  padding-bottom: 12rem;
}

.pb-130,
.section-block.pb-130 {
  padding-bottom: 13rem;
}

.pb-140,
.section-block.pb-140 {
  padding-bottom: 14rem;
}

.pb-150,
.section-block.pb-150 {
  padding-bottom: 15rem;
}

[class*="pu-"],
[class*="pd-"] {
  position: relative;
}

.pd-10 {
  top: 1rem;
}

.pd-20 {
  top: 2rem;
}

.pd-30 {
  top: 3rem;
}

.pd-40 {
  top: 4rem;
}

.pd-50 {
  top: 5rem;
}

.pd-60 {
  top: 6rem;
}

.pd-70 {
  top: 7rem;
}

.pd-80 {
  top: 8rem;
}

.pd-90 {
  top: 9rem;
}

.pd-100 {
  top: 10rem;
}

.pd-110 {
  top: 11rem;
}

.pd-120 {
  top: 12rem;
}

.pd-130 {
  top: 13rem;
}

.pd-140 {
  top: 14rem;
}

.pd-150 {
  top: 15rem;
}

.pu-10 {
  top: -1rem;
}

.pu-20 {
  top: -2rem;
}

.pu-30 {
  top: -3rem;
}

.pu-40 {
  top: -4rem;
}

.pu-50 {
  top: -5rem;
}

.pu-60 {
  top: -6rem;
}

.pu-70 {
  top: -7rem;
}

.pu-80 {
  top: -8rem;
}

.pu-90 {
  top: -9rem;
}

.pu-100 {
  top: -10rem;
}

.pu-110 {
  top: -11rem;
}

.pu-120 {
  top: -12rem;
}

.pu-130 {
  top: -13rem;
}

.pu-140 {
  top: -14rem;
}

.pu-150 {
  top: -15rem;
}

@media only screen and (max-width: 768px) {
  .mt-mobile-0 {
    margin-top: 0;
  }
  .mt-mobile-5 {
    margin-top: 0.5rem;
  }
  .mt-mobile-10 {
    margin-top: 1rem;
  }
  .mt-mobile-20 {
    margin-top: 2rem;
  }
  .mt-mobile-30 {
    margin-top: 3rem;
  }
  .mt-mobile-40 {
    margin-top: 4rem;
  }
  .mt-mobile-50 {
    margin-top: 5rem;
  }
  .mt-mobile-60 {
    margin-top: 6rem;
  }
  .mt-mobile-70 {
    margin-top: 7rem;
  }
  .mt-mobile-80 {
    margin-top: 8rem;
  }
  .mt-mobile-90 {
    margin-top: 9rem;
  }
  .mt-mobile-100 {
    margin-top: 10rem;
  }
  .mt-mobile-110 {
    margin-top: 11rem;
  }
  .mt-mobile-120 {
    margin-top: 12rem;
  }
  .mt-mobile-130 {
    margin-top: 13rem;
  }
  .mt-mobile-140 {
    margin-top: 14rem;
  }
  .mt-mobile-150 {
    margin-top: 15rem;
  }
  .mb-mobile-0 {
    margin-bottom: 0;
  }
  .mb-mobile-5 {
    margin-bottom: 0.5rem;
  }
  .mb-mobile-10 {
    margin-bottom: 1rem;
  }
  .mb-mobile-20 {
    margin-bottom: 2rem;
  }
  .mb-mobile-30 {
    margin-bottom: 3rem;
  }
  .mb-mobile-40 {
    margin-bottom: 4rem;
  }
  .mb-mobile-50 {
    margin-bottom: 5rem;
  }
  .mb-mobile-60 {
    margin-bottom: 6rem;
  }
  .mb-mobile-70 {
    margin-bottom: 7rem;
  }
  .mb-mobile-80 {
    margin-bottom: 8rem;
  }
  .mb-mobile-90 {
    margin-bottom: 9rem;
  }
  .mb-mobile-100 {
    margin-bottom: 10rem;
  }
  .mb-mobile-110 {
    margin-bottom: 11rem;
  }
  .mb-mobile-120 {
    margin-bottom: 12rem;
  }
  .mb-mobile-130 {
    margin-bottom: 13rem;
  }
  .mb-mobile-140 {
    margin-bottom: 14rem;
  }
  .mb-mobile-150 {
    margin-bottom: 15rem;
  }
  [class*="pu-"],
  [class*="pd-"] {
    top: 0;
  }
}
